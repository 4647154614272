import { hasPermission } from "../../../store/permissions";
import { LayoutActions } from "./actions";

export const saveLayout =
  (result: object) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("CREATE_VENUE_LAYOUT")) {
      dispatch(LayoutActions.loadingState(true));
      api.layoutsAPI
        .createVenueLayout(result)
        .catch((error: any) => {
          console.log("saveLayout Error", error);
          dispatch(LayoutActions.loadingState(false));
        })
        .then((res: any) => {
          const { data } = res;
          // console\.log("saveLayout RESPONSE: " , JSON.stringify(res));
          if (data) {
            dispatch(LayoutActions.saveLayout(true));
          }
          dispatch(LayoutActions.loadingState(false));
        });
    }
  };

export const getApprovedVenues =
  () =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("FETCH_APPROVED_VENUES")) {
      dispatch(LayoutActions.loadingState(true));
      api.layoutsAPI
        .getApprovedVenueLayout()
        .catch((error: any) => {
          console.log("getApprovedVenues Error : ", error);
          dispatch(LayoutActions.loadingState(false));
        })
        .then((res: any) => {
          const { data } = res;
          // console\.log("getApprovedVenues RESPONSE: ", JSON.stringify(res));
          if (data) {
            dispatch(LayoutActions.approvedLayout(data));
          }
          dispatch(LayoutActions.loadingState(false));
        });
    }
  };

export const getPendingVenues =
  () =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("FETCH_PENDING_APPROVAL_VENUES")) {
      dispatch(LayoutActions.loadingState(true));
      api.layoutsAPI
        .getPendingVenueLayout()
        .catch((error: any) => {
          console.log("getPendingVenues Error :", error);
          dispatch(LayoutActions.loadingState(false));
        })
        .then((res: any) => {
          const { data } = res;
          // console\.log("getPendingVenues RESPONSE: ", JSON.stringify(res));
          if (data) {
            dispatch(LayoutActions.pendingLayout(data));
          }
          dispatch(LayoutActions.loadingState(false));
        });
    }
  };

export const getVenueDetail =
  (venueId: string) =>
  (dispatch: any, _: any, { api }: any) => {
    dispatch(LayoutActions.loadingState(true));
    api.layoutsAPI
      .getVenueDetailLayout(venueId)
      .then((res: any) => {
        const { data } = res;
        // console\.log("getVenueDetail RESPONSE: ", JSON.stringify(res));
        if (data) {
          dispatch(LayoutActions.venueDetailLayout(data));
        }
        dispatch(LayoutActions.loadingState(false));
      })
      .catch((error: any) => {
        console.log("getVenueDetail Error : ", error);
        dispatch(LayoutActions.venueDetailLayout({}));
        dispatch(LayoutActions.loadingState(false));
      });
  };
