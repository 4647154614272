import { LoginActions } from "./actions";

export const login =
  (email: string, password: string, navigate: any) =>
  (dispatch: any, _: any, { api }: any) => {
    dispatch(LoginActions.loadingState(true));

    api.loginAPI
      .login(email, password)
      .catch((error: any) => {
        console.log(error);
        dispatch(LoginActions.loadingState(false));
        dispatch(
          LoginActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
      })
      .then((res: any) => {
        const { data, headers } = res;
        dispatch(LoginActions.loadingState(false));
        if (data) {
          const token = headers["x-identity-token"];
          const d = { ...data, token };
          localStorage.setItem("user", JSON.stringify(d));
          return navigate("/");
        }
      });
  };
