import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { getActiveLocationMetadata, getIsLocationActive } from '../../../store/locations/selectors';
import { useAppDispatch } from "../../../hooks";
import { useMedia } from "../../../utils/useMedia";
import { activateLocation } from "./async-actions";

const ActivateLocation = () => {
    const { tablet } = useMedia();
    const dispatch = useAppDispatch();
    const isLocationActive = useSelector(getIsLocationActive);
    const activeLocation = useSelector(getActiveLocationMetadata);
    const handleActivate = () => {
        dispatch(activateLocation(activeLocation.id))
    }
    if (isLocationActive === false) {
        return (
            <Box sx={{
                background: '#EE5A5A',
                margin: tablet ? '8px 0' : '8px 12px',
                borderRadius: '12px',
                padding: '8px 12px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                This location is not activated yet.
                <Button
                    sx={{
                        background: '',
                        color: '#fff'
                    }}
                    onClick={handleActivate}>
                    {tablet ? 'Activate location' : 'Activate'}
                </Button>
            </Box>
        )
    }

    return null;

}

export default ActivateLocation;