import { hasPermission } from "../../store/permissions";
import { EmployeesActions } from "./actions";

export const getEmployees =
  (locationId: string) =>
  (dispatch: any, _: any, { api }: any) => {
    dispatch(EmployeesActions.loadingState(true));
    api.employeesAPI
      .getEmployees(locationId)
      .catch((error: any) => {
        console.log(error);
        dispatch(EmployeesActions.loadingState(false));
        dispatch(
          EmployeesActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
      })
      .then((res: any) => {
        const { data } = res;

        if (data) {
          dispatch(EmployeesActions.addEmployees(data));
        }
        dispatch(EmployeesActions.loadingState(false));
      });
  };

export const createEmployee =
  (data: any) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("CREATE_EMPLOYEE")) {
      dispatch(EmployeesActions.loadingState(true));

      api.employeesAPI
        .createEmployee(data)
        .then((res: any) => {
          const { data } = res;
          if (data) {
            dispatch(EmployeesActions.addEmployee(data));
          }
          dispatch(
            EmployeesActions.snackbarState({
              show: true,
              isError: false,
              message: "User created successfully",
            })
          );
          dispatch(EmployeesActions.loadingState(false));
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(EmployeesActions.loadingState(false));
          dispatch(
            EmployeesActions.snackbarState({
              show: true,
              isError: true,
              message: error.response.data.message,
            })
          );
        });
    }
  };

export const updateEmployee =
  (employeeId: string, data: any) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("UPDATE_EMPLOYEE")) {
      dispatch(EmployeesActions.loadingState(true));

      api.employeesAPI
        .updateEmployee(employeeId, data)
        .then((res: any) => {
          const { data } = res;

          if (data) {
            dispatch(EmployeesActions.addEmployee(data));
          }
          dispatch(
            EmployeesActions.snackbarState({
              show: true,
              isError: false,
              message: "Update successfully",
            })
          );
          dispatch(EmployeesActions.loadingState(false));
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(EmployeesActions.loadingState(false));
          dispatch(
            EmployeesActions.snackbarState({
              show: true,
              isError: true,
              message: error.response.data.message,
            })
          );
        });
    }
  };

export const getRoles =
  () =>
  (dispatch: any, _: any, { api }: any) => {
    api.employeesAPI
      .getRoles()
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;

        if (data) {
          dispatch(EmployeesActions.addRoles(data));
        }
      });
  };

export const deleteEmployee =
  (employeeId: string) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("DELETE_EMPLOYEE")) {
      api.employeesAPI
        .deleteEmployee(employeeId)
        .catch((error: any) => {
          console.log(error);
          dispatch(
            EmployeesActions.snackbarState({
              show: true,
              isError: true,
              message: error.response.data.message,
            })
          );
        })
        .then((res: any) => {
          const { data } = res;

          if (data || data === "") {
            dispatch(EmployeesActions.removeEmployee(employeeId));
          }
          dispatch(
            EmployeesActions.snackbarState({
              show: true,
              isError: true,
              message: "User Deleted Successfully",
            })
          );
        });
    }
  };

export const resendActivationLink =
  (email: string) =>
  (dispatch: any, _: any, { api }: any) => {
    api.employeesAPI
      .resendActivationLink(email)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data || data === "") {
        }
      });
  };
