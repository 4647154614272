export interface Data {
    id: string;
    status: string;
    date: string;
    spotCostAmount: string;
    revenueAmount: string;
    createdAt: number;
}

interface Column {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const cols: Column[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'Номер',
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Дата на резервация',
    },
    {
        id: 'spotCostAmount',
        numeric: false,
        disablePadding: false,
        label: 'Цена на място',
    },
    {
        id: 'revenueAmount',
        numeric: false,
        disablePadding: false,
        label: 'Приход нето',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Статус',
    },
    {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Дата на създаване',
    }
];

export default {
    cols
}