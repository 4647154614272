import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Close, Info, Map, Search, Settings } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Tabs,
  Tab,
  Snackbar,
  Stack,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { getActiveLocationMetadata } from "../../../store/locations/selectors";
import {
  getSelectedDate,
  getSelectedSpots,
  getSelectedInitilizeSpot,
} from "../sidepanel/selectors";
import { useAppDispatch } from "../../../hooks";
import {
  createSchedule,
  initializeSpot,
  reLinkSpot,
  removeSchedulesPromise,
  updateSpot,
} from "./async-actions";
import { getActiveSpotReservations, getSpots } from "./selector";
import { LayoutActions } from "./actions";
import InitializeSpotModal from "./InitializeSpotModal";
import UpdateSpotModal from "./UpdateSpotModal";
import AssignQRModal from "./AssignQRModal";
import SpotDisableModal from "./SpotDisableModal";
import SpotEnableModal from "./SpotEnableModal";

const MobileHeader = ({
  activeTab,
  isInitializeSpotOpened,
  setIsInitializeSpotOpened,
  handleTabChange,
}: any) => {
  const dispatch = useAppDispatch();

  const activeLocation = useSelector(getActiveLocationMetadata);
  const selectedSpots = useSelector(getSelectedSpots);
  const selectedInitilizeSpot = useSelector(getSelectedInitilizeSpot);

  const selectedDate = useSelector(getSelectedDate);
  const spots = useSelector(getSpots);
  const activeSpotReservations = useSelector((state: any) =>
    getActiveSpotReservations(state, selectedSpots[0] && selectedSpots[0].id)
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const settingsOpen = Boolean(anchorEl);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isUpdateSpotOpened, setIsUpdateSpotOpened] = useState(false);
  const [isAssignQROpened, setIsAssignQROpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
    handleCleanSearch();
  };

  const handleSettingsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const handleAssignQR = () => {
    setIsAssignQROpened(!isAssignQROpened);
    handleSettingsClose();
  };

  const handleSearchTermChange = (event: any) => {
    setSearchTerm(event.target.value);
    const filtered = spots.filter((spot: any) => {
      return event.target.value === ""
        ? null
        : spot.label.includes(event.target.value);
    });
    dispatch(LayoutActions.setSearchResults(filtered));
  };

  const handleCleanSearch = () => {
    setSearchTerm("");
    dispatch(LayoutActions.setSearchResults([]));
  };

  const handleInitializeSpotModalClose = () => {
    setIsInitializeSpotOpened(!isInitializeSpotOpened);
  };

  const handleAssignQRModalClose = () => {
    setIsAssignQROpened(!isAssignQROpened);
  };

  const handleAssign = () => {
    handleAssignQR();
  };

  const handleInitialize = (data: any, plateId?: string) => {
    const fromDate = selectedDate;
    const untilDate = selectedDate;

    const formData = {
      venueId: activeLocation.id,
      ...data,
    };

    dispatch(
      initializeSpot(
        selectedSpots[0].id,
        formData,
        fromDate,
        untilDate,
        plateId
      )
    );
    handleInitializeSpotModalClose();
  };

  const handleUpdateSpot = () => {
    setIsUpdateSpotOpened(!isUpdateSpotOpened);
    handleClose();
  };

  const handleUpdateSpotModalClose = () => {
    setIsUpdateSpotOpened(!isUpdateSpotOpened);
  };

  const [isEnableSpotModalOpened, setIsEnableSpotModalOpened] = useState(false);
  const [isDisableSpotModalOpened, setIsDisableSpotModalOpened] =
    useState(false);

  const handleDisableSpotModal = () => {
    if (selectedSpots[0].reserved) {
      setOpenMessage(true);
    } else {
      setIsDisableSpotModalOpened(!isDisableSpotModalOpened);
    }
    handleSettingsClose();
  };

  const handleEnableSpotModal = () => {
    setIsEnableSpotModalOpened(!isEnableSpotModalOpened);
    handleSettingsClose();
  };

  // TODO: move to utils
  const generateDateRange = (fromDate: any, toDate: any) => {
    const dateRange = [];
    let currentDate = fromDate;

    while (
      currentDate.isSame(toDate, "day") ||
      currentDate.isBefore(toDate, "day")
    ) {
      dateRange.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    return dateRange;
  };

  const handleDisableSpot = (fromDate: string, toDate: string) => {
    const range = generateDateRange(fromDate, toDate);
    dispatch(
      createSchedule(
        activeLocation.id,
        [selectedSpots[0].id],
        range,
        selectedDate,
        selectedDate
      )
    );
    setIsDisableSpotModalOpened(!isDisableSpotModalOpened);
  };

  const handleEnableSpot = (schedules: any) => {
    dispatch(
      removeSchedulesPromise(
        schedules,
        activeLocation.id,
        selectedDate,
        selectedDate
      )
    );
    setIsEnableSpotModalOpened(!isEnableSpotModalOpened);
  };

  const [openMessage, setOpenMessage] = useState(false);
  const handleMessageClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessage(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "14px 12px",
        mt: 1,
        position: "relative",
      }}
    >
      <Box
        sx={{
          flex: "0 0 auto",
        }}
      >
        <IconButton
          sx={{
            background: "#EDEFF2",
            borderRadius: 1,
          }}
          onClick={handleSearchOpen}
        >
          <Search />
        </IconButton>
        {searchOpen && (
          <Box
            sx={{
              flex: 1,
              position: "absolute",
              top: 14,
              left: 12,
              background: "#fff",
              width: "calc(100% - 24px)",
              zIndex: 9999,
            }}
          >
            <IconButton
              sx={{
                background: "#EDEFF2",
                borderRadius: 1,
                mr: 1,
              }}
              onClick={handleSearchClose}
            >
              <Close />
            </IconButton>
            <FormControl
              variant="standard"
              sx={{
                width: "calc(100% - 48px)",
              }}
            >
              <FormControl
                sx={{
                  m: 0,
                  width: "100%",
                }}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="outlined-adornment-search"
                  sx={{
                    top: "-7px",
                  }}
                >
                  Търсене
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-search"
                  value={searchTerm}
                  size="small"
                  onChange={handleSearchTermChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle search visibility"
                        onClick={handleCleanSearch}
                        edge="end"
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Търсене"
                />
              </FormControl>
            </FormControl>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            "& .MuiTabs-flexContainer": {
              justifyContent: "center",
            },
            "& .MuiButtonBase-root.MuiTab-root": {
              color: "rgba(0, 0, 0, .5)",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: ".4px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: 0,
              minHeight: "48px",
              "&.Mui-selected": {
                color: "#C5FFFF",
                backgroundColor: "#044D5E",
                borderBottom: "none",
              },
              "&:not(.Mui-selected)": {
                backgroundColor: "#EDEFF2",
              },
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          <Tab
            icon={<Map sx={{ mr: 1, mb: 0 }} />}
            label="Карта"
            sx={{
              borderRadius: "8px 0 0 8px",
            }}
          />
          <Tab
            icon={<Info sx={{ mr: 1, mb: 0 }} />}
            label="Инфо"
            sx={{
              borderRadius: "0 8px 8px 0",
            }}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          flex: "0 0 auto",
        }}
      >
        <IconButton
          onClick={handleSettingsOpen}
          sx={{
            background: "#EDEFF2",
            borderRadius: 1,
          }}
        >
          <Settings />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={settingsOpen}
          onClose={handleSettingsClose}
          sx={{
            mt: 1,
          }}
        >
          {selectedSpots.length !== 1 && (
            <MenuItem disabled={true}>Блокирай</MenuItem>
          )}
          {selectedSpots.length === 1 ? (
            selectedSpots[0] && !selectedSpots[0].disabled ? (
              <MenuItem onClick={handleDisableSpotModal}>Блокирай</MenuItem>
            ) : (
              <MenuItem onClick={handleEnableSpotModal}>Отблокирай</MenuItem>
            )
          ) : null}
          <MenuItem
            disabled={selectedInitilizeSpot == null}
            onClick={handleUpdateSpot}
          >
            Промяна на конфигурация
          </MenuItem>
          <MenuItem
            disabled={selectedInitilizeSpot == null}
            onClick={handleAssignQR}
          >
            Промяна на QR код
          </MenuItem>
        </Menu>
      </Box>

      {isInitializeSpotOpened && (
        <InitializeSpotModal
          selectedSpot={selectedSpots[0]}
          handleInitialize={handleInitialize}
          handleClose={handleInitializeSpotModalClose}
        />
      )}

      {isUpdateSpotOpened && (
        <UpdateSpotModal handleClose={handleUpdateSpotModalClose} />
      )}

      {isAssignQROpened && (
        <AssignQRModal
          selectedInitilizeSpot={selectedInitilizeSpot}
          handleAssign={handleAssign}
          handleClose={handleAssignQRModalClose}
        />
      )}

      {isDisableSpotModalOpened && (
        <SpotDisableModal
          selectedSpot={selectedSpots[0]}
          handleDisable={handleDisableSpot}
          handleClose={handleDisableSpotModal}
        />
      )}

      {isEnableSpotModalOpened && (
        <SpotEnableModal
          selectedSpot={selectedSpots[0]}
          handleEnable={handleEnableSpot}
          handleClose={handleEnableSpotModal}
        />
      )}
      <Stack spacing={2} sx={{ maxWidth: "500px" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={openMessage}
          autoHideDuration={6000}
          onClose={handleMessageClose}
          sx={{
            "& .MuiPaper-root": {
              background: "#044D5E",
            },
          }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error">
            Това място има активни резервации за избраните дни{" "}
            {activeSpotReservations &&
              activeSpotReservations.length &&
              activeSpotReservations.map((spot: any, index: number) => {
                return (
                  <Fragment key={index}>
                    &nbsp;{dayjs(spot.date).format("DD/MM/YYYY")}
                    {activeSpotReservations.length - (index + 1) !== 0
                      ? ", "
                      : ""}
                  </Fragment>
                );
              })}
            . За да го блокирате първо трябва да канселирате резервациите.
          </MuiAlert>
        </Snackbar>
      </Stack>
    </Box>
  );
};

export default MobileHeader;
