import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const UserActionsContainer = styled.div`
    flex: 1;
    justify-content: end;
    display: flex;
    align-items: center;
`;

export const NotificationsWrapper = styled.div`
    position: relative;
`;

export const NotificationIndicator = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: red;
`;

export const SPALink = styled(Link).attrs((props) => ({
    to: props.to || '#'
}))`
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding: 8px 16px;
    min-height: 48px;
    text-decoration: none;
    color: inherit;
    background: transparent;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        color: inherit;
    }
    & .MuiTypography-root {
        font-size: 14px;
        font-weight: 700;
        line-height: 20.02px;
        letter-spacing: .17px;
    }
`;