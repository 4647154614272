import {
  Box,
  Button,
  Typography,
  Card,
  TextField,
  Alert,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LoginLines } from "../../assets/icons";
import Logo from "../../assets/logo.svg";
import { useAppDispatch } from "../../hooks";
import { submitAccountActivation } from "./async-actions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getLoading,
  getSnackbar,
  isActivated,
  activeFailed,
} from "./selectors";
import { AccountAction } from "./actions";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const ActivateAccount = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const loading = useSelector(getLoading);

  const snackbar = useSelector(getSnackbar);

  const activated = useSelector(isActivated);

  const activatedFailed = useSelector(activeFailed);

  const [password, setPassword] = useState<string>("");

  const [inputError, setInputError] = useState<string>("");

  const [activationToken, setActivationToken] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("activationToken");
    if (token) {
      setActivationToken(token);
    }
  }, []);

  const validate = () => {
    if (!password) {
      setInputError("Password is required");
      return false;
    }

    setInputError("");
    return true;
  };

  const handleSubmit = () => {
    const isValid = validate();
    if (!isValid) return;

    const formData = {
      activationToken: activationToken,
      password: password,
    };

    dispatch(submitAccountActivation(formData));
  };

  const handleGoToLogin = () => {
    navigate(`/login`);
  };

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(
      AccountAction.snackbarState({
        show: false,
        isError: snackbar.isError,
        message: "",
      })
    );
  };

  return (
    <div className="parent-wrapper">
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            position: "fixed",
            top: "41px",
            left: 0,
          }}
        >
          <LoginLines />
        </Box>

        <div className="activation-wrapper">
          <img src={Logo} className="mylogo" alt="" />

          {!activated && !activatedFailed && (
            <Card
              className="activate-ac-card"
              sx={{ maxWidth: 500, backgroundColor: "#fff" }}
            >
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                Account Activation
              </Typography>

              <p className="fieldLabels">Password</p>
              <TextField
                variant="outlined"
                value={password}
                placeholder="Enter password here"
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
              />

              {inputError && (
                <div>
                  <Alert sx={{ marginTop: "30px" }} severity="error">
                    {inputError}
                  </Alert>
                </div>
              )}

              <Box sx={{ mt: "25px", position: "relative" }}>
                <Button
                  sx={{
                    background: "#044D5E",
                    color: "#C5FFFF",
                    width: "100%",
                  }}
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  Activate Account
                </Button>

                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-16px",
                      marginLeft: "-16px",
                    }}
                  />
                )}
              </Box>
            </Card>
          )}

          {activated && (
            <Card
              className="activate-ac-card text-center"
              sx={{ maxWidth: 500, backgroundColor: "#fff" }}
            >
              <CheckCircleIcon
                sx={{
                  fontSize: "80px",
                  color: "#0ece82",
                }}
              />

              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                Your account is activated
              </Typography>

              <Button
                sx={{
                  background: "#044D5E",
                  color: "#C5FFFF",
                  marginTop: "15px",
                }}
                disabled={loading}
                onClick={handleGoToLogin}
              >
                Go to Login
              </Button>
            </Card>
          )}

          {/* {activatedFailed && (
            <Card
              className="activate-ac-card text-center"
              sx={{ maxWidth: 500, backgroundColor: "#fff" }}
            >
              <ErrorIcon
                sx={{
                  fontSize: "80px",
                  color: "#ff4a49",
                }}
              />

              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                Your activation failed!
              </Typography>

              <Button
                sx={{
                  background: "#044D5E",
                  color: "#C5FFFF",
                  marginTop: "15px",
                }}
                disabled={loading}
                onClick={handleGoToLogin}
              >
                Go to Login
              </Button>
            </Card>
          )} */}
        </div>
      </Box>

      <br />
      <br />

      <Snackbar
        open={snackbar.show}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          severity={snackbar.isError ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
          onClose={handleSnackBarClose}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ActivateAccount;
