import axios from 'axios';

const getToken = () => {
    const user: any = JSON.parse(localStorage.getItem('user') as any);
    if (!user) {
        console.log("wrong user");
    }
    const token = user ? user.token : '';

    return token;
}

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL
});

axiosInstance.interceptors.request.use((config) => {
    if (window.location.pathname !== '/login' && window.location.pathname !== '/activate-account') {
        config.headers.Authorization = `Bearer ${getToken()}`
    } 
    
    return config;
});

const auth = (method: any) => {
    return (url: string, data?: any, config = {}) => {
        return method(url, data, { ...config });
    };
};


const axiosCheckInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL
});

export default {
    getCheck: auth(axiosCheckInstance.get),
    postActivate: auth(axiosCheckInstance.post),
    get: auth(axiosInstance.get),
    post: auth(axiosInstance.post),
    patch: auth(axiosInstance.patch),
    delete: auth(axiosInstance.delete),
};