import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../hooks";
import { getEmployees } from "./async-actions";
import Table from "./table/Table";
import { useSelector } from "react-redux";
import { getEmployeesList, getLoading, getSnackbar } from "./selectors";
import {
  getLocationsList,
  getActiveLocationMetadata,
} from "../../store/locations/selectors";
import { Link } from "react-router-dom";
import { EmployeesActions } from "./actions";
import { hasPermission } from "../../store/permissions";

import { useNavigate } from "react-router-dom";

const allLocations = [
  {
    id: "all",
    name: "Всички",
  },
];

const roles = [
  {
    value: "all",
    text: "All roles",
  },
];

const UserManagement = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const snackbar = useSelector(getSnackbar);

  const loading = useSelector(getLoading);

  const activeLocation = useSelector(getActiveLocationMetadata);

  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user") as any)
  );

  // console.log("user :", JSON.stringify(user));

  const employees = useSelector(getEmployeesList);
  const handleSearchTermChange = (event: any) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  useEffect(() => {
    if (!hasPermission("FETCH_EMPLOYEES")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    console.log("Active Location:", activeLocation);
    if (activeLocation) {
      dispatch(getEmployees(activeLocation.id));
    }
  }, [activeLocation, dispatch]);

  // skipped logined user
  const filteredByLocation =
    employees &&
    employees.filter((employee: any) => {
      return employee.id != user.id;
    });

  const searchedData =
    filteredByLocation &&
    filteredByLocation.filter((e: any) => {
      //indexof
      if (searchTerm !== "") {
        const name = e.firstname.concat(" ", e.lastname).toLowerCase();
        return (
          name.includes(searchTerm) ||
          e.email.toLowerCase().includes(searchTerm)
        );
      }
      return e;
    });

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(
      EmployeesActions.snackbarState({
        show: false,
        isError: snackbar.isError,
        message: "",
      })
    );
  };

  return (
    <Box sx={{ pt: 3, pr: 3, pl: 3, position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" },
          gap: 2,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "auto" },
            flex: { xs: "100%", sm: "0 0 30%" },
          }}
        >
          <TextField
            label="Търсене"
            id="search-field"
            placeholder="Name, email, etc."
            size="small"
            sx={{
              background: "#F4F7FE",
              color: "#000",
              boxShadow: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)",
              width: "100%",
              fontWeight: "600",
              "& fieldset": {
                border: "none",
              },
            }}
            onChange={handleSearchTermChange}
          />
        </Box>

        {hasPermission("CREATE_EMPLOYEE") && (
          <Box
            sx={{
              position: { xs: "fixed", sm: "unset" },
              bottom: "35px",
              right: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              flex: 1,
            }}
          >
            <Link to="/user-management/create">
              <Button
                variant="contained"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { xs: "60px", sm: "auto" },
                  height: { xs: "60px", sm: "auto" },
                  borderRadius: { xs: "100%", sm: "4px" },
                  minWidth: "60px",
                  background: "#C5FFFF",
                  color: "#06191E",
                  "&:hover": {
                    background: "#044D5E",
                    color: "#fff",
                  },
                }}
              >
                <AddIcon />
                <Typography
                  sx={{
                    ml: "6px",
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  Създай
                </Typography>
              </Button>
            </Link>
          </Box>
        )}
      </Box>
      {employees && employees.length >= 0 && (
        <Box sx={{ mt: 2, width: { xs: "calc(100vw - 48px)", sm: "auto" } }}>
          {loading ? (
            <div className="center-loader">
              <CircularProgress />
            </div>
          ) : (
            <Table data={searchedData} />
          )}
        </Box>
      )}

      <Snackbar
        open={snackbar.show}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          severity={snackbar.isError ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
          onClose={handleSnackBarClose}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserManagement;
