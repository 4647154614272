import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, TextField } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: document.body.offsetWidth > 769 ? 526 : '90%',
    maxHeight: document.body.offsetWidth > 769 ? '100%' : '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '16px 24px',
    borderRadius: '6px'
};

const SpotDisableModal = ({
    selectedSpot,
    handleDisable,
    handleClose
}: any) => {
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());


    const handleFromDate = (event: any) => {
        setFromDate(event);
    }

    const handleToDate = (event: any) => {
        setToDate(event);
    }

    useEffect(() => {
        return () => {
            setFromDate(dayjs());
            setToDate(dayjs());
        }
    }, []);

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography variant="h6" component="h2">
                        Потвърждаване на блокирането
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '16px 0',
                        gap: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mt: 2
                        }}>
                            <TextField
                                value={selectedSpot.label}
                                label="Избрано място"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    flex: 1
                                }} />
                        </Box>
                        <Box sx={{
                            background: '#EDEFF2',
                            borderRadius: '16px',
                            padding: '16px 16px 24px 16px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Typography variant="h6" component="h2" sx={{
                                fontWeight: 500,
                                fontSize: '20px',
                                lineHeight: '32px',
                                letterSpacing: '.15px'
                            }}>
                                
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                flexDirection: {xs: 'column', sm: 'row'}
                            }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker label="От" value={fromDate} onChange={handleFromDate} />
                                    </DemoContainer>
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker label="До" value={toDate} onChange={handleToDate} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'end'
                        }}>
                            <Button onClick={handleClose}>Отказ</Button>
                            <Button sx={{
                                background: '#044D5E',
                                color: '#C5FFFF',
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '24px'
                            }}
                                onClick={() => handleDisable(fromDate, toDate)}>
                                Блокирай
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default SpotDisableModal;