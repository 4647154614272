import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { getSelectedDate } from "../sidepanel/selectors";

import { getSelectedReservedSpot } from "../sidepanel/selectors";

import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MakeReservationModal = ({
  handleConfirm,
  handleClose,
  isComplete,
}: any) => {
  const selectedReservedSpot = useSelector(getSelectedReservedSpot);

  const selectedDate = useSelector(getSelectedDate);

  const handleSubmit = () => {
    handleConfirm(isComplete);
  };

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="confirm-reserve-dialog"
      >
        {isComplete && (
          <DialogTitle sx={{ textAlign: "center" }}>
            {"Завърши резервация"}
          </DialogTitle>
        )}
        {!isComplete && (
          <DialogTitle sx={{ textAlign: "center" }}>
            {"Канселирай резервация"}
          </DialogTitle>
        )}
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <p className="ma-0">
                Номер на място: <b>{selectedReservedSpot.spot.label}</b>
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <p className="ma-0">
                Дата: <b>{selectedDate}</b>
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <p className="ma-0">
                цена: <b>{selectedReservedSpot.spot.costAmount} лв.</b>
              </p>
            </Grid>
          </Grid>

          {isComplete && (
            <p className="confirm-res-note">
              Приключвате успешно Резервацията!клиента е заплатил и получил
              услугата и освобождава комплекта чадър/шатра
            </p>
          )}

          {!isComplete && (
            <p className="revoke-res-note">
              Отменяте активна Резервация! <br />
              клиента не е заплатил и не е получил услугатa
            </p>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleSubmit}
            sx={{
              background: isComplete ? "#044D5E" : "#ee5a5a",
              color: isComplete ? "#C5FFFF" : "#fff",
            }}
          >
            {isComplete && "Потвърди"}
            {!isComplete && "Канселирай резервация"}
          </Button>
        </DialogActions>

        <IconButton
          sx={{
            position: "absolute",
            right: "7px",
            top: "10px",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </div>
  );
};

export default MakeReservationModal;
