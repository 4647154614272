import locationsAPI from './locations';
import loginAPI from './login';
import layoutsAPI from './layouts';
import platesAPI from './plates';
import spotsAPI from './spots';
import reservationsAPI from './reservations';
import reportsAPI from './reports';
import employeesAPI from './employees';
import vanueApi from './vanue';
import accountApi from './account';
import ticketsAPI from "./tickets.ts";
import statisticsAPI from './statistics.ts';

const api = {
    loginAPI,
    locationsAPI,
    layoutsAPI,
    platesAPI,
    spotsAPI,
    reservationsAPI,
    reportsAPI,
    employeesAPI,
    vanueApi,
    accountApi,
    ticketsAPI,
    statisticsAPI
}

export default api;
