import { createSelector } from "@reduxjs/toolkit"

const getDashboardSidepanel = (state: any) => state.dashboardSidepanel;
export const getSelectedSpots = createSelector(getDashboardSidepanel, panel => panel.selectedSpots);
export const getSelectedInitilizeSpot = createSelector(getDashboardSidepanel, panel => panel.selectedInitilizeSpot);
export const getSelectedReservedSpot = createSelector(getDashboardSidepanel, panel => panel.selectedReservedSpot);
export const getSelectedDate = createSelector(getDashboardSidepanel, panel => panel.selectedDate);
export const getInitilizeLoading = createSelector(getDashboardSidepanel, panel => panel.initilizeLoading);
export const getReservationLoading = createSelector(getDashboardSidepanel, panel => panel.reservationLoading);
export const getCompleteReservationLoading = createSelector(getDashboardSidepanel, panel => panel.completeReservationLoading);
export const getCancelReservationLoading = createSelector(getDashboardSidepanel, panel => panel.cancelReservationLoading);
export const getSpotSettingsLoading = createSelector(getDashboardSidepanel, panel => panel.spotSettingsLoading);
export const getSpotSettingData = createSelector(getDashboardSidepanel, panel => panel.spotSettingsData);
export const getspotAssociateLoading = createSelector(getDashboardSidepanel, panel => panel.spotAssociateLoading);