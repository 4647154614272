export interface Data {
    id: string;
    plateNumber: string;
    type: string;
    status: string;
    date: string;
    price: string;
}

function createData(
    id: string,
    plateNumber: string,
    type: string,
    status: string,
    date: string,
    price: string,
): Data {
    return {
        id,
        plateNumber,
        type,
        status,
        date,
        price
    };
}

const rows = [
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
    createData('Cupcake', '305', '3.7', '67', '4.3', '1000'),
];

interface Column {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const cols: Column[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'plateNumber',
        numeric: true,
        disablePadding: false,
        label: 'Plate number',
    },
    {
        id: 'type',
        numeric: true,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Price',
    },
];

export default {
    cols,
    rows
}