import {
  REMOVE_SELECTED_SPOT,
  RESET_PANEL,
  SET_ACTIVE_DATE,
  SET_SELECTED_DATE,
  TOGGLE_SELECTED_SPOT,
  TOGGLE_INITILIZE_SPOT,
  TOGGLE_RESERVED_SPOT,
  RESET_SELECTED_SPOTS,
  INITILIZE_LOADING,
  RESERVATION_LOADING,
  COMPLETE_RESERVATION_LOADING,
  CANCEL_RESERVATION_LOADING,
  SPOT_SETTING_LOADING,
  SPOT_SETTING_DATA,
  SPOT_ASSOCIATE_LOADING,
} from "./types";

const setActiveDate = (payload: any) => ({
  type: SET_ACTIVE_DATE,
  payload,
});

const toggleSelectedSpots = (payload: any) => ({
  type: TOGGLE_SELECTED_SPOT,
  payload,
});

const toggleInitilizeSpot = (payload: any) => ({
  type: TOGGLE_INITILIZE_SPOT,
  payload,
});

const toggleReservedSpot = (payload: any) => ({
  type: TOGGLE_RESERVED_SPOT,
  payload,
});

const removeSelectedSpot = (payload: any) => ({
  type: REMOVE_SELECTED_SPOT,
  payload,
});

const resetSelectedSpots = () => ({
  type: RESET_SELECTED_SPOTS,
});

const setSelectedDate = (payload: any) => ({
  type: SET_SELECTED_DATE,
  payload,
});

const resetDashboardPanel = () => ({
  type: RESET_PANEL,
});

const setReservationLoading = (payload: any) => ({
  type: RESERVATION_LOADING,
  payload,
});

const setInitilizeLoading = (payload: any) => ({
  type: INITILIZE_LOADING,
  payload,
});

const setCompleteReservationLoading = (payload: any) => ({
  type: COMPLETE_RESERVATION_LOADING,
  payload,
});

const setCancelReservationLoading = (payload: any) => ({
  type: CANCEL_RESERVATION_LOADING,
  payload,
});

const setSpotSettingLoading = (payload: any) => ({
  type: SPOT_SETTING_LOADING,
  payload,
});

const spotSettingData = (payload: any) => ({
  type: SPOT_SETTING_DATA,
  payload,
});

const setAccociateLoading = (payload: any) => ({
  type: SPOT_ASSOCIATE_LOADING,
  payload,
});

export const DashboardSidepanelActions = {
  setActiveDate,
  toggleSelectedSpots,
  toggleInitilizeSpot,
  toggleReservedSpot,
  removeSelectedSpot,
  resetSelectedSpots,
  setSelectedDate,
  resetDashboardPanel,
  setInitilizeLoading,
  setReservationLoading,
  setCompleteReservationLoading,
  setCancelReservationLoading,
  setSpotSettingLoading,
  spotSettingData,
  setAccociateLoading,
};
