import { createSelector } from "@reduxjs/toolkit";

const getStatistics = (state: any) => state.statistics;

export const getLoading = createSelector(
  getStatistics,
  (layout) => layout.loading
);
export const getSnackbar = createSelector(
  getStatistics,
  (login) => login.snackbar
);
export const getStatisticsData = createSelector(
  getStatistics,
  (layout) => layout.statistics_data
);
