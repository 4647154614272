import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react"; 
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import DateRangeIcon from "@mui/icons-material/DateRange";
import styled from "styled-components";
import { getAllStatistics } from "../statistics/async-actions";
import { useAppDispatch } from "../../hooks";
import StatisticCard from "../../components/StatisticCard";
import BeachAccessOutlinedIcon from "@mui/icons-material/BeachAccessOutlined";
import BookOnlineOutlinedIcon from "@mui/icons-material/BookOnlineOutlined";
import { useSelector } from "react-redux";
import { getActiveLocationMetadata } from "../../store/locations/selectors";
import { getStatisticsData } from "./selector";

const Statistics = () => {
  // const localDateFrom = localStorage.getItem("dateFrom");
  // const localDateTo = localStorage.getItem("dateTo");

  // const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const [dateRange, setDateRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);

  // const [startDate, setStartDate] = useState(localDateFrom);
  // const [endDate, setEndDate] = useState(localDateTo);

  const statisticsData = useSelector(getStatisticsData);

  const dispatch = useAppDispatch();

  const activeLocation = useSelector(getActiveLocationMetadata);

  const handleClearDate = () => {
    const today = new Date();

    const initialStartDate = new Date(today);
    initialStartDate.setDate(today.getDate() - 1);

    setDateRange([initialStartDate, today]);

    const formatDate = (date: Date | null): string => {
      if (!date) return "";
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const formattedStartDate = formatDate(initialStartDate);
    setStartDate(`${formattedStartDate}`);

    const formattedEndDate = formatDate(today);
    setEndDate(`${formattedEndDate}`);
    // localStorage.removeItem("dateRange");
    // localStorage.removeItem("dateFrom");
    // localStorage.removeItem("dateTo");
  };

  const handleDateChange = (dates: [Date | null, Date | null] | null) => {
    if (dates) {
      const [selectedStartDate, selectedEndDate] = dates;

      // const formatDate = (date: Date | null): string => {
      //   if (!date) return "";
      //   const year = date.getFullYear();
      //   const month = String(date.getMonth() + 1).padStart(2, "0");
      //   const day = String(date.getDate()).padStart(2, "0");
      //   return `${year}-${month}-${day}`;
      // };

      // if (selectedStartDate) {
      //   const formattedStartDate = formatDate(selectedStartDate);
      //   setStartDate(formattedStartDate);
      //   // localStorage.setItem("dateFrom", formattedStartDate);
      // }

      // if (selectedEndDate) {
      //   const formattedEndDate = formatDate(selectedEndDate);
      //   setEndDate(formattedEndDate);
      //   // localStorage.setItem("dateTo", formattedEndDate);
      // }

      setDateRange([
        selectedStartDate || undefined,
        selectedEndDate || undefined,
      ]);
      // localStorage.setItem(
      //   "dateRange",
      //   JSON.stringify([selectedStartDate, selectedEndDate])
      // );
    }
  };

  useEffect(() => {
    const today = new Date();

    const initialStartDate = new Date(today);
    initialStartDate.setDate(today.getDate() - 1);

    setDateRange([initialStartDate, today]);
  }, []);

  useEffect(() => {
    const formatDate = (date: Date | undefined): string => {
      if (!date) return "";
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    if (activeLocation) {
      if (dateRange[0] && dateRange[1]) {
        dispatch(
          getAllStatistics(
            activeLocation.id,
            `${formatDate(dateRange[0])}`,
            `${formatDate(dateRange[1])}`
          )
        );
      }
    }

    // const localDateRange = localStorage.getItem("dateRange");
    // if (localDateRange) {
    //   const [startDateStr, endDateStr] = JSON.parse(localDateRange);

    //   const startDate = startDateStr ? new Date(startDateStr) : undefined;
    //   const endDate = endDateStr ? new Date(endDateStr) : undefined;

    //   setDateRange([startDate, endDate]);
    // }
  }, [activeLocation, dispatch, dateRange[0], dateRange[1]]);

  const Container = styled.div`
    border: 2px solid rgb(4, 77, 94);
    display: flex;
    width: fit-content;
    padding: 7px;
    border-radius: 7px;
    margin: 5px;
  `;

  const Heading = styled.h4`
    margin-bottom: 0px;
  `;

  const TopContainer = styled.div`
    width: 100%;
    border: 2px dotted skyblue;
    padding: 10px;
  `;

  const BottomContainer = styled.div`
    width: 100%;
    border: 2px dotted skyblue;
    padding: 10px;
  `;

  return (
    <>
      <Box
        sx={{
          p: 2,
        }}
      >
        <div>
          <Heading>Select Date Range</Heading>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <Container>
              <DateRangePicker
                onChange={setDateRange}
                clearIcon={false}
                value={dateRange}
                className="my-date-range"
              />
            </Container>

            <Button
              variant="contained"
              sx={{
                marginLeft: "10px",
                height: "35px",
                background: "rgba(0, 17, 11, 0.76)",
              }}
              onClick={handleClearDate}
            >
              CLEAR
            </Button>
          </div>
        </div>

        <Box
          sx={{
            pt: 3,
            pr: 3,
            pl: 3,
            mt: "20px",
            background: "white",
            height: "100%",
            border: "2px solid #00abff",
            pb: "20px",
          }}
        >
          {/* {statisticsData ? ( */}
          <Box>
            <TopContainer>
              <Box sx={{ display: "flex", my: "10px", color: "grey" }}>
                <BookOnlineOutlinedIcon sx={{ mr: "4px" }} /> Online
                Reservations for the period selected
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <StatisticCard
                  title="Complete Online Reservations Revenue"
                  dateFrom={
                    statisticsData?.dateFrom
                      ? statisticsData?.dateFrom
                      : dateRange[0]
                  }
                  dateTo={
                    statisticsData?.dateTo
                      ? statisticsData?.dateTo
                      : dateRange[1]
                  }
                  total={statisticsData?.completedOnlineReservationsRevenue}
                  percent="3.2"
                />
                <StatisticCard
                  title="Refunded Online Reservations Amount"
                  dateFrom={
                    statisticsData?.dateFrom
                      ? statisticsData?.dateFrom
                      : dateRange[0]
                  }
                  dateTo={
                    statisticsData?.dateTo
                      ? statisticsData?.dateTo
                      : dateRange[1]
                  }
                  total={statisticsData?.refundedOnlineReservationsAmount}
                  percent="3.2"
                />
                <StatisticCard
                  title="Active Online Reservations Revenue"
                  dateFrom={
                    statisticsData?.dateFrom
                      ? statisticsData?.dateFrom
                      : dateRange[0]
                  }
                  dateTo={
                    statisticsData?.dateTo
                      ? statisticsData?.dateTo
                      : dateRange[1]
                  }
                  total={statisticsData?.activeOnlineReservationsRevenue}
                  percent="3.2"
                />
              </Box>
            </TopContainer>

            <Box sx={{ borderTop: "2px dotted  #00b5ff", my: "30px" }}></Box>

            <BottomContainer>
              <Box sx={{ display: "flex", my: "10px", color: "grey" }}>
                <BeachAccessOutlinedIcon sx={{ mr: "4px" }} /> On-site
                Reservations for the period selected
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <StatisticCard
                  title="Completed OnSite Reservations Revenue"
                  dateFrom={
                    statisticsData?.dateFrom
                      ? statisticsData?.dateFrom
                      : dateRange[0]
                  }
                  dateTo={
                    statisticsData?.dateTo
                      ? statisticsData?.dateTo
                      : dateRange[1]
                  }
                  total={statisticsData?.completedOnSiteReservationsRevenue}
                  percent="3.2"
                />
                <StatisticCard
                  title="Active OnSite Reservations Revenue"
                  dateFrom={
                    statisticsData?.dateFrom
                      ? statisticsData?.dateFrom
                      : dateRange[0]
                  }
                  dateTo={
                    statisticsData?.dateTo
                      ? statisticsData?.dateTo
                      : dateRange[1]
                  }
                  total={statisticsData?.activeOnSiteReservationsRevenue}
                  percent="3.2"
                />
              </Box>
            </BottomContainer>
          </Box>
          {/* ) : (
            <Typography sx={{ fontWeight: "600" }}>
              Please select date range for the statistics
            </Typography>
          )} */}
        </Box>
      </Box>
    </>
  );
};

export default Statistics;
