import { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import { Backdrop, Box, Fade, IconButton, Modal, Typography } from "@mui/material"
import { useMedia } from "../../../utils/useMedia";
import GalleryCarousel from "./Carousel";

const Gallery = ({ images }: any) => {
    const { mobileOnly } = useMedia();
    const showArrows = images.length > 1;
    const [open, setOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleGalleryImageOpen = (index: number) => {
        setSelectedImageIndex(index);
        setOpen(true);
    }

    const handleGalleryImageClose = () => {
        setOpen(false);
    }

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePrevImage = () => {
        setSelectedImageIndex((prevIndex) => prevIndex === 0 ? images.length - 1 : prevIndex - 1);
    };

    const handleNextImage = () => {
        setSelectedImageIndex((prevIndex) => prevIndex === images.length - 1 ? 0 : prevIndex + 1);
    };

    const handleDotClick = (index: any) => {
        setSelectedImageIndex(index);
    }

    return (
        <>

            {mobileOnly ? (
                <GalleryCarousel
                    images={images}
                    activeSlide={selectedImageIndex}
                    onPrevImage={handlePrevImage}
                    onNextImage={handleNextImage}
                    onClick={handleGalleryImageOpen}
                    onDotClick={handleDotClick}
                />
            ) : (
                <Box sx={{
                    background: '#EFF0F9',
                    borderRadius: '0px 0px 40px 40px',
                    padding: '35px 24px 45px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                }}>
                    <Box
                        sx={{
                            background: `url(${images[0]})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            flex: '0 0 290px',
                            height: '270px',
                            borderRadius: '11px'
                        }}
                        onClick={() => handleGalleryImageOpen(0)}
                    />
                    <Box sx={{
                        flex: 1,
                        height: '270px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1
                    }}>
                        {images.map((item: any, index: number) => {
                            if (index > 0 && index < 8) {
                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            flex: '0 0 calc(25% - 6px)',
                                            background: `url(${item})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center center',
                                            borderRadius: '11px',
                                            maxHeight: 'calc((270px - 16px) / 2)'
                                        }}
                                        onClick={() => handleGalleryImageOpen(index)}
                                    />
                                )
                            }
                        })}
                        {images.length > 9 &&
                            <Box
                                sx={{
                                    flex: '0 0 calc(25% - 6px)',
                                    borderRadius: '11px',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    maxHeight: 'calc((270px - 16px) / 2)'
                                }}
                                onClick={() => handleGalleryImageOpen(9)}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        background: `url(${images[9]})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center',
                                        filter: 'blur(1px)',
                                    }}
                                />
                                <Typography sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    lineHeight: '17px',
                                    color: '#fff'
                                }}>
                                    +{images.length - 9} More
                                </Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            )}
            <Modal
                open={open}
                onClose={handleGalleryImageClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            outline: 'none',
                            height: '100%',
                            width: '100%',
                            maxHeight: '100vh',
                            overflow: 'hidden',
                        }}
                    >
                        <IconButton
                            sx={{ position: 'absolute', top: '24px', right: '24px', background: '#044D5E' }}
                            onClick={handleGalleryImageClose}
                        >
                            <Close sx={{ fill: '#C5FFFF' }} />
                        </IconButton>
                        <Box
                            sx={{
                                position: 'relative',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                        >
                            <img
                                src={images[selectedImageIndex]}
                                alt="Selected Image"
                                style={{
                                    maxWidth: windowSize.width - 40,
                                    maxHeight: windowSize.height - 40,
                                    width: 'auto',
                                    height: 'auto',
                                }}
                            />
                        </Box>
                        {showArrows &&
                            <>
                                <IconButton
                                    sx={{ position: 'absolute', top: '50%', left: '24px', transform: 'translateY(-50%)', background: '#044D5E' }}
                                    onClick={handlePrevImage}
                                >
                                    <ChevronLeft sx={{ fill: '#C5FFFF' }} />
                                </IconButton>
                                <IconButton
                                    sx={{ position: 'absolute', top: '50%', right: '24px', transform: 'translateY(-50%)', background: '#044D5E' }}
                                    onClick={handleNextImage}
                                >
                                    <ChevronRight sx={{ fill: '#C5FFFF' }} />
                                </IconButton>
                            </>
                        }
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default Gallery;