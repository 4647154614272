export interface Data {
    firstname: string;
    email: string;
    role: string;
    activated: string;
    employmentLocations: string;
    actions: string;
}

interface Column {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const cols: Column[] = [
    {
        id: 'firstname',
        numeric: false,
        disablePadding: false,
        label: 'Потребителско име',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Имейл',
    },
    {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'Потребителска роля',
    },
    {
        id: 'activated',
        numeric: false,
        disablePadding: false,
        label: 'Статус',
    },
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Акција',
    },
];

export default {
    cols
}