import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { getActiveLocationMetadata } from "../../../store/locations/selectors";
import { updateSpot } from "./async-actions";
import {
  getSelectedDate,
  getSelectedInitilizeSpot,
} from "../sidepanel/selectors";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateSpotModal = ({ handleClose }: any) => {
  const [inputError, setInputError] = useState<string>("");

  const dispatch = useAppDispatch();

  const [label, setLabel] = useState("");
  const [peopleCapacity, setPeopleCapacity] = useState("");
  const [reservationCostAmount, setReservationCostAmount] = useState("");
  const [minConsumptionAmount, setConsumption] = useState("");

  const selectedInitilizeSpot = useSelector(getSelectedInitilizeSpot);

  const activeLocation = useSelector(getActiveLocationMetadata);

  const selectedDate = useSelector(getSelectedDate);

  useEffect(() => {
    if(selectedInitilizeSpot && selectedInitilizeSpot.spot) {
      var data = selectedInitilizeSpot.spot;

      setLabel(data.label);
      setPeopleCapacity(data.capacity);
      setReservationCostAmount(data.costAmount);
    }
  }, [selectedInitilizeSpot]);

  const handleLabel = (event: any) => {
    setLabel(event?.target.value);
  };

  const handlePeopleCapacity = (event: any) => {
    setPeopleCapacity(event?.target.value);
  };

  const handleReservationCostAmount = (event: any) => {
    setReservationCostAmount(event?.target.value);
  };

  const handleConsumption = (event: any) => {
    setConsumption(event.target.value);
  };

  const validate = () => {
    if (!label) {
      setInputError("Please provide a label.");
      return false;
    }

    if (!peopleCapacity) {
      setInputError("Please provide a capacity.");
      return false;
    }

    if (!minConsumptionAmount && !reservationCostAmount) {
      setInputError(
        "Please provide at least cost amount or consumption value."
      );
      return false;
    }

    if (reservationCostAmount) {
      const costAmountNumber = Number(reservationCostAmount);
      if (isNaN(costAmountNumber) || costAmountNumber <= 0) {
        setInputError("Please provide a valid cost amount.");
        return false;
      }
    }

    if (minConsumptionAmount) {
      const consumptionNumber = Number(minConsumptionAmount);
      if (isNaN(consumptionNumber) || consumptionNumber <= 0) {
        setInputError("Please provide a valid consumption amount.");
        return false;
      }
    }

    setInputError("");
    return true;
  };

  const handleSubmit = () => {
    const isValid = validate();
    if (!isValid) return;

    var costItems = [];

    if (reservationCostAmount) {
      costItems.push({
        categoryId: "8af5050f-ccdc-4db9-a013-1eddf1bf890c",
        label: "Reservation",
        priceTag: reservationCostAmount,
      });
    }

    if (minConsumptionAmount) {
      costItems.push({
        categoryId: "e918b2be-250b-450b-b114-5001d35b36cd",
        label: "Consumption",
        priceTag: minConsumptionAmount,
      });
    }

    const formData = {
      venueId: activeLocation.id,
      label: label,
      capacity: peopleCapacity,
      costItems: costItems,
    };

    dispatch(
      updateSpot(selectedInitilizeSpot.id, formData, selectedDate, selectedDate)
    );

    handleClose();
  };

  useEffect(() => {
    return () => {
      setLabel("");
      setPeopleCapacity("");
      setReservationCostAmount("");
      setConsumption("");
    };
  }, []);

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update Spot"}</DialogTitle>
        <DialogContent>
          <Grid
            sx={{
              marginTop: "0px",
            }}
            container
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                value={label}
                onChange={handleLabel}
                fullWidth
                label="Label"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                value={peopleCapacity}
                label="Capacity"
                variant="outlined"
                fullWidth
                onChange={handlePeopleCapacity}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                value={reservationCostAmount}
                label="Cost amount"
                variant="outlined"
                fullWidth
                onChange={handleReservationCostAmount}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                value={minConsumptionAmount}
                label="Consumption"
                variant="outlined"
                fullWidth
                onChange={handleConsumption}
                type="number"
              />
            </Grid>
          </Grid>
          {inputError && (
            <div>
              <Alert sx={{ marginTop: "30px" }} severity="error">
                {inputError}
              </Alert>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateSpotModal;
