import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, Settings } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DashboardSidepanelActions } from "./actions";
import { createReservation } from "./async-actions";
import {
  getSelectedDate,
  getSelectedSpots,
  getSelectedInitilizeSpot,
  getSelectedReservedSpot,
  getInitilizeLoading,
  getReservationLoading,
  getCompleteReservationLoading,
  getCancelReservationLoading,
} from "./selectors";
import { useAppDispatch } from "../../../hooks";
import { DefaultUmbrella } from "../../../assets/icons";
import {
  getActiveLocationMetadata,
  getIsLocationActive,
} from "../../../store/locations/selectors";
import {
  cancelReservation,
  completeReservation,
  createSchedule,
  initializeSpot,
  reLinkSpot,
  markIssuedSpot,
  removeSchedulesPromise,
  updateSpot,
} from "../beach-location/async-actions";
import InitializeSpotModal from "../beach-location/InitializeSpotModal";
import MakeReservationModal from "../beach-location/MakeReservationModal";
import ConfirmReservationModal from "../beach-location/ConfirmReservationModal";
import MarkReceiptIssued from "../beach-location/MarkReceiptIssued";
import UpdateSpotModal from "../beach-location/UpdateSpotModal";
import AssignQRModal from "../beach-location/AssignQRModal";
import {
  getLocationReservations,
  getActiveSpotReservations,
  getLocationReservationById,
} from "../beach-location/selector";
import SpotFaultyModal from "../beach-location/SpotDisableModal";
import SpotEnableModal from "../beach-location/SpotEnableModal";
import { useMedia } from "../../../utils/useMedia";
import { hasPermission } from "../../../store/permissions";

const DashboardSidepanel = () => {
  const reservations = useSelector(getLocationReservations);

  const { desktop } = useMedia();

  const dispatch = useAppDispatch();
  const [isInitializeSpotOpened, setIsInitializeSpotOpened] = useState(false);

  const [isReservationModelOpened, setReservationModelOpened] = useState(false);

  const [isCompleteReservationOpened, setCompleteReservationModel] =
    useState(false);

  const [isMarkIssuedOpened, setMarkIssuedModel] = useState(false);

  const [isCancelReservationOpened, setCancelReservationModel] =
    useState(false);

  const initilizeLoading = useSelector(getInitilizeLoading);
  const reservationLoading = useSelector(getReservationLoading);
  const completeReservationLoading = useSelector(getCompleteReservationLoading);
  const cancelReservationLoading = useSelector(getCancelReservationLoading);

  const [isUpdateSpotOpened, setIsUpdateSpotOpened] = useState(false);
  const [isAssignQROpened, setIsAssignQROpened] = useState(false);
  const [isDisableSpotModalOpened, setIsDisableSpotModalOpened] =
    useState(false);
  const [isEnableSpotModalOpened, setIsEnableSpotModalOpened] = useState(false);
  const selectedSpots = useSelector(getSelectedSpots);
  const selectedInitilizeSpot = useSelector(getSelectedInitilizeSpot);
  const selectedReservedSpot = useSelector(getSelectedReservedSpot);

  const selectedDate = useSelector(getSelectedDate);
  const activeLocation = useSelector(getActiveLocationMetadata);
  const date = useSelector(getSelectedDate);
  const isLocationActive = useSelector(getIsLocationActive);

  const reservation = useSelector((state: any) =>
    getLocationReservationById(
      state,
      selectedReservedSpot && selectedReservedSpot.id
    )
  );

  const activeSpotReservations = useSelector((state: any) =>
    getActiveSpotReservations(state, selectedSpots[0] && selectedSpots[0].id)
  );

  const handleOnChange = (value: any) => {
    const formatedDate = dayjs(value).format("YYYY-MM-DD");
    dispatch(DashboardSidepanelActions.setSelectedDate(formatedDate));
  };

  const handleRemoveSpot = (id: string) => {
    dispatch(DashboardSidepanelActions.removeSelectedSpot(id));
  };

  const colors = [
    {
      bg: "#044D5E",
      color: "#ABFFC1",
      title: "Свободно",
    },
    {
      bg: "#EDEFF2",
      color: "#EE5A5A",
      title: "Резервирано",
    },
    {
      bg: "#EDEFF2",
      color: "#5A6977",
      title: "Блокирано",
    },
  ];

  const handleInitializeSpot = () => {
    setIsInitializeSpotOpened(!isInitializeSpotOpened);
  };

  const handleInitializeSpotModalClose = () => {
    setIsInitializeSpotOpened(!isInitializeSpotOpened);
  };

  const handleInitialize = (data: any, plateId?: string) => {
    const fromDate = selectedDate;
    const untilDate = selectedDate;

    const formData = {
      venueId: activeLocation.id,
      ...data,
    };

    dispatch(
      initializeSpot(
        selectedSpots[0].id,
        formData,
        fromDate,
        untilDate,
        plateId
      )
    );
    handleInitializeSpotModalClose();
  };

  const makeReservationModal = () => {
    setReservationModelOpened(!isReservationModelOpened);
  };

  const handleReserve = (data: any) => {
    makeReservationModal();
    console.log(`Posting message`);
    window.parent.postMessage({ type: "bluetooth", payload: data }, "*");
    dispatch(createReservation(data));
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisableSpotModal = () => {
    if (selectedSpots[0].reserved) {
      setOpenMessage(true);
    } else {
      setIsDisableSpotModalOpened(!isDisableSpotModalOpened);
    }
    handleClose();
  };

  const handleEnableSpotModal = () => {
    setIsEnableSpotModalOpened(!isEnableSpotModalOpened);
    handleClose();
  };

  const generateDateRange = (fromDate: any, toDate: any) => {
    const dateRange = [];
    let currentDate = fromDate;

    while (
      currentDate.isSame(toDate, "day") ||
      currentDate.isBefore(toDate, "day")
    ) {
      dateRange.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    return dateRange;
  };

  const handleDisableSpot = (fromDate: string, toDate: string) => {
    const range = generateDateRange(fromDate, toDate);
    dispatch(
      createSchedule(
        activeLocation.id,
        [selectedSpots[0].id],
        range,
        selectedDate,
        selectedDate
      )
    );
    setIsDisableSpotModalOpened(!isDisableSpotModalOpened);
  };

  const handleEnableSpot = (schedules: any) => {
    dispatch(
      removeSchedulesPromise(
        schedules,
        activeLocation.id,
        selectedDate,
        selectedDate
      )
    );
    setIsEnableSpotModalOpened(!isEnableSpotModalOpened);
  };

  const handleUpdateSpot = () => {
    setIsUpdateSpotOpened(!isUpdateSpotOpened);
    handleClose();
  };

  const handleUpdateSpotModalClose = () => {
    setIsUpdateSpotOpened(!isUpdateSpotOpened);
  };

  // const handlePriceChange = () => {
  //   dispatch(
  //     updateSpot(
  //       activeLocation.id,
  //       selectedSpots[0].id,
  //       {
  //         reservationCostAmount: price === "" ? null : price,
  //         label: label === "" ? null : label,
  //         peopleCapacity: capacity === "" ? null : Number(capacity),
  //       },
  //       selectedDate,
  //       selectedDate
  //     )
  //   );
  //   handleUpdateSpotModalClose();
  // };


  const handleAssignQR = () => {
    setIsAssignQROpened(!isAssignQROpened);
    handleClose();
  };

  const handleAssign = () => {
    handleAssignQR();
  };


  const handleAssignQRModalClose = () => {
    setIsAssignQROpened(!isAssignQROpened);
  };

  const completeReservationModel = () => {
    setCompleteReservationModel(!isCompleteReservationOpened);
  };

  const cancelReservationModel = () => {
    setCancelReservationModel(!isCancelReservationOpened);
  };

  const handleCompleteReservation = () => {
    var spotReserve = reservations.find(
      (e: any) => e.spotId == selectedReservedSpot.id && e.status == "ACTIVE"
    );

    if (spotReserve) {
      dispatch(
        completeReservation(spotReserve.id, activeLocation.id, selectedDate)
      );
    }
    completeReservationModel();
  };

  const handleCancelReservation = () => {
    var spotReserve = reservations.find(
      (e: any) => e.spotId == selectedReservedSpot.id && e.status == "ACTIVE"
    );
    if (spotReserve) {
      dispatch(
        cancelReservation(spotReserve.id, activeLocation.id, selectedDate)
      );
    }
    cancelReservationModel();
  };

  const markIssueReservationModel = () => {
    setMarkIssuedModel(!isMarkIssuedOpened);
  };

  const cancelMarkIssueReservationModel = () => {
    setMarkIssuedModel(!isMarkIssuedOpened);
  };

  const handleMarkIssue = () => {
    dispatch(markIssuedSpot(reservation.id, activeLocation.id, selectedDate));

    cancelMarkIssueReservationModel();
  };

  const [openMessage, setOpenMessage] = useState(false);
  const handleMessageClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessage(false);
  };

  return (
    <Box>
      {desktop && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
          }}
        >
          {colors.map((item: any, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Box
                  sx={{
                    background: item.bg,
                    width: "50px",
                    height: "50px",
                    borderRadius: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DefaultUmbrella color={item.color} />
                </Box>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "14px",
                    marginTop: "8px",
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          "& .MuiDateCalendar-root": {
            width: "100%",
          },
          "& .MuiButtonBase-root.MuiPickersDay-root": {
            width: "26px",
            height: "26px",
          },
          "& .MuiMonthCalendar-root": {
            width: "100%",
          },
          "& .MuiPickersMonth-monthButton": {
            padding: "5px 12px",
            width: "60px",
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["DateCalendar", "DateCalendar"]}
            sx={{
              width: "100%",
            }}
          >
            <DemoItem>
              <DateCalendar
                sx={{
                  "& .MuiPickersSlideTransition-root": {
                    minHeight: "200px",
                  },
                }}
                value={dayjs(selectedDate)}
                views={["month", "day"]}
                onChange={(newValue: any) => handleOnChange(newValue)}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </Box>

      {desktop && (
        <div>
          <Box
            sx={{
              padding: "0px 15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* Make a reservation */}
            {selectedInitilizeSpot && hasPermission("CREATE_RESERVATION") && (
              <Box sx={{ position: "relative", width: "100%" }}>
                <Button
                  sx={{
                    flex: 1,
                    mr: 2,
                    background: "#044D5E",
                    color: "#C5FFFF",
                    "&[disabled]": {
                      background: "#EDEFF2",
                      color: "#06191E",
                      opacity: "0.2",
                    },
                  }}
                  fullWidth
                  disabled={reservationLoading}
                  onClick={makeReservationModal}
                >
                  Направи резервация
                </Button>

                {reservationLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-10px",
                      marginLeft: "-16px",
                    }}
                  />
                )}
              </Box>
            )}

            {/* Initilize Spot */}
            {!selectedInitilizeSpot && (
              <Box sx={{ position: "relative", width: "100%" }}>
                <Button
                  sx={{
                    flex: 1,
                    mr: 2,
                    background: "#C5FFFF",
                    color: "#044D5E",
                    "&[disabled]": {
                      background: "#EDEFF2",
                      color: "#06191E",
                    },
                  }}
                  fullWidth
                  disabled={
                    initilizeLoading ||
                    !selectedSpots.length ||
                    selectedSpots.length !== 1 ||
                    (selectedSpots[0] && !!selectedSpots[0]?.label)
                  }
                  onClick={handleInitializeSpot}
                >
                  Активиране
                </Button>

                {initilizeLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-10px",
                      marginLeft: "-16px",
                    }}
                  />
                )}
              </Box>
            )}

            <Tooltip title="Settings">
              <IconButton
                sx={{
                  color: "#044D5E",
                }}
                onClick={handleSettingsClick}
              >
                <Settings />
              </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {selectedSpots.length !== 1 && (
                <MenuItem disabled={true}>Блокирай</MenuItem>
              )}
              {selectedSpots.length === 1 ? (
                selectedSpots[0] && !selectedSpots[0].disabled ? (
                  <MenuItem onClick={handleDisableSpotModal}>Блокирай</MenuItem>
                ) : (
                  <MenuItem onClick={handleEnableSpotModal}>
                    Отблокирай
                  </MenuItem>
                )
              ) : null}
              <MenuItem
                disabled={selectedInitilizeSpot == null}
                onClick={handleUpdateSpot}
              >
                Промяна на конфигурация
              </MenuItem>
              <MenuItem
                disabled={selectedInitilizeSpot == null}
                onClick={handleAssignQR}
              >
                Промяна на QR код
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            sx={{
              marginLeft: "15px",
              marginTop: "16px",
              marginBottom: "8px",
              fontSize: "16px",
            }}
          >
            Избрани места
          </Typography>

          <Box
            sx={{
              padding: "0px 15px",
            }}
          >
            {selectedSpots.length > 0 ? (
              <>
                {selectedSpots.some((spot: any) => !spot.label) && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      background: "#EDEFF2",
                      color: "#EE5A5A",
                      padding: "4px 8px",
                      borderRadius: "12px",
                    }}
                  >
                    Избрани са неактивни места!
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: "8px",

                    mt: 2,
                  }}
                >
                  {selectedSpots.map((spot: any) => {
                    const label = !!spot.label
                      ? spot.label
                      : spot.id.slice(0, 5);
                    return (
                      <Box key={spot.id}>
                        <Chip
                          label={label}
                          deleteIcon={
                            <Close
                              sx={{
                                width: "16px",
                              }}
                            />
                          }
                          onClick={() => handleRemoveSpot(spot.id)}
                          onDelete={() => handleRemoveSpot(spot.id)}
                          sx={{
                            color: !!spot.label ? "#fff" : "#EE5A5A",
                            background: !!spot.label ? "#06191E" : "#EDEFF2",
                            fontWeight: 700,
                            fontSize: "12px",
                            lineHeight: "12px",
                            letterSpacing: ".15px",
                            "& svg": {
                              fill: !!spot.label ? "#fff" : "#5A6977",
                            },
                          }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </>
            ) : (
              <Typography
                sx={{
                  marginBottom: "8px",
                  fontSize: "12px",
                }}
              >
                Няма избрани места!
              </Typography>
            )}

            {selectedReservedSpot &&
              reservation.fiscalReceiptIssued == false &&
              activeLocation.fiscalDeviceSupported == true && (
                <Button
                  sx={{
                    background: "#C5FFFF",
                    color: "#044D5E",
                    marginTop: "15px",
                    "&[disabled]": {
                      background: "#EDEFF2",
                      color: "#06191E",
                      opacity: "0.2",
                    },
                  }}
                  fullWidth
                  onClick={markIssueReservationModel}
                >
                  Принтирай фискален бон
                </Button>
              )}

            {/* Complete Reservation */}
            {selectedReservedSpot &&
              hasPermission("MANAGE_RESERVATIONS") &&
              !dayjs(date).isAfter(dayjs(), "day") && (
                <Box sx={{ position: "relative", width: "100%" }}>
                  <Button
                    sx={{
                      background: "#044D5E",
                      color: "#C5FFFF",
                      marginTop: "15px",
                      "&[disabled]": {
                        background: "#EDEFF2",
                        color: "#06191E",
                        opacity: "0.2",
                      },
                    }}
                    fullWidth
                    disabled={completeReservationLoading}
                    onClick={completeReservationModel}
                  >
                    Завърши резервация
                  </Button>

                  {completeReservationLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-10px",
                        marginLeft: "-16px",
                      }}
                    />
                  )}
                </Box>
              )}

            {/* Cancel Reservation */}
            {selectedReservedSpot && hasPermission("MANAGE_RESERVATIONS") && (
              <Box sx={{ position: "relative", width: "100%" }}>
                <Button
                  sx={{
                    background: "#ee5a5a",
                    color: "#fff",
                    marginTop: "15px",
                    marginBottom: "5px",
                    "&[disabled]": {
                      background: "#EDEFF2",
                      color: "#06191E",
                      opacity: "0.2",
                    },
                  }}
                  fullWidth
                  disabled={cancelReservationLoading}
                  onClick={cancelReservationModel}
                >
                  Канселирай резервация
                </Button>

                {cancelReservationLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-10px",
                      marginLeft: "-16px",
                    }}
                  />
                )}
              </Box>
            )}
            <br />
          </Box>
        </div>
      )}

      {isInitializeSpotOpened && (
        <InitializeSpotModal
          selectedSpot={selectedSpots[0]}
          handleInitialize={handleInitialize}
          handleClose={handleInitializeSpotModalClose}
        />
      )}

      {isReservationModelOpened && (
        <MakeReservationModal
          handleReservation={handleReserve}
          handleClose={makeReservationModal}
        />
      )}

      {isCompleteReservationOpened && (
        <ConfirmReservationModal
          isComplete={true}
          handleConfirm={handleCompleteReservation}
          handleClose={completeReservationModel}
        />
      )}

      {isCancelReservationOpened && (
        <ConfirmReservationModal
          isComplete={false}
          handleConfirm={handleCancelReservation}
          handleClose={cancelReservationModel}
        />
      )}

      {isMarkIssuedOpened && (
        <MarkReceiptIssued
          handleConfirm={handleMarkIssue}
          handleClose={cancelMarkIssueReservationModel}
        />
      )}

      {isUpdateSpotOpened && (
        <UpdateSpotModal
          handleClose={handleUpdateSpotModalClose}
        />
      )}

      {isAssignQROpened && (
        <AssignQRModal
          selectedInitilizeSpot={selectedInitilizeSpot}
          handleAssign={handleAssign}
          handleClose={handleAssignQRModalClose}
        />
      )}

      {isDisableSpotModalOpened && (
        <SpotFaultyModal
          selectedSpot={selectedSpots[0]}
          handleDisable={handleDisableSpot}
          handleClose={handleDisableSpotModal}
        />
      )}

      {isEnableSpotModalOpened && (
        <SpotEnableModal
          selectedSpot={selectedSpots[0]}
          handleEnable={handleEnableSpot}
          handleClose={handleEnableSpotModal}
        />
      )}
      <Stack spacing={2} sx={{ maxWidth: "500px" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={openMessage}
          autoHideDuration={6000}
          onClose={handleMessageClose}
          sx={{
            "& .MuiPaper-root": {
              background: "#044D5E",
            },
          }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="error"
            sx={{
              "& .MuiAlert-message": {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            Това място има активни резервации за избраните дни{" "}
            {activeSpotReservations &&
              activeSpotReservations.length &&
              activeSpotReservations.map((spot: any, index: number) => {
                return (
                  <Fragment key={index}>
                    &nbsp;{dayjs(spot.date).format("DD/MM/YYYY")}
                    {activeSpotReservations.length - (index + 1) !== 0
                      ? ", "
                      : ""}
                  </Fragment>
                );
              })}
            . За да го блокирате първо трябва да канселирате резервациите.
          </MuiAlert>
        </Snackbar>
      </Stack>
    </Box>
  );
};

export default DashboardSidepanel;
