import { createSelector } from "@reduxjs/toolkit"
import { getSelectedDate } from "../sidepanel/selectors";

const getLayout = (state: any) => state.layout;
export const getLoading = createSelector(getLayout, layout => layout.loading);
export const getSnackbar = createSelector(getLayout, login => login.snackbar);
export const getLayoutItems = createSelector(getLayout, layout => layout.builder);
export const getSpots = createSelector(getLayout, layout => layout.spots);
export const getDimensions = createSelector(getLayout, layout => layout.dimensions);
export const getPlates = createSelector(getLayout, layout => layout.plates);

export const getSearchedSpots = createSelector(getLayout, layout => layout.searchResults);
export const getScannerData = createSelector(getLayout, layout => layout.scannerData);
export const getSpotAvailability = createSelector(getLayout, layout => layout.spotAvailability);
export const getLocationReservations = createSelector(getLayout, layout => layout.reservations);
export const getSpotNotes = createSelector(getLayout, layout => layout.selectedSpotNotes);
// export const locReservations2 = createSelector(getLayout, layout => layout.reservations);

export const getLocationReservationById = createSelector(
    [getLocationReservations, (_: any, spotId: string) => spotId],
    (reservations, spotId) => {
        if (reservations && spotId) {
            return reservations.find((reservation: any) => reservation.spotId === spotId && reservation.status === 'ACTIVE');
        }

        return null;
 });

// export const getLocationReservationById = createSelector(
//     [getLocationReservations, (_: any, spotId: string) => spotId, (_: any, __: string, selectedDate: string) => selectedDate],
//     (reservations, spotId, selectedDate) => {
//         if (reservations && spotId && selectedDate) {
//             return reservations.find((reservation: any) => reservation.spotId === spotId && reservation.date === selectedDate && reservation.status === 'ACTIVE');
//         }

//         return null;
//     });

export const getLocationReservationByDate = createSelector(
    [getLocationReservations, getSelectedDate],
    (reservations, selectedDate) => {
        if (reservations && selectedDate) {
            return reservations.filter((reservation: any) => reservation.date === selectedDate);
        }

        return null;
    });

export const getSchedules = createSelector(getLayout, layout => layout.schedules);

export const getActiveSpotReservations = createSelector(
    [getLocationReservations, (_: any, spotId: string) => spotId],
    (reservations, spotId) => {
        if (reservations && spotId) {
            const filtered = reservations.filter((r: any) => r.spotId === spotId);
            if (filtered.length > 0) {
                return filtered.filter((s: any) => s.status === 'ACTIVE');
            }
            return null;
        }

        return null;
    });
