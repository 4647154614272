import { Box, Button, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getScannerData } from "./selector";

import iconFree from '../../../assets/icon-free.svg';
import iconReserved from '../../../assets/icon-reserved.svg';
import styled from "styled-components";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper'
};

const IconFree = styled.img.attrs((props: any) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
`;

const IconReserved = styled.img.attrs((props: any) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
`;

const ScannerModal = ({
    handleClose,
    handleCheckAgain
}: any) => {

    const [isReservationValid, setIsReservationValid] = useState(null);
    const scannerResult = useSelector(getScannerData);

    useEffect(() => {
        if (scannerResult) {
            setIsReservationValid(scannerResult.valid);
        }
    }, [scannerResult]);

    return (
        <Modal
            open={true}
            onClose={handleClose}
            sx={{
                '& .MuiBox-root': {
                    borderRadius: '4px'
                }
            }}
        >
            <Box>
                <Box sx={style}>
                    <Box sx={{
                        p: '16px 24px'
                    }}>
                        <Typography sx={{
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '32px',
                            letterSpacing: '.15px',
                            textAlign: 'center'
                        }}>
                            Проверка на резервация
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {isReservationValid && <IconFree src={iconFree} />}
                        {isReservationValid === false && <IconReserved src={iconReserved} />}
                        {isReservationValid === true &&
                            <Typography sx={{
                                fontWeight: 400,
                                fontSize: '32px',
                                lineHeight: '47.5px',
                                letterSpacing: '.4px'
                            }}>
                                Валидна резервация
                            </Typography>
                        }
                        {isReservationValid === false &&
                            <Typography sx={{
                                fontWeight: 400,
                                fontSize: '32px',
                                lineHeight: '47.5px',
                                letterSpacing: '.4px'
                            }}>Невалидна резервация</Typography>
                        }
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0 40px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: '16px 0'
                        }}>
                        </Box>
                    </Box>
                    <Box sx={{
                        padding: '16px 40px',
                        display: 'flex',
                        justifyContent: 'end',
                        gap: 1
                    }}>
                        <Button
                            sx={{
                                color: '#044D5E'
                            }}
                            onClick={handleClose}
                        >
                            Отказ
                        </Button>
                        <Button
                            sx={{
                                background: '#044D5E',
                                color: '#C5FFFF',
                                '&:hover': {
                                    background: '#044D5E'
                                }
                            }}
                            onClick={handleCheckAgain}
                        >
                            Ново сканиране
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default ScannerModal;
