import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import dayjs from "dayjs";

interface StatisticCardProps {
  title: string;
  dateFrom: string | undefined;
  dateTo: string | undefined;
  total: string;
  percent: string;
}

const StatisticCard = ({
  title,
  dateFrom,
  dateTo,
  total,
  percent,
}: StatisticCardProps) => {
  const dateFromFormatted = dayjs(dateFrom).format("MM.DD.YYYY");
  const dateToFormatted = dayjs(dateTo).format("MM.DD.YYYY");

  return (
    <Box
      sx={{
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        p: "15px",
        width: "fit-content",
        borderRadius: "5px",
        m: "5px",
        flex: "1",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "90%" }}>
          <Typography variant="h5" sx={{}}>
            {title}
          </Typography>
        </div>
        <InfoOutlinedIcon
          sx={{ color: "grey", fontSize: "30px", ml: "10px" }}
        />
      </Box>
      <Typography sx={{ color: "grey" }}>
        {dateFromFormatted} - {dateToFormatted}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: "45px",
        }}
      >
        <Typography variant="h3" sx={{ wordBreak: "break-all" }}>
          {total ? total : '0'}
        </Typography>
        <Typography variant="h4" sx={{ ml: "5px" }}>
          BGN
        </Typography>
      </Box>

      {/* <Box sx={{display: 'flex', alignItems: 'center', mt: '10px'}}>
            <div style={{border: '3px solid rgb(2, 99, 121)', width: 'fit-content', lineHeight: '0px'}}>
                <TrendingUpOutlinedIcon sx={{color:'rgb(2, 99, 121)', fontSize: '15px'}}/>
            </div>
            <Typography sx={{ml: '5px', fontSize: '16px', color:'rgb(2, 99, 121)', fontWeight:'500'}}>+{percent}% for the period selected</Typography>
        </Box> */}
    </Box>
  );
};

export default StatisticCard;
