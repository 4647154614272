import {GoogleMap, Marker, useLoadScript} from '@react-google-maps/api';
import dayjs from "dayjs";
import {Box, Container, Tooltip, Typography} from "@mui/material";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WifiIcon from '@mui/icons-material/Wifi';
import WcIcon from '@mui/icons-material/Wc';
import ShowerIcon from '@mui/icons-material/Shower';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import {SportsVolleyball} from '@mui/icons-material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Gallery from "./Gallery";
import locationMarker from '../../../assets/location-marker.svg';
import {
    Bar,
    DefaultLifeguard,
    DefaultPool,
    DefaultSunbed,
    DefaultTent,
    DefaultUmbrella,
    DressingRoom,
    KidsPlayground,
    MedicalPoint
} from '../../../assets/icons';
import {RefundTypesEnum} from "../../../utils";

const mapAmenityToImage: any = {
    "MUSIC": <MusicNoteIcon sx={{color: '#044D5E', fontSize: '20px'}}/>,
    "WC": <WcIcon sx={{color: '#044D5E', fontSize: '20px'}}/>,
    "WI_FI": <WifiIcon sx={{color: '#044D5E', fontSize: '20px'}}/>,
    "PARKING_LOT": <LocalParkingIcon sx={{color: '#044D5E', fontSize: '20px'}}/>,
    "SHOWER": <ShowerIcon sx={{color: '#044D5E', fontSize: '20px'}}/>,
    "BEVEREAGE": <LocalBarIcon sx={{color: '#044D5E', fontSize: '20px'}}/>,
    "BAR": <Bar/>,
    "UMBRELLAS": <DefaultUmbrella color='#044D5E'/>,
    "VOLLEYBALL_FIELD": <SportsVolleyball sx={{color: '#044D5E', fontSize: '20px'}}/>,
    "KIDS_PLAYGROUND": <KidsPlayground/>,
    "DRESSING_ROOM": <DressingRoom/>,
    "POOL": <DefaultPool color='#044D5E'/>,
    "SUNBEDS": <DefaultSunbed color='#044D5E'/>,
    "TENTS": <DefaultTent color='#044D5E'/>,
    "RESTAURANT": <RestaurantIcon sx={{color: '#044D5E', fontSize: '20px'}}/>,
    "MEDICAL_POINT": <MedicalPoint/>,
    "LIFEGUARD": <DefaultLifeguard color='#044D5E'/>
}

const getRefundPolicyTranslation = (policy: RefundTypesEnum): string => {
    switch (policy) {
        case RefundTypesEnum.FULL_REFUND:
            return "Пълно";
        case RefundTypesEnum.PARTIAL_REFUND:
            return "Частично";
        case RefundTypesEnum.NO_REFUND:
            return "Без възстановяване";
        default:
            return "Неизвестна политика";
    }
};

const Information = ({location: venue}: any) => {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
    });

    const center = {
        lat: Number(venue.latitude),
        lng: Number(venue.longitude)
    };

    let isOpened = false;
    const currentDateTime = dayjs();
    const openingTime = dayjs(venue.openingHour, "HH:mm:ss");
    const closingTime = dayjs(venue.closingHour, "HH:mm:ss");

    if (currentDateTime.isAfter(openingTime) && currentDateTime.isBefore(closingTime)) {
        isOpened = true;
    } else {
        isOpened = false;
    }

    return (
        <Box>
            {venue &&
                <Gallery images={venue.galleryImageUrls}/>
                // TODO:: Uncomment when fetch data from api
                // <Gallery images={location.imageUrls} />
            }
            <Container maxWidth="md" sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                mt: '40px',
                mb: '40px'
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: {xs: 'baseline', sm: 'center'},
                    justifyContent: 'space-between',
                    flexDirection: {xs: 'column', sm: 'row'}
                }}>
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <WatchLaterIcon sx={{
                            color: isOpened ? '#2AC152' : '#EE5A5A',
                            mr: 1
                        }}/>
                        <Typography sx={{
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '.15px'
                        }}>
                            {isOpened ? "Отворено" : "Затворено"}
                        </Typography>
                        <Box sx={{
                            width: '4px',
                            height: '4px',
                            background: '#044D5E',
                            borderRadius: '100%',
                            m: '0 8px'
                        }}/>
                        <Typography sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '18px',
                            letterSpacing: '.15px'
                        }}>
                            {isOpened ? `Затваря в ${closingTime.format('HH:mm')}` : `Отваря в ${openingTime.format('HH:mm')}`}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Typography sx={{
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '.15px'
                        }}>
                            Работни часове
                        </Typography>
                        <Typography sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '18px',
                            letterSpacing: '.15px',
                            ml: 1
                        }}>
                            {openingTime.format('HH:mm')} to {closingTime.format('HH:mm')}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1
                }}>
                    {venue.amenities.map((amenity: string, index: number) => {
                        return (
                            <Tooltip title={amenity.replace(/_/g, ' ').toLowerCase()} key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Box sx={{
                                        width: '44px',
                                        height: '44px',
                                        borderRadius: '14px',
                                        background: '#EFF0F9',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {mapAmenityToImage[amenity]}
                                    </Box>
                                </Box>
                            </Tooltip>
                        )
                    })}
                </Box>
                <Box>
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '20px',
                        lineHeight: '32px',
                        letterSpacing: '.15px'
                    }}>
                        {venue.name}
                    </Typography>
                    <Typography sx={{
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '.15px',
                        wordBreak: 'break-all'
                    }}>
                        {venue.description}
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '20px',
                        lineHeight: '32px',
                        letterSpacing: '.15px'
                    }}>
                        Как да стигнете до {venue.name}, {venue.city}
                    </Typography>
                    {isLoaded && (
                        <GoogleMap
                            mapContainerStyle={{
                                width: "100%",
                                height: "400px",
                                borderRadius: '16px',
                                marginTop: '16px'
                            }}
                            center={center}
                            zoom={15}
                        >
                            <Marker
                                position={center}
                                animation={google.maps.Animation.BOUNCE}
                                icon={{

                                    url: locationMarker,
                                    scale: 20,
                                    strokeColor: '#044D5E',
                                    strokeOpacity: 0.5,
                                    fillColor: '#044D5E',
                                    fillOpacity: 1,
                                    strokeWeight: 20
                                }}
                            />
                        </GoogleMap>
                    )}
                </Box>
                <Box>
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '20px',
                        lineHeight: '32px',
                        letterSpacing: '.15px'
                    }}>
                        Политика за анулиране <span style={{
                        fontWeight: '400',
                        fontSize: '20px',
                        lineHeight: '32px',
                        letterSpacing: '.15px'
                    }}>{getRefundPolicyTranslation(venue.refundPolicy)}</span>
                    </Typography>
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '20px',
                        lineHeight: '32px',
                        letterSpacing: '.15px'
                    }}>
                        Процент на възстановяване <span style={{
                        fontWeight: '400',
                        fontSize: '20px',
                        lineHeight: '32px',
                        letterSpacing: '.15px'
                    }}>{`${venue.refundPercentage * 100}%`}</span>
                    </Typography>
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '20px',
                        lineHeight: '32px',
                        letterSpacing: '.15px'
                    }}>
                        Срок на възстановяване <span style={{
                        fontWeight: '400',
                        fontSize: '20px',
                        lineHeight: '32px',
                        letterSpacing: '.15px'
                    }}>{venue.refundValidityDays} дни </span>
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Information;
