import { SET_LOADING, SHOW_SNACKBAR } from "./types";

const loadingState = (isLoading: boolean) => ({
  type: SET_LOADING,
  payload: isLoading,
});

const snackbarState = (snackbar: {
  show: boolean;
  isError: boolean;
  message: string;
}) => ({
  type: SHOW_SNACKBAR,
  payload: snackbar,
});

export const LoginActions = {
  loadingState,
  snackbarState,
};
