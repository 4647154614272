import {
  ACCOUNT_ACTIVATED,
  ACCOUNT_FAILED,
  SET_LOADING,
  SHOW_SNACKBAR,
} from "./types";

const accountActivated = (activated: boolean) => ({
  type: ACCOUNT_ACTIVATED,
  payload: activated,
});

const accountFailed = (activated: boolean) => ({
  type: ACCOUNT_FAILED,
  payload: activated,
});

const loadingState = (isLoading: boolean) => ({
  type: SET_LOADING,
  payload: isLoading,
});

const snackbarState = (snackbar: {
  show: boolean;
  isError: boolean;
  message: string;
}) => ({
  type: SHOW_SNACKBAR,
  payload: snackbar,
});

export const AccountAction = {
  accountActivated,
  accountFailed,
  loadingState,
  snackbarState,
};
