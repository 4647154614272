import { useState, MouseEvent, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TableHeader from "./Header";
import columns from "./config";
import { Settings } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getLocationsList } from "../../../../store/locations/selectors";

import { useAppDispatch } from "../../../../hooks";
import { hasPermission } from "../../../../store/permissions";
import { deleteLocation } from "../../../../store/locations/async-actions";
// import { deleteEmployee, resendActivationLink } from '../async-actions';

interface RowData {
  id: string;
  name: string;
  contactEmail: string;
  contactPhoneNumber: string;
  city: string;
  type: string;
  priceLevel: string;
  description: string;
  keywords: string[];
  amenities: string[];
  coverImageUrl: string;
  galleryImageUrls: string[];
  openingHour: string;
  closingHour: string;
  acceptReservationsUntilHour: string;
  reservationsValidUntilHour: string;
  latitude: string;
  longitude: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  data: RowData[];
  listType: string;
}

const EnhancedTable: React.FC<EnhancedTableProps> = ({ data, listType }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteDialog, setDeleteDialog] = useState(false);

  const [deleteLocationData, setDeleteLocationData] = useState<any>({});

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof any>("id");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [anchorEls, setAnchorEls] = useState<any>({});
  const allVenues = useSelector(getLocationsList);

  const rowsLength = data && data.length;

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSettingsClick = (
    event: MouseEvent<HTMLButtonElement>,
    row: RowData
  ) => {
    setAnchorEls({ ...anchorEls, [row.id]: event.currentTarget });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsLength) : 0;

  const visibleRows = stableSort(data, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const openDeleteDialog = (row: any) => {
    setDeleteLocationData(row);
    setDeleteDialog(!deleteDialog);
    handleClose(row.id);
  };

  const handleDeleteClose = () => {
    setDeleteDialog(false); 
    dispatch(deleteLocation(deleteLocationData.id));
  };


  const handleDeleteLocation = () => {

    setDeleteDialog(!deleteDialog);
  }

  const handleClose = (row: RowData) => {
    setAnchorEls({ [row.id]: null });
  };

  const handleEditClick = (row: RowData) => {
    navigate(`/edit-location/${row.id}`, { state: { row, listType } });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rowsLength}
            config={columns}
          />
          <TableBody>
            {visibleRows.length > 0 ? (
              visibleRows.map((row: any, index: number) => {
                return (
                  <TableRow
                    key={index}
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    tabIndex={-1}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.contactEmail}</TableCell>
                    <TableCell align="left">{row.contactPhoneNumber}</TableCell>
                    <TableCell align="left">{row.city}</TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">{row.priceLevel}</TableCell>
                    <TableCell align="left">
                      {(hasPermission("UPDATE_VENUE") ||
                        hasPermission("UPDATE_UNAPPROVED_LOCATION") ||
                        hasPermission("CREATE_VENUE_LAYOUT")) && (
                        <>
                          <IconButton
                            onClick={(event: any) =>
                              handleSettingsClick(event, row)
                            }
                          >
                            <Settings />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEls[row.id]}
                            open={Boolean(anchorEls[row.id])}
                            keepMounted
                            onClose={() => handleClose(row)}
                          >
                            {(hasPermission("UPDATE_VENUE") ||
                              hasPermission("UPDATE_UNAPPROVED_LOCATION")) && (
                                <MenuItem onClick={() => handleEditClick(row)}>
                                  Edit
                                </MenuItem>
                              )}

                            {!row.activated &&
                              hasPermission("CREATE_VENUE_LAYOUT") && (
                                <MenuItem>
                                  <Link
                                    to={`/create-layout/${row.id}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                    }}
                                  >
                                    View Layout
                                  </Link>
                                </MenuItem>
                              )}

                            { hasPermission("DELETE_LOCATION") && (
                              <MenuItem onClick={() => openDeleteDialog(row)}>
                                Delete
                              </MenuItem>
                            )}
                          </Menu>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.cols.length + 1}>
                  <Typography sx={{ textAlign: "center" }}>
                    No results!
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[12, 24, 48]}
        component="div"
        count={rowsLength}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={deleteDialog}
        keepMounted
        fullWidth
        maxWidth="xs"
        onClose={handleDeleteClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Изтрий"}</DialogTitle>
        <DialogContent>
          {"Сигурни ли сте, че искате да изтриете това местоположение?"}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Отказ</Button>
          <Button
            sx={{
              fontWeight: "500",
              background: "#ee5a5a",
              color: "#fff",
              "&:disabled": {
                background: "#EDEFF2",
                color: "#5A6977",
              },
            }}
            onClick={handleDeleteLocation}
          >
            Изтрий
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EnhancedTable;
