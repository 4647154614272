import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store.ts';
import App from './App.tsx';
import './index.css';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import React, { useState, useEffect } from 'react';
import FloatingConsole from './mobile/FloatingDataDump.tsx';

const theme = {};

const Main = () => {
	const [debugMode, setDebugMode] = useState<boolean>(false);

	useEffect(() => {
		const storedDebugMode = localStorage.getItem('enable_debug_mode');
		setDebugMode(storedDebugMode === 'true');
	}, []);

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					{debugMode && <FloatingConsole />}
					<App />
				</Provider>
			</ThemeProvider>
		</BrowserRouter>
	);
};

createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
