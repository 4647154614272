import { useEffect, useState } from "react";
import { Box, TextField, CircularProgress } from "@mui/material";
import { useAppDispatch } from "../../../hooks";
import { getPendingVenues } from "./async-actions";
import Table from "./table/Table";
import { useSelector } from "react-redux";
import { pendingList, getLoading } from "./selectors";

const venueManagement = () => {
  const dispatch = useAppDispatch();

  const loading = useSelector(getLoading);

  const [searchTerm, setSearchTerm] = useState("");

  const venues = useSelector(pendingList);
  const handleSearchTermChange = (event: any) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  useEffect(() => {
    dispatch(getPendingVenues());
  }, []);

  const searchedData = venues.filter((e: any) => {
    //indexof
    if (searchTerm !== "") {
      return (
        e.name.toLowerCase().includes(searchTerm) ||
        e.contactEmail.toLowerCase().includes(searchTerm) ||
        e.contactPhoneNumber.toLowerCase().includes(searchTerm)
      );
    }
    return e;
  });

  return (
    <Box sx={{ pt: 3, pr: 3, pl: 3, position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" },
          gap: 2,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "auto" },
            flex: { xs: "100%", sm: "0 0 30%" },
          }}
        >
          <TextField
            label="Search"
            id="search-field"
            placeholder="Name, email, phone"
            size="small"
            sx={{
              background: "#F4F7FE",
              color: "#000",
              boxShadow: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)",
              width: "100%",
              fontWeight: "600",
              "& fieldset": {
                border: "none",
              },
            }}
            onChange={handleSearchTermChange}
          />
        </Box>
      </Box>
      {venues && venues.length >= 0 && (
        <Box sx={{ mt: 2, width: { xs: "calc(100vw - 48px)", sm: "auto" } }}>
          {loading ? (
            <div className="center-loader">
              <CircularProgress />
            </div>
          ) : (
            <Table data={searchedData} listType="pending" />
          )}
        </Box>
      )}
    </Box>
  );
};

export default venueManagement;
