import api from './auth';

const getLocation = (guid: string) => {
    return api.get(`locations/${guid}`)
}

const getEmploymentLocations = () => {
    return api.get('venues/approved');
    // return api.get('locations/employment');
}

const completeLocationConfiguration = (guid: string) => {
    return api.post(`locations/${guid}/complete-configuration`)
}

const activateLocation = (guid: string) => {
    return api.post(`locations/${guid}/activate`);
}

const deactivateLocation = (guid: string) => {
    return api.post(`locations/${guid}/deactivate`);
}

const deleteLocation = (guid: string) => {
    return api.delete(`locations/${guid}`);
}

const pendingLocations = () => {
    return api.get('locations/unapproved/organisation');
}

const submitLocation = (form: any) => {
    return api.post('locations/unapproved', { form });
}

const updateLocation = (guid: string, form: any) => {
    return api.patch(`locations/unapproved/${guid}`, {
        form
    })
}

const approveSubmitedLocation = (guid: string, tfa: number) => {
    return api.post(`locations/unapproved/${guid}/approve`, {
        Headers: {
            'X-Tfa-Code': tfa
        }
    })
}
const locationsAPI = {
    getLocation,
    getEmploymentLocations,
    completeLocationConfiguration,
    activateLocation,
    deactivateLocation,
    deleteLocation,
    pendingLocations,
    submitLocation,
    updateLocation,
    approveSubmitedLocation
};

export default locationsAPI;