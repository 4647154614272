const ACTION_TYPE = "ACTIVE_ACCOUNT";

export const ACCOUNT_ACTIVATED = `${ACTION_TYPE}/ACCOUNT_ACTIVATED`;

export const ACCOUNT_FAILED = `${ACTION_TYPE}/ACCOUNT_FAILED`;

export const SET_LOADING = `${ACTION_TYPE}/SET_LOADING`;

export const SHOW_SNACKBAR = `${ACTION_TYPE}/SHOW_SNACKBAR`;

