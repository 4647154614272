import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

interface LatLng {
  lat: number;
  lng: number;
}

interface LocationMapProps {
  initialLatitude?: number;
  initialLongitude?: number;
  onMapClick: (lat: number, lng: number) => void;
}

const LocationMap: React.FC<LocationMapProps> = ({
  initialLatitude = -3.745,
  initialLongitude = -38.523,
  onMapClick,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string,
  });

  const [markerPosition, setMarkerPosition] = useState<LatLng>({
    lat: initialLatitude,
    lng: initialLongitude,
  });

  useEffect(() => {
    // Ensure that latitude and longitude are numbers
    const parsedLat = Number(initialLatitude);
    const parsedLng = Number(initialLongitude);

    if (!isNaN(parsedLat) && !isNaN(parsedLng)) {
      setMarkerPosition({ lat: parsedLat, lng: parsedLng });
    } else {
      console.error("Invalid initial latitude or longitude values");
    }
  }, [initialLatitude, initialLongitude]);


   const handleMapClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarkerPosition({ lat, lng });
        onMapClick(lat, lng);
      }
    },
    [onMapClick]
  );
 
  const mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
  };

  if (loadError) {
    return <div>Error loading map</div>;
  }
  
  return (
    <div> 
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "400px",
            borderRadius: "16px",
            marginTop: "16px",
            marginBottom: "16px",
          }}
          center={markerPosition}
          zoom={17}
          options={mapOptions}
          onClick={handleMapClick}
        >
          {markerPosition && (
            <Marker
              position={markerPosition}
              animation={google.maps.Animation.DROP}
            />
          )}
        </GoogleMap>
      ) : (
        <div>Loading map...</div>
      )}
    </div>
  );
};

export default LocationMap;
