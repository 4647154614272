import produce from 'immer';

import {
    SET_END_DATE,
    SET_LOCATION_RESERVATIONS, SET_START_DATE,
    ADD_STATUS,
    REMOVE_STATUS
} from './types';
import dayjs from 'dayjs';

interface InitialState {
    location: Array<any> | null;
    startDate: any;
    endDate: any;
    statuses: Array<string>;
}

const currentDate = dayjs();
const lastMonthDate = currentDate.subtract(1, 'month');
const currentDateFormatted = currentDate;
const lastMonthDateFormatted = lastMonthDate;

const initialState: InitialState = <InitialState>({
    location: null,
    startDate: lastMonthDateFormatted.format('YYYY-MM-DD'),
    endDate: currentDateFormatted.format('YYYY-MM-DD'),
    statuses: ['COMPLETED', 'ACTIVE', 'REVOKED', 'CANCELLED']
});

export default function (state = initialState, action: any) {
    const { type, payload } = action;

    return produce(state, (draft) => {
        switch (type) {
            case SET_LOCATION_RESERVATIONS: {
                const edited = payload.map((p: any) => {
                    return {
                        ...p,
                        createdAt: dayjs(p.createdAt).format('YYYY-MM-DD')
                    }
                });
                draft.location = edited;
                break;
            }
            case SET_START_DATE: {
                draft.startDate = payload;
                break;
            }
            case SET_END_DATE: {
                draft.endDate = payload;
                break;
            }
            case ADD_STATUS: {
                draft.statuses?.push(payload);
                break;
            }
            case REMOVE_STATUS: {
                const index = draft.statuses.findIndex(status => status === payload);
                if (index !== -1) draft.statuses.splice(index, 1)
                break;
            }
            default:
                return state;
        }
    })
}