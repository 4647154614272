import {
    ADD_LOCATIONS,
    CHANGE_ACTIVE_LOCATION,
    ADD_LOCATION_INFORMATION
} from "./types";

const addLocations = (payload: any) => ({
    type: ADD_LOCATIONS,
    payload,
});

const changeActiveLocations = (payload: any) => ({
    type: CHANGE_ACTIVE_LOCATION,
    payload,
});

const addLocationInformation = (payload: any) => ({
    type: ADD_LOCATION_INFORMATION,
    payload
})

export const LocationsActions = {
    addLocations,
    setActiveLocation: changeActiveLocations,
    addLocationInformation
}
