import React, { useEffect, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ProfileDropdown from "./ProfileDropdown";
// NotificationIndicator, NotificationsWrapper,
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { SPALink, UserActionsContainer } from "./styles";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";

import { getUserRole } from "../../storage/storage";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { LocationOn, Logout } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  getActiveLocationMetadata,
  getLocationsList,
} from "../../store/locations/selectors";
import { useAppDispatch } from "../../hooks";
import { LocationsActions } from "../../store/locations/actions";
import DashboardSidepanel from "../../views/dashboard/sidepanel/DashboardSidepanel";
import { DashboardSidepanelActions } from "../../views/dashboard/sidepanel/actions";
import { getLocations } from "../../store/locations/async-actions";
// import Breadcrumbs from '../Breadcrumbs';
import LogoWhite from "../../assets/logo-white.svg";
import PersonIcon from "@mui/icons-material/Person";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import {
  PayoutHistoryIcon,
  ReportsIcon,
  StatisticsIcon,
} from "../../assets/icons";
import { useMedia } from "../../utils/useMedia";
import ReportsSidePanel from "../../views/reports/ReportsSidePanel";

const drawerWidth = 240;

import { hasPermission } from "../../store/permissions";
import {Capacitor} from "@capacitor/core";

const SidePanelContentStatistics = () => {
  return <div></div>;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
interface Props {
  children: any;
  window?: () => Window;
}

const DrawerComponent = ({ children, window }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { mobileOnly, tablet } = useMedia();
  const [open, setOpen] = React.useState(true);
  const locations = useSelector(getLocationsList);
  const activeLocation = useSelector(getActiveLocationMetadata);
  const [isUserAgentAndroid, setIsAgentAndroid] = useState(false);

  const userRole = getUserRole();

  useEffect(() => {
    if (hasPermission("FETCH_APPROVED_VENUES")) {
      dispatch(getLocations());
    }
  }, []);

  useEffect(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      setIsAgentAndroid(userAgent.includes("android"));
  }, []);

  const handleNavigationOpen = () => {
    setOpen(true);
  };

  const handleNavigationClose = () => {
    setOpen(false);
  };

  const handleLocationChange = (loc: any) => {
    dispatch(LocationsActions.setActiveLocation(loc));

    if (loc.id !== activeLocation.id) {
      if (location.pathname.includes("create-layout")) {
        navigate(`/create-layout/${loc.id}`);
      }

      dispatch(DashboardSidepanelActions.resetDashboardPanel());
    }
  };

  const beachLocationRoutes = [
    {
      link: "/",
      text: "Локация",
      icon: <BeachAccessIcon sx={{ color: "#5A6977" }} />,
      permission: null,
    },
    {
      link: "/user-management",
      text: "Потребители",
      icon: <PersonIcon sx={{ color: "#5A6977" }} />,
      permission: "FETCH_EMPLOYEES",
    },
    {
      link: "/reports",
      text: "Репорти",
      icon: <ReportsIcon />,
      permission: "FETCH_RESERVATION_REPORTS",
    },
    {
      link: "/statistics",
      text: "Статистики",
      icon: <StatisticsIcon />,
      permission: "FETCH_STATISTICS",
    },
    {
      link: "/payout-history",
      text: "Плащания",
      icon: <PayoutHistoryIcon />,
      permission: "FETCH_PAYOUTS",
    },

    ...(activeLocation
      ? [
          {
            link: `/create-layout/${activeLocation.id}`,
            text: "Изглед на плажа",
            icon: <CalendarViewMonthIcon />,
            permission: "CREATE_VENUE_LAYOUT",
          },
        ]
      : []),
      ...(isUserAgentAndroid ? [{
          link: "/mobile-download",
          text: "Мобилно Приложение",
          icon: <PayoutHistoryIcon/>,
      }] : []),
  ];

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user") as any)
  );

  const handleLogout = () => {
    // dispatch(LoginActions.logoutSuccess());
    localStorage.removeItem("user");
    setUser(JSON.parse(localStorage.getItem("user") as string));
  };

  // todo: remove
  const managementUrl = location.pathname.includes("user-management");
  const createLocation = location.pathname.includes("create-location");
  const approvedVenues = location.pathname.includes("approved-venues");
  const pendingVenues = location.pathname.includes("pending-venues");

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const drawer = (
    <div>
      <DrawerHeader
        sx={{
          background: "#06191E",
          border: "none",
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <img src={LogoWhite} alt="" />
        </Box>
        <IconButton
          onClick={mobileOnly ? handleDrawerToggle : handleNavigationClose}
          sx={{
            color: "#06191E",
            background: "#C5FFFF",
            "&:hover": {
              background: "#044D5E",
              color: "#fff",
            },
          }}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {/* {userRole} */}
        {beachLocationRoutes
          .filter(
            (route) => !route.permission || hasPermission(route.permission)
          )
          .map((route, index) => (
            <Box key={index}>
              <ListItem
                key={index}
                disablePadding
                sx={{
                  display: "block",
                  color: "#044D5E",
                }}
                onClick={handleDrawerToggle}
              >
                <SPALink to={route.link}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={route.text}
                    sx={{ opacity: mobileOnly ? 1 : open ? 1 : 0 }}
                  />
                </SPALink>
              </ListItem>
            </Box>
          ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        open={mobileOpen}
        position="fixed"
        sx={{
          background: "#06191E",
          display: { xs: "flex", sm: "none" },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          {mobileOnly && !mobileOpen && (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <img src={LogoWhite} alt="" />
              </Box>
              <UserActionsContainer>
                {locations && activeLocation && (
                  <FormControl
                    sx={{
                      m: "5px 0 5px 0",
                      minWidth: 120,
                      maxWidth: 250,
                      width: "100%",
                    }}
                    size="small"
                  >
                    <Select
                      value={activeLocation.name}
                      renderValue={(value) => {
                        return (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <LocationOn
                              sx={{
                                fill: "#fff",
                                flex: "0 0 auto",
                              }}
                            />
                            <Box
                              sx={{
                                flex: 1,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {value}
                            </Box>
                          </Box>
                        );
                      }}
                      sx={{
                        background: "transparent",
                        color: "#fff",
                        fontWeight: "500",
                        "& fieldset": {
                          border: "none",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#fff",
                        },
                      }}
                    >
                      {locations.map(
                        (
                          location: { id: string; name: string },
                          index: number
                        ) => (
                          <MenuItem
                            key={index}
                            value={location.name}
                            onClick={() => handleLocationChange(location)}
                          >
                            {location.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                )}
              </UserActionsContainer>
            </>
          )}
        </Toolbar>
      </AppBar>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          display: { xs: "none", sm: "flex" },
        }}
      >
        <Toolbar
          sx={{
            alignItems: "center",
            boxShadow: "none",
            background: "#06191E",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleNavigationOpen}
            edge="start"
            sx={{
              color: "#06191E",
              background: "#C5FFFF",
              marginRight: 5,
              ...(open && { display: "none" }),
              ...(!open && { mr: "16px" }),
              "&:hover": {
                background: "#044D5E",
                color: "#fff",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          {!open && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <img src={LogoWhite} alt="" />
            </Box>
          )}
          <UserActionsContainer>
            {locations && activeLocation && (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="location-select-label"
                  id="location-select"
                  value={activeLocation.name}
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <LocationOn
                          sx={{
                            fill: "#422AFB",
                          }}
                        />
                        {value}
                      </Box>
                    );
                  }}
                  sx={{
                    background: "#F4F7FE",
                    color: "#000",
                    boxShadow: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)",
                    fontWeight: "600",
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                >
                  {locations.map(
                    (location: { id: string; name: string }, index: number) => (
                      <MenuItem
                        key={index}
                        value={location.name}
                        onClick={() => handleLocationChange(location)}
                      >
                        {location.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
            <ProfileDropdown />
          </UserActionsContainer>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ flexShrink: { sm: 0 } }}>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            position: "relative",
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}

          {hasPermission("SUBMIT_VENUE") && (
            <ListItem
              disablePadding
              sx={{
                display: "block",
                color: "#044D5E",
              }}
              onClick={handleDrawerToggle}
            >
              <SPALink to={"/create-location"}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AddIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Добави локация"
                  sx={{ opacity: mobileOnly ? 1 : open ? 1 : 0 }}
                />
              </SPALink>
            </ListItem>
          )}

          {hasPermission("FETCH_APPROVED_VENUES") && (
            <ListItem
              disablePadding
              sx={{
                display: "block",
                color: "#044D5E",
              }}
              onClick={handleDrawerToggle}
            >
              <SPALink to={"/approved-venues"}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <CheckCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Одобрени локации"
                  sx={{ opacity: mobileOnly ? 1 : open ? 1 : 0 }}
                />
              </SPALink>
            </ListItem>
          )}

          {hasPermission("FETCH_PENDING_APPROVAL_VENUES") && (
            <ListItem
              disablePadding
              sx={{
                display: "block",
                color: "#044D5E",
              }}
              onClick={handleDrawerToggle}
            >
              <SPALink to={"/pending-venues"}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Локации чакащи одобрение"
                  sx={{ opacity: mobileOnly ? 1 : open ? 1 : 0 }}
                />
              </SPALink>
            </ListItem>
          )}

          <Box
            sx={{
              position: "absolute",
              bottom: "0",
              left: "0",
              width: "100%",
              background: "#06191E",
              color: "#fff",
            }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout
                  fontSize="small"
                  sx={{
                    fill: "#fff",
                  }}
                />
              </ListItemIcon>
              Изход
            </MenuItem>
          </Box>
        </Drawer>
        <StyledDrawer
          className="styled-drawer"
          variant="permanent"
          open={open}
          sx={{
            display: { xs: "none", sm: "block" },
            border: "none",
            "& .MuiPaper-root": {
              border: "none",
            },
          }}
        >
          {drawer}
        </StyledDrawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          background: tablet ? "#EDEFF2" : "#fff",
          paddingBottom: tablet ? "0" : "60px",
        }}
      >
        <DrawerHeader />
        <Box
          sx={{
            display: "flex",
            width: tablet
              ? open
                ? "calc(100vw - 240px)"
                : "calc(100vw - 65px)"
              : "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              overflowY: tablet ? "scroll" : "auto",
              height: tablet ? "calc(100vh - 64px)" : "auto",
            }}
          >
            {children}
          </Box>
          {/* {tablet && !managementUrl && !createLocation && !approvedVenues && !pendingVenues &&
                        <Box component='nav' sx={{
                            flex: '0 0 242px',
                            background: '#fff',
                            height: 'calc(100vh - 64px)',
                            overflow: 'auto',
                            minWidth: 0,
                            padding: '15px',
                            boxShadow: '0px 3px 8px -3px rgba(0,0,0,0.75)'
                        }}>
                            <Routes>
                                <Route path="/" element={<DashboardSidepanel />} />
                                <Route path="/reports" element={<ReportsSidePanel />} />
                                <Route path="/statistics" element={<SidePanelContentStatistics />} />
                            </Routes>
                        </Box>
                    } */}
        </Box>
      </Box>
    </Box>
  );
};

export default DrawerComponent;
