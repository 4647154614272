import api from './auth';

const login = (email: string, password: string) => {
    return api.post('login/dashboard', {
        email,
        password,
    })
}

const loginAPI = {
    login
}
export default loginAPI;