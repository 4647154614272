import api from './auth';

const getReservationsReport = (locationId: string, limit = 25, afterDate: string, beforeDate: string, statuses: any) => {
    return api.get(`reservations/location/${locationId}?statuses=${statuses}&startingAfter=${afterDate}&startingBefore=${beforeDate}&limit=${limit}`)
}

const getReservationsBySpotReport = (locationId: string, spotId: string, limit = 25, afterDate: string, beforeDate: string, statuses: any) => {
    return api.get(`reservations/location/${locationId}?spotId=${spotId}&statuses=${statuses}&startingAfter=${afterDate}&startingBefore=${beforeDate}&limit=${limit}`)
}

const reportsAPI = {
    getReservationsReport,
    getReservationsBySpotReport
}

export default reportsAPI;