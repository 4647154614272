import { createSelector } from "@reduxjs/toolkit";

const getEmployees = (state: any) => state.employees;

export const getLoading = createSelector(
  getEmployees,
  (employees) => employees.loading
);
export const getSnackbar = createSelector(
  getEmployees,
  (employees) => employees.snackbar
);
export const getEmployeesList = createSelector(
  getEmployees,
  (employees) => employees.list
);

export const getEmployeesRoles = createSelector(getEmployees, (employees) => {
  return employees.roles.filter((item: any) => item.name !== "OWNER");
});

export const getEmployeeActive = createSelector(
  getEmployees,
  (employees) => employees.employeeActive
);
