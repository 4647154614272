import { Navigate, Outlet } from "react-router-dom"

type Props = {
    allowedRoles: Array<string>;
    redirectPath?: string;
    children?: JSX.Element;
}

const tokenValidation = (user: any) => {
    if (user) {
        const parsed = JSON.parse(atob(user.token.split(".")[1]));
        return parsed.exp * 1000 > Date.now();
    }
    return false;
};

const PrivateRoutes = ({
    allowedRoles,
    redirectPath = '/login',
    children
}: Props) => {
    const user: any = JSON.parse(localStorage.getItem('user') as any);

    const isTokenValid = tokenValidation(user);
    const hasPermissions = user && allowedRoles.includes(user?.role);

    if (!isTokenValid) {
        localStorage.removeItem("user");
        return <Navigate to={redirectPath} replace />;
    }

    // if (!hasPermissions) {
    //     return <Navigate to={redirectPath} replace />;
    // }

    return children ? children : <Outlet />
};

export default PrivateRoutes;