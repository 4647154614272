import * as iconv from 'iconv-lite';

export class DeviceCommuncationService {

    private transSeq: number;

    constructor() {
        this.transSeq = EnumConstants.MIN_TRANS_SEQ;
        let date = new Date();
        let h = date.getHours();
        let sec = date.getSeconds();
        this.transSeq += (h + sec);
    }

    private setStartPacket(lpBuf: string[], CmdID: number, nLen: number): { nLen: number; lpBuf: string[] } {
        if (lpBuf.length === 0)
            return {lpBuf, nLen};

        lpBuf[EnumConstants.BLOCK_STX_NUM] = String.fromCharCode(EnumConstants.TRANS_START);
        lpBuf[EnumConstants.BLOCK_LEN_NUM] = String.fromCharCode(0x20 + nLen);

        if (this.transSeq >= 255) {
            this.transSeq = EnumConstants.MIN_TRANS_SEQ;
        } else {
            this.transSeq++;
        }
        lpBuf[EnumConstants.BLOCK_SEQ_NUM] = String.fromCharCode(this.transSeq);
        lpBuf[EnumConstants.BLOCK_CMD_ID_NUM] = String.fromCharCode(CmdID);
        // console.log(`startpacket after build`, lpBuf, nLen);
        return {lpBuf, nLen};
    }

    private MakeEndPacket(lpBuf: string[], nLen: number): { nLen: number; lpBuf: string[] } {
        // console.log(`maketransend `, lpBuf, nLen);
        if (lpBuf.length === 0 || nLen < 0)
            return {lpBuf, nLen};

        // Setting END of the packet
        lpBuf[nLen++] = String.fromCharCode(EnumConstants.HOST_DATA_END);
        let BCC = this.CheckSumIndexStart(lpBuf, nLen);
        const putBCC = this.GenerateCheckSum(BCC, lpBuf, nLen);
        // BCC = putBCC.bcc;
        lpBuf = putBCC.buff;
        nLen = putBCC.len;
        nLen += EnumConstants.BLOCK_BCC_SIZE;
        lpBuf[nLen] = String.fromCharCode(EnumConstants.TRANS_END);

        // console.log(`endpacket after build`, lpBuf, nLen);
        return {lpBuf, nLen};
    }

    private CheckSumIndexStart(lpStr: string[], iLen: number): number {
        if (lpStr.length <= 1)
            return 0;

        let iWrk;
        let nCount = 0;
        for (iWrk = 1; iWrk < iLen; iWrk++) {
            // console.log(`iWrk`, iWrk);
            nCount += lpStr[iWrk].charCodeAt(0);
        }

        return nCount;
    }

    private GenerateCheckSum(bcc: number, buff: string[], len: number): { bcc: number; len: number; buff: string[] } {
        // console.log(`putbcc`, bcc, buff, len);
        if (buff.length === 0)
            return {bcc, buff, len};

        for (let i = EnumConstants.BLOCK_BCC_SIZE - 1; i >= 0; i--) {
            buff[i + len] = String.fromCharCode((bcc & 0x0F) + 0x30);
            bcc = bcc >> 4;
        }
        return {bcc, buff, len};
    }

    getPacket(data: string, code: number): Uint8Array {
        let resultPacketArray: string[] = new Array(1024);
        let wIndex = 0x04;    // start address of data bytes

        // let bytes = new TextEncoder().encode(data);
        let bytes = iconv.encode(data, 'cp1251');
        let lengthBytes = bytes.length;
        // transform data to hex data
        for (let i = 0; i < lengthBytes; i++) {
            if (bytes[i] < 0) {
                resultPacketArray[wIndex] = String.fromCharCode(bytes[i] + 256);
            } else {
                resultPacketArray[wIndex] = String.fromCharCode(bytes[i]);
            }
            wIndex++;
        }
        // console.log(`before maketrans`, resultPacketArray, code, wIndex);

        const {lpBuf, nLen} = this.setStartPacket(resultPacketArray, code, wIndex);
        resultPacketArray = lpBuf;
        wIndex = nLen;

        const endResult = this.MakeEndPacket(resultPacketArray, wIndex);

        resultPacketArray = endResult.lpBuf;
        wIndex = endResult.nLen;

        // console.log(`full message `, resultPacketArray, wIndex);

        let result = new Uint8Array(wIndex + 1);
        let lengthResult = result.length;
        for (let i = 0; i < lengthResult; i++) {
            result[i] = resultPacketArray[i].charCodeAt(0);
        }

        return result;
    }
}

export enum EnumConstants {
    BLOCK_STX_NUM = 0x00,
    TRANS_START = 0x01,
    BLOCK_LEN_NUM = 0x01,
    BLOCK_SEQ_NUM = 0x02,
    BLOCK_CMD_ID_NUM = 0x03,
    HOST_DATA_END = 0x05,
    BLOCK_BCC_SIZE = 0x04,
    TRANS_END = 0x03,
    MIN_TRANS_SEQ = 0x20,
}
