import React from "react";
import { Grid } from "@mui/material";
export interface IconGalleryImage {
  imageUrl: string;
  columnsInMd?: number,
  beachObjType: string;
}

interface IconGalleryProps {
  draggable: any,
  onDragStart: any,
  images: IconGalleryImage[];
  columnsInMd?: number,
  onImageSelected: (image: IconGalleryImage) => void;
  height?: number,
  width?: number,
  type:string
}

const IconGalleryComponent: React.FC<IconGalleryProps> = ({
  draggable,
  onDragStart,
  images,
  columnsInMd = 4,
  onImageSelected,
  height = 96,
  width = 96,
  type
}) => {
  return (
    <div>
      <Grid container spacing={2}>
        {images.map((image) => (
          <Grid item key={image.imageUrl} xs={3} sm={3} md={columnsInMd}>
            <img
              src={image.imageUrl}
              alt={image.beachObjType}
              className="builder-side-item"
              draggable={draggable}
              onDragStart={(e) => onDragStart(e, image, width, height , type)}
              onDoubleClick={() => onImageSelected(image)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default IconGalleryComponent;
