import produce from "immer";

import {
  REMOVE_SELECTED_SPOT,
  SET_ACTIVE_DATE,
  SET_SELECTED_DATE,
  TOGGLE_SELECTED_SPOT,
  TOGGLE_INITILIZE_SPOT,
  TOGGLE_RESERVED_SPOT,
  RESET_PANEL,
  INITILIZE_LOADING,
  RESERVATION_LOADING,
  RESET_SELECTED_SPOTS,
  COMPLETE_RESERVATION_LOADING,
  CANCEL_RESERVATION_LOADING,
  SPOT_SETTING_LOADING,
  SPOT_SETTING_DATA,
  SPOT_ASSOCIATE_LOADING
} from "./types";
import dayjs from "dayjs";

interface InitialState {
  activeDate: any;
  selectedSpots: Array<any>;
  selectedInitilizeSpot: any;
  selectedReservedSpot: any;
  selectedDate: any;
  initilizeLoading: boolean;
  reservationLoading: boolean;
  completeReservationLoading: boolean;
  cancelReservationLoading: boolean;
  spotSettingsLoading: boolean;
  spotSettingsData: any;
  spotAssociateLoading: boolean
}

const initialState: InitialState = <InitialState>{
  activeDate: null,
  selectedSpots: [],
  selectedInitilizeSpot: null,
  selectedReservedSpot: null,
  selectedDate: dayjs().format("YYYY-MM-DD"),
  initilizeLoading: false,
  reservationLoading: false,
  completeReservationLoading: false,
  cancelReservationLoading: false,
  spotSettingsLoading: false,
  spotSettingsData: null,
  spotAssociateLoading: false
};

export default function (state = initialState, action: any) {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case SET_ACTIVE_DATE: {
        draft.activeDate = payload;
        break;
      }
      case TOGGLE_SELECTED_SPOT: {
        // const isIncluded = draft.selectedSpots && draft.selectedSpots.find((spot: any) => spot.id === payload.id);
        // if (isIncluded) {
        //     draft.selectedSpots = draft.selectedSpots.filter((item: any) => item.id !== payload.id);
        // } else {
        //     draft.selectedSpots?.push(payload);
        // }
        if (payload) {
          draft.selectedSpots = [payload];
        } else {
          draft.selectedSpots = [];
        }
        break;
      }
      case TOGGLE_INITILIZE_SPOT: {
        draft.selectedInitilizeSpot = payload;
        break;
      }
      case TOGGLE_RESERVED_SPOT: {
        draft.selectedReservedSpot = payload;
        break;
      }
      case REMOVE_SELECTED_SPOT: {
        draft.selectedSpots = draft.selectedSpots.filter(
          (item: any) => item.id !== payload
        );
        break;
      }
      case SET_SELECTED_DATE: {
        draft.selectedDate = payload;
        break;
      }
      case RESET_PANEL: {
        return initialState;
      }
      case RESERVATION_LOADING: {
        draft.reservationLoading = payload;
        break;
      }
      case COMPLETE_RESERVATION_LOADING: {
        draft.completeReservationLoading = payload;
        break;
      }
      case CANCEL_RESERVATION_LOADING: {
        draft.cancelReservationLoading = payload;
        break;
      }
      case INITILIZE_LOADING: {
        draft.initilizeLoading = payload;
        break;
      }
      case RESET_SELECTED_SPOTS: {
        draft.selectedSpots = [];
        draft.selectedInitilizeSpot = null;
        draft.selectedReservedSpot = null;
        break;
      }
      case SPOT_SETTING_LOADING: {
        draft.spotSettingsLoading = payload;
        break;
      }
      case SPOT_SETTING_DATA: {
        draft.spotSettingsData = payload;
        break;
      }
      case SPOT_ASSOCIATE_LOADING: {
        draft.spotAssociateLoading = payload;
        break;
      }  
      default:
        return state;
    }
  });
}
