import produce from "immer";
import {
  ACCOUNT_ACTIVATED,
  ACCOUNT_FAILED,
  SET_LOADING,
  SHOW_SNACKBAR,
} from "./types";

interface InitialState {
  activated: boolean;
  activateFailed: boolean;
  loading: boolean;
  snackbar: Object;
}

const initialState: InitialState = <InitialState>{
  activated: false,
  activateFailed: false,
  loading: false,
  snackbar: {
    show: false,
    isError: false,
    message: "",
  },
};

export default function (state = initialState, action: any) {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case ACCOUNT_ACTIVATED:
        draft.activated = payload;
        break;
      case ACCOUNT_FAILED:
        draft.activateFailed = payload;
        break;
      case SET_LOADING:
        draft.loading = payload;
        break;
      case SHOW_SNACKBAR:
        draft.snackbar = payload;
        break;
      default:
        return state;
    }
  });
}
