export enum AppendDirection {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export interface IconGalleryImage {
  imageUrl: string;
  beachObjType: string;
}

export interface AddBeachObjModalData {
  width: number;
  height: number;
  count: number;
  perRow: number;
  direction: AppendDirection;
  image: IconGalleryImage;
}
