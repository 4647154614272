import produce from "immer";
import { SET_LOADING, SHOW_SNACKBAR } from "./types";

interface InitialState {
  loading: boolean;
  snackbar: Object;
}

const initialState: InitialState = <InitialState>{
  loading: false,
  snackbar: {
    show: false,
    isError: false,
    message: "",
  },
};

export default function (state = initialState, action: any) {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case SET_LOADING:
        draft.loading = payload;
        break;
      case SHOW_SNACKBAR:
        draft.snackbar = payload;
        break;
      default:
        return state;
    }
  });
}
