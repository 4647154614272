import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useAppDispatch } from "../../../hooks";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getActiveLocationMetadata } from "../../../store/locations/selectors";

import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getPlates } from "./selector";
import {
  getSelectedDate,
  getSpotSettingsLoading,
  getSpotSettingData,
  getspotAssociateLoading,
} from "../sidepanel/selectors";

import {
  settingsSpot,
  associateSpot,
  disassociateSpot,
} from "../beach-location/async-actions";

import { useMedia } from "../../../utils/useMedia";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: document.body.offsetWidth ? 526 : "90%",
  maxHeight: document.body.offsetWidth ? "100%" : "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "16px 24px",
  borderRadius: "6px",
};

const AssignQRModal = ({
  selectedInitilizeSpot,
  handleAssign,
  handleClose,
}: any) => {
  const { tablet } = useMedia();

  const dispatch = useAppDispatch();

  const plates = useSelector(getPlates);

  const [selectedPlate, setSelectedPlate] = useState({});

  const activeLocation = useSelector(getActiveLocationMetadata);

  const selectedDate = useSelector(getSelectedDate);

  const spotSettingLoading = useSelector(getSpotSettingsLoading);
  const spotAssociateLoading = useSelector(getspotAssociateLoading);

  const settingData = useSelector(getSpotSettingData);

  useEffect(() => {
    if (selectedInitilizeSpot) {
      dispatch(settingsSpot(selectedInitilizeSpot.id));
    }
  }, []);

  const handlePlate = (plate: any) => {
    setSelectedPlate(plate);
  };

  useEffect(() => {
    if (plates.length > 1) {
      setSelectedPlate(plates[0]);
    }
  }, [plates]);

  const assciatePlate = () => {
    const selectedSpotDate = selectedDate;

    var data = {
      spotId: selectedInitilizeSpot?.id,
      venueId: activeLocation?.id,
    };

    dispatch(associateSpot(selectedPlate?.id, data, selectedSpotDate));

    handleAssign();
  };

  const disAccociatePlate = () => {
    const selectedSpotDate = selectedDate;

    dispatch(
      disassociateSpot(selectedPlate?.id, activeLocation?.id, selectedSpotDate)
    );

    handleAssign();
  };

  if (!selectedInitilizeSpot) return null;

  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>{"Associate Spot"}</DialogTitle>
        <DialogContent>
          {spotSettingLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "16px 0",
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {/* Disaccociate */}
          {!spotSettingLoading && settingData && settingData.plate && (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  padding: "16px 0",
                }}
              >
                <Typography>
                  This spot is already associate to this plate
                </Typography>
                <Typography
                  component="h2"
                  variant="h6"
                  sx={{ fontWeight: 600 }}
                >
                  {settingData.plate.serialNumber}
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: 1,
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Button variant="text" onClick={handleClose}>
                  Отказ
                </Button>

                <Box sx={{ position: "relative" }}>
                  <Button
                    variant="contained"
                    sx={{
                      background: "#ee5a5a",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                    onClick={() => disAccociatePlate()}
                    disabled={spotAssociateLoading}
                  >
                    Disassociate
                  </Button>

                  {spotAssociateLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-16px",
                        marginLeft: "-16px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </>
          )}

          {!spotSettingLoading && settingData && !settingData.plate && (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  padding: "16px 0",
                }}
              >
                <Typography>
                  This spot is not accociate to any plate. please select a plate
                  below.
                </Typography>
              </Box>

              <FormControl
                sx={{
                  minWidth: 120,
                  width: "100%",
                  mt: 1,
                  textAlign: "start",
                }}
              >
                <InputLabel>Изберете табела</InputLabel>
                <Select value={selectedPlate.id} label="Age">
                  {plates
                    .filter((plate: any) => !plate.linked)
                    .map((plate: any, index: number) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => handlePlate(plate)}
                          value={plate.id}
                        >
                          {plate.serialNumber}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <Box
                sx={{
                  marginTop: 1,
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Button variant="text" onClick={handleClose}>
                  Отказ
                </Button>

                <Box sx={{ position: "relative" }}>
                  <Button
                    variant="contained"
                    sx={{
                      background: "#044D5E",
                      color: "#C5FFFF",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                    onClick={() => assciatePlate()}
                    disabled={spotAssociateLoading}
                  >
                    Associate
                  </Button>

                  {spotAssociateLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-16px",
                        marginLeft: "-16px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AssignQRModal;
