const ACTION_TYPE = "DASHBOARD/SIDEPANEL";

export const SET_ACTIVE_DATE = `${ACTION_TYPE}/SET_ACTIVE_DATE`;
export const TOGGLE_SELECTED_SPOT = `${ACTION_TYPE}/TOGGLE_SELECTED_SPOT`;
export const TOGGLE_INITILIZE_SPOT = `${ACTION_TYPE}/TOGGLE_INITILIZE_SPOT`;
export const TOGGLE_RESERVED_SPOT = `${ACTION_TYPE}/TOGGLE_RESERVED_SPOT`;
export const REMOVE_SELECTED_SPOT = `${ACTION_TYPE}/REMOVE_SELECTED_SPOT`;
export const RESET_SELECTED_SPOTS = `${ACTION_TYPE}/RESET_SELECTED_SPOTS`;
export const SET_SELECTED_DATE = `${ACTION_TYPE}/SET_SELECTED_DATE`;
export const RESET_PANEL = `${ACTION_TYPE}/RESET_PANEL`;
export const INITILIZE_LOADING = `${ACTION_TYPE}/INITILIZE_LOADING`;
export const RESERVATION_LOADING = `${ACTION_TYPE}/RESERVATION_LOADING`;
export const COMPLETE_RESERVATION_LOADING = `${ACTION_TYPE}/COMPLETE_RESERVATION_LOADING`;
export const CANCEL_RESERVATION_LOADING = `${ACTION_TYPE}/CANCEL_RESERVATION_LOADING`;
export const SPOT_SETTING_LOADING = `${ACTION_TYPE}/SPOT_SETTING_LOADING`;
export const SPOT_SETTING_DATA = `${ACTION_TYPE}/SPOT_SETTING_DATA`;
export const SPOT_ASSOCIATE_LOADING = `${ACTION_TYPE}/SPOT_ASSOCIATE_LOADING`;