import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import iconFree from '../../assets/icon-free.svg';
import { useMedia } from "../../utils/useMedia";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch } from "../../hooks";
import { activateEmoployee } from "./async-actions";
import { getEmployeeActive } from "../user-management/selectors";

const IconFree = styled.img.attrs((props: any) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
`;

const EmployeeActivation = () => {
    const dispatch = useAppDispatch();
    const { tablet } = useMedia();
    const { activationToken } = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const handlePassword = (event: any) => {
        setPassword(event.target.value);
    }

    const handleRepeatPassword = (event: any) => {
        setRepeatPassword(event.target.value);
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseDownRepeatPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if ((password === repeatPassword) && (password !== '' && repeatPassword !== '') && activationToken) {
            setIsPasswordValid(true);
        } else {
            setIsPasswordValid(false);
        }
    }, [password, repeatPassword]);

    const handleActivate = () => {
        dispatch(activateEmoployee(activationToken ? activationToken : '', password));
    }

    const isEmployeeActive = useSelector(getEmployeeActive);

    return (
        <Box sx={{
            p: 3,
            height: '100%'
        }}>
            <Box sx={{
                background: '#fff',
                borderRadius: 2,
                p: 2,
                position: 'relative',
                height: '100%'
            }}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '500px',
                    height: '300px',
                    maxWidth: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    borderRadius: '16px',
                    padding: '20px 16px'
                }}>

                    {isEmployeeActive ? (
                        <>
                            <Box>
                                <IconFree src={iconFree} />
                            </Box>
                            <Box>
                                <Typography sx={{
                                    fontWeight: 400,
                                    fontSize: '34px',
                                    lineHeight: '48px',
                                    letterSpacing: '.4px',
                                    textAlign: 'center'
                                }}>
                                    Успешна активация!
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <>
                            {(!activationToken || !activationToken.length) &&
                                <Box sx={{
                                    background: '#06191E',
                                    width: '100%',
                                    borderRadius: 2,
                                    color: '#fff',
                                    p: 1
                                }}>
                                    Невалиден активационен код!
                                </Box>
                            }
                            <FormControl
                                sx={{
                                    mt: 2,
                                    width: tablet ? '400px' : '320px',
                                }}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="password">Парола</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Парола"
                                    onChange={handlePassword}
                                />
                            </FormControl>
                            <FormControl
                                sx={{
                                    mt: 2,
                                    width: tablet ? '400px' : '320px',
                                }}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="repeatPassword">Повторете паролата</InputLabel>
                                <OutlinedInput
                                    id="repeatPassword"
                                    type={showRepeatPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowRepeatPassword}
                                                onMouseDown={handleMouseDownRepeatPassword}
                                                edge="end"
                                            >
                                                {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Повторете паролата"
                                    onChange={handleRepeatPassword}
                                />
                            </FormControl>
                        </>
                    )}

                    {!isEmployeeActive &&
                        <Button
                            sx={{
                                mt: 1
                            }}
                            variant="contained"
                            disabled={!isPasswordValid}
                            onClick={handleActivate}
                        >
                            Активирай
                        </Button>
                    }
                </Box>
            </Box>
        </Box>
    )

}

export default EmployeeActivation;