import React, { useState, useEffect, useRef } from 'react';
import './FloatingDataDump.css';

const FloatingConsole = () => {
    const [logs, setLogs] = useState([]);
    const logRef = useRef(null);

    useEffect(() => {
        const handleLog = (message) => {
            setLogs((prevLogs) => [...prevLogs, message]);
            logRef.current.scrollTop = logRef.current.scrollHeight;
        };

        const originalLog = console.log;
        console.log = (...args) => {
            originalLog(...args);
            handleLog({ type: 'log', message: args.join(' ') });
        };

        const originalDebug = console.debug;
        console.log = (...args) => {
            originalLog(...args);
            handleLog({ type: 'debug', message: args.join(' ') });
        };

        const originalError = console.error;
        console.error = (...args) => {
            originalError(...args);
            handleLog({ type: 'error', message: args.join(' ') });
        };

        // Add other console methods if needed
        // console.warn, console.info, etc.

        return () => {
            console.log = originalLog;
            console.error = originalError;
            console.debug = originalDebug;
        };
    }, []);

    return (
        <div className="floating-console" ref={logRef}>
            {logs.map((log, index) => (
                <div key={index} className={`log-item ${log.type}`}>
                    {log.message}
                </div>
            ))}
        </div>
    );
};

export default FloatingConsole;
