import { useMediaQuery } from '@mui/material';

export const useMedia = () => {
    const mobileOnly = useMediaQuery('(max-width: 600px)', { noSsr: true });
    const tablet = useMediaQuery('(min-width: 601px)', { noSsr: true });
    const desktop = useMediaQuery('(min-width: 961px)', { noSsr: true });

    return {
        mobileOnly,
        tablet,
        desktop
    };
};