import { useState } from "react";

type CommonIconProps = {
  isInitialized: boolean;
  isReserved: boolean;
  isSelected: boolean;
  isFiscalIssued: boolean;
  enableInactiveHover: boolean;
  enableInitializedHover: boolean;
  enableReservedHover: boolean;
};

export const TentIcon = ({
  isInitialized,
  isReserved,
  isSelected,
  isFiscalIssued,
  enableInactiveHover,
  enableInitializedHover,
  enableReservedHover,
}: CommonIconProps) => {
  const [isHovered, setIsHovered] = useState(false);

  let state = "Inactive";

  if (isInitialized && !isSelected) {
    state = "Default";
  } else if (isReserved && !isSelected && !isFiscalIssued) {
    state = "Reserved";
  } else if (isSelected && !isFiscalIssued) {
    state = "Selected";
  } else if (!isSelected && isFiscalIssued) {
    state = "Fiscal";
  } else if (isReserved && isSelected && isFiscalIssued) {
    state = "Fiscal_Select";
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const src =
    isHovered &&
    ((enableInactiveHover && state === "Inactive") ||
      (enableInitializedHover && state === "Default" && isInitialized) ||
      (enableReservedHover && state === "Reserved" && isReserved) ||
      isFiscalIssued)
      ? `/assets/layout-builder/objects/Type=Tent, State=${
          isFiscalIssued ? "Fiscal_Hover" : "Hover"
        }.svg`
      : `/assets/layout-builder/objects/Type=Tent, State=${state}.svg`;

  return (
    <img
      src={src}
      alt={`Tent ${state}`}
      width={"100%"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export const SunbedIcon = ({
  isInitialized,
  isReserved,
  isSelected,
  isFiscalIssued,
  enableInactiveHover,
  enableInitializedHover,
  enableReservedHover,
}: CommonIconProps) => {
  const [isHovered, setIsHovered] = useState(false);

  let hoverAllCases = true;

  let state = "Inactive";

  if (isInitialized && !isSelected) {
    state = "Default";
  } else if (isReserved && !isSelected && !isFiscalIssued) {
    state = "Reserved";
  } else if (isSelected && !isFiscalIssued) {
    state = "Selected";
  } else if (!isSelected && isFiscalIssued) {
    state = "Fiscal";
  } else if (isReserved && isSelected && isFiscalIssued) {
    state = "Fiscal_Select";
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const src =
    isHovered &&
    ((enableInactiveHover && state === "Inactive") ||
      (enableInitializedHover && state === "Default" && isInitialized) ||
      (enableReservedHover && state === "Reserved" && isReserved) ||
      isFiscalIssued)
      ? `/assets/layout-builder/objects/Type=Sunbed, State=${
          isFiscalIssued ? "Fiscal_Hover" : "Hover"
        }.svg`
      : `/assets/layout-builder/objects/Type=Sunbed, State=${state}.svg`;

  return (
    <img
      src={src}
      alt={`Sunbed ${state}`}
      width={"100%"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export const UmbrellaSunbedIcon = ({
  isInitialized,
  isReserved,
  isSelected,
  isFiscalIssued,
  enableInactiveHover,
  enableInitializedHover,
  enableReservedHover,
}: CommonIconProps) => {
  const [isHovered, setIsHovered] = useState(false);

  let hoverAllCases = true;

  let state = "Inactive";

  if (isInitialized && !isSelected) {
    state = "Default";
  } else if (isReserved && !isSelected && !isFiscalIssued) {
    state = "Reserved";
  } else if (isSelected && !isFiscalIssued) {
    state = "Selected";
  } else if (!isSelected && isFiscalIssued) {
    state = "Fiscal";
  } else if (isReserved && isSelected && isFiscalIssued) {
    state = "Fiscal_Select";
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const src =
    isHovered &&
    ((enableInactiveHover && state === "Inactive") ||
      (enableInitializedHover && state === "Default" && isInitialized) ||
      (enableReservedHover && state === "Reserved" && isReserved) ||
      isFiscalIssued)
      ? `/assets/layout-builder/objects/Type=Umbrella_Sunbed, State=${
          isFiscalIssued ? "Fiscal_Hover" : "Hover"
        }.svg`
      : `/assets/layout-builder/objects/Type=Umbrella_Sunbed, State=${state}.svg`;

  return (
    <img
      src={src}
      alt={`Umbrella Sunbed ${state}`}
      width={"100%"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export const UmbrellaIcon = ({
  isInitialized,
  isReserved,
  isSelected,
  isFiscalIssued,
  enableInactiveHover,
  enableInitializedHover,
  enableReservedHover,
}: CommonIconProps) => {
  const [isHovered, setIsHovered] = useState(false);

  let hoverAllCases = true;

  let state = "Inactive";

  if (isInitialized && !isSelected) {
    state = "Default";
  } else if (isReserved && !isSelected && !isFiscalIssued) {
    state = "Reserved";
  } else if (isSelected && !isFiscalIssued) {
    state = "Selected";
  } else if (!isSelected && isFiscalIssued) {
    state = "Fiscal";
  } else if (isReserved && isSelected && isFiscalIssued) {
    state = "Fiscal_Select";
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const src =
    isHovered &&
    ((enableInactiveHover && state === "Inactive") ||
      (enableInitializedHover && state === "Default" && isInitialized) ||
      (enableReservedHover && state === "Reserved" && isReserved) ||
      isFiscalIssued)
      ? `/assets/layout-builder/objects/Type=Umbrella, State=${
          isFiscalIssued ? "Fiscal_Hover" : "Hover"
        }.svg`
      : `/assets/layout-builder/objects/Type=Umbrella, State=${state}.svg`;

  return (
    <img
      src={src}
      alt={`Umbrella ${state}`}
      width={"100%"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export const PathHorizontaIcon = () => {
  return (
    <img
      src="/assets/layout-builder/objects/Type=HorizontalPath, State=Default.svg"
      width={"100%"}
      height={"100%"}
      alt={`PathHorizontal`}
    />
  );
};

export const PathVerticalIcon = () => {
  return (
    <img
      src="/assets/layout-builder/objects/Type=VerticalPath, State=Default.svg"
      width={"100%"}
      height={"100%"}
      alt={`PathVertical`}
    />
  );
};

export const TreeIcon = () => {
  return (
    <img
      src="/assets/layout-builder/objects/Palm.svg"
      width={"100%"}
      alt={`Tree`}
    />
  );
};

export const LifeguardIcon = () => {
  return (
    <img
      src="/assets/layout-builder/zones/Lifeguard.svg"
      width={"100%"}
      alt={`Lifeguard`}
    />
  );
};

export const BarIcon = () => {
  return (
    <img
      src="/assets/layout-builder/zones/Bar.svg"
      width={"100%"}
      alt={`Bar`}
    />
  );
};

export const MedicalIcon = () => {
  return (
    <img
      src="/assets/layout-builder/zones/Medical.svg"
      width={"100%"}
      alt={`Medical`}
    />
  );
};

export const PoolIcon = () => {
  return (
    <img
      src="/assets/layout-builder/zones/Pool.svg"
      width={"100%"}
      alt={`Pool`}
    />
  );
};

export const VolleyballIcon = () => {
  return (
    <img
      src="/assets/layout-builder/zones/Playfield.svg"
      width={"100%"}
      alt={`Volleyball`}
    />
  );
};

export const PlaygroundIcon = () => {
  return (
    <img
      src="/assets/layout-builder/zones/Playground.svg"
      width={"100%"}
      alt={`Playground`}
    />
  );
};

export const RestaurantIcon = () => {
  return (
    <img
      src="/assets/layout-builder/zones/Restaurant.svg"
      width={"100%"}
      alt={`Restaurant`}
    />
  );
};
