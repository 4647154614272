import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Autocomplete, Box, Button, Chip, Modal, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { getSpotAvailability, getSpots } from "./selector";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import iconFree from '../../../assets/icon-free.svg';
import iconReserved from '../../../assets/icon-reserved.svg';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 526,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '16px 24px',
    borderRadius: '6px'
};

const IconFree = styled.img.attrs((props: any) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
`;

const IconReserved = styled.img.attrs((props: any) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
`;

const CheckReservationModal = ({
    selectedSpot,
    isOpen,
    handleCheckReservation,
    handleClose,
    handleAvailabilityReset
}: any) => {
    const [availableSpot, setAvailableSpot] = useState(null) as any;
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());
    const isSpotAvailable = useSelector(getSpotAvailability);
    const spots = useSelector(getSpots);

    useEffect(() => {
        setAvailableSpot(selectedSpot);
        handleAvailabilityReset();
    }, [selectedSpot]);

    const handleFromDate = (event: any) => {
        setFromDate(event);
    }
    const handleToDate = (event: any) => {
        setToDate(event);
    }

    const handleAvailableSpotChange = (spot: any) => {
        setAvailableSpot(spot);
        handleAvailabilityReset();
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography variant="h6" component="h2">
                    Провери резервация
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px 0',
                    gap: '16px'
                }}>
                    <Autocomplete
                        value={availableSpot ? availableSpot?.label : ''}
                        disablePortal
                        options={spots}
                        sx={{ width: 300 }}
                        renderTags={(spot: any) => {
                            return <Chip
                                variant="outlined"
                                label={spot ? spot?.label : ''}
                                size="small"
                                sx={{
                                    color: '#fff',
                                    background: '#06191E',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                    letterSpacing: '.15px',
                                    '& svg': {
                                        fill: '#fff',
                                    }
                                }}
                            />
                        }}
                        renderInput={(params) => <TextField {...params} label="Изберете място" />}
                        onChange={(event: any, newValue: string | null) => {
                            handleAvailableSpotChange(newValue);
                        }}
                    />
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                    }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="From" value={fromDate} onChange={handleFromDate} />
                            </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="To" value={toDate} onChange={handleToDate} />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Box>
                </Box>
                {isSpotAvailable !== null &&
                    <Box sx={{
                        background: '#EDEFF2',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        borderRadius: '16px',
                        padding: '20px 16px'
                    }}>
                        <Box>
                            {isSpotAvailable ? <IconFree src={iconFree} /> : <IconReserved src={iconReserved} />}
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Typography sx={{
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '32px',
                                textTransform: 'uppercase'
                            }}>За избраните дати, мястото е</Typography>
                            <Typography sx={{
                                fontWeight: 400,
                                fontSize: '34px',
                                lineHeight: '48px',
                                letterSpacing: '.4px'
                            }}>
                                {isSpotAvailable ? 'Свободно' : 'Резервирано'}
                            </Typography>
                            {/* {isSpotAvailable &&
                                <Button sx={{
                                    background: '#044D5E',
                                    color: '#fff',
                                    mt: 2
                                }}>Make reservation</Button>
                            } */}
                        </Box>
                    </Box>
                }
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    gap: 1,
                    mt: 2
                }}>
                    <Button onClick={handleClose}>Отказ</Button>
                    <Button
                        disabled={!availableSpot}
                        sx={{
                            background: '#044D5E',
                            color: '#C5FFFF',
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '24px',
                            '&:disabled': {
                                color: '#fff',
                                opacity: '.5'
                            }
                        }}
                        onClick={() => handleCheckReservation(dayjs(fromDate).format('YYYY-MM-DD'), dayjs(toDate).format('YYYY-MM-DD'), availableSpot?.id, availableSpot?.plateTag)}>
                        Провери
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default CheckReservationModal;