import { Box, TextField } from "@mui/material";
import Table from "../../components/table/Table";
import ComingSoon from "../../components/ComingSoon";
import defaultConfig from "../../components/table/default-config";
import { useEffect } from "react";
import { hasPermission } from "../../store/permissions";
import { useNavigate } from "react-router-dom";

const PayoutHistory = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasPermission("FETCH_PAYOUTS")) {
      navigate("/");
    }
  }, []);

  const handleSearchTermChange = () => {};
  return (
    <Box sx={{ pt: 3, pr: 3, pl: 3, position: "relative" }}>
      <ComingSoon />
      <Box>
        <TextField
          label="Search"
          id="search-field"
          placeholder="Search by invoice"
          size="small"
          sx={{
            background: "#F4F7FE",
            color: "#000",
            boxShadow: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)",
            width: "100%",
            maxWidth: "280px",
            fontWeight: "600",
            "& fieldset": {
              border: "none",
            },
          }}
          onChange={handleSearchTermChange}
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Table columns={defaultConfig} data={defaultConfig.rows} />
      </Box>
    </Box>
  );
};

export default PayoutHistory;
