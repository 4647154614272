import { EmployeesActions } from '../user-management/actions';

export const activateEmoployee = (activationToken: string, password: string) => (dispatch: any, _: any, { api }: any) => {
    api.employeesAPI.employeeActivation(activationToken, password)
        .catch((error: any) => {
            console.log(error);
            dispatch(EmployeesActions.setEmployeeInactiveStatus());
        })
        .then((res: any) => {
            const { data } = res;
            if (data || data === '') {
                dispatch(EmployeesActions.setEmployeeActiveStatus());
            }
        })
}