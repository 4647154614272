import produce from "immer";

import { LOADING, SHOW_SNACKBAR, SET_STATISTICS_DATA } from "./types";

interface InitialState {
  loading: boolean;
  snackbar: any;
  statistics_data: any;
}

const initialState: InitialState = <InitialState>{
  loading: false,
  snackbar: {
    show: false,
    isError: false,
    message: "",
  },
  statistics_data: null,
};

export default function (state = initialState, action: any) {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case LOADING: {
        draft.loading = payload;
        break;
      }
      case SHOW_SNACKBAR: {
        draft.snackbar = payload;
        break;
      }
      case SET_STATISTICS_DATA: {
        draft.statistics_data = payload;
        break;
      }
      default:
        return state;
    }
  });
}
