import { createSelector } from "@reduxjs/toolkit"

const getAccount = (state: any) => state.account;

export const isActivated = createSelector(getAccount, panel => panel.activated);

export const activeFailed = createSelector(getAccount, panel => panel.activateFailed);

export const getLoading = createSelector(getAccount, panel => panel.loading);

export const getSnackbar = createSelector(getAccount, panel => panel.snackbar);