import { Outlet } from "react-router-dom";
import Drawer from "./drawer/Drawer";

const Layout = () => {
    return (
        <Drawer>
            <Outlet />
        </Drawer>
    );
}

export default Layout;