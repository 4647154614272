import api from "./auth";

const getEmployees = (locationId: string) => {
  return api.get(`employees?venueId=${locationId}`);
};

const createEmployee = (data: any) => {
  return api.post("employees", data);
};

const updateEmployee = (employeeId: string, data: any) => {
  return api.patch(`employees/${employeeId}`, data);
};

const getRoles = () => {
  return api.get("employees/roles");
};

const deleteEmployee = (employeeId: string) => {
  return api.delete(`employees/${employeeId}`);
};

const employeeActivation = (activationToken: string, password: string) => {
  return api.postActivate("employees/activate", {
    activationToken,
    password,
  });
};

const resetPasswordRequest = (email: string) => {
  return api.postActivate("employees/request-password-reset", {
    email,
  });
};

const resetPassword = (passwordResetToken: string, newPassword: string) => {
  return api.postActivate("employees/reset-password", {
    passwordResetToken,
    newPassword,
  });
};

const resendActivationLink = (email: string) => {
  return api.post("employees/resend-activation", {
    email,
  });
};

const employeesAPI = {
  getEmployees,
  createEmployee,
  updateEmployee,
  getRoles,
  deleteEmployee,
  employeeActivation,
  resetPasswordRequest,
  resetPassword,
  resendActivationLink,
};

export default employeesAPI;
