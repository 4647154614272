import api from "./auth.ts";

const getStatistics = (venueId: string, dateFrom: string, dateTo: string) => {
    return api.get(`statistics/${venueId}?dateFrom=${dateFrom}&dateTo=${dateTo}`);
};

const statisticsAPI = {
    getStatistics,
};
export default statisticsAPI;
