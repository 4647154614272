import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography, useTheme, Select, MenuItem, FormControl, InputLabel, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FiscalReceiptService } from "../../../mobile/FiscalReceiptService.ts";
import { DeviceCommuncationService } from "../../../mobile/daisy-communication-protocol.ts";
import { Capacitor } from "@capacitor/core";
import { LayoutActions } from "./actions.ts";
import { useAppDispatch } from "../../../hooks.ts";

export enum FiscalReceiptObjectType {
    UMBRELLA = "UMBRELLA",
    SUNBED = "SUNBED",
    BEACH_EQUIPMENT = "BEACH_EQUIPMENT",
    BEACH_SET = "BEACH_SET",
}

const FiscalReceiptObjectTypeTranslations = {
    [FiscalReceiptObjectType.UMBRELLA]: "Чадър",
    [FiscalReceiptObjectType.SUNBED]: "Шезлонг",
    [FiscalReceiptObjectType.BEACH_EQUIPMENT]: "ПЛАЖНО СЪОРЪЖЕНИЕ",
    [FiscalReceiptObjectType.BEACH_SET]: "Плажен комплект",
};

const CreateFiscalReceiptForAmountModal = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState(false);
    const [selectedType, setSelectedType] = useState(FiscalReceiptObjectType.UMBRELLA);
    const theme = useTheme();

    const formatAmount = (value) => {
        const numericValue = value.replace(/[^0-9]/g, '');
        const trimmedValue = numericValue.replace(/^0+/, ''); // Remove leading zeros
        if (trimmedValue.length <= 2) {
            return `00.${trimmedValue.padStart(2, '0')}`;
        }
        const integerPart = trimmedValue.slice(0, -2);
        const decimalPart = trimmedValue.slice(-2);
        const formattedValue = parseInt(integerPart, 10) < 100
            ? `${integerPart.padStart(2, '0')}.${decimalPart}`
            : `${integerPart.padStart(3, '0')}.${decimalPart}`;
        return parseFloat(formattedValue) > 999 ? '999.99' : formattedValue;
    };

    const handleInputChange = (event) => {
        const value = event.target.value.replace('.', '');
        if (/^\d*$/.test(value)) {
            const formattedValue = formatAmount(value);
            setAmount(formattedValue);
            setWarning(parseFloat(formattedValue) > 100);
        }
    };

    const handleKeypadClick = (value) => {
        const newAmount = amount.replace('.', '') + value;
        const formattedValue = formatAmount(newAmount);
        setAmount(formattedValue);
        setWarning(parseFloat(formattedValue) > 100);
    };

    const handleClear = () => {
        setAmount('');
        setWarning(false);
    };

    const handleCancelStartedFiscalReceipt = async () => {
        setLoading(true);
        if (Capacitor.getPlatform() === 'android') {
            try {
                await new FiscalReceiptService(new DeviceCommuncationService()).cancelStartedFiscalReceipt();
            } catch (e) {
                dispatch(
                    LayoutActions.snackbarState({
                        show: true,
                        isError: true,
                        message: 'Проблем с връзката с фискалното устройство, опитайте отново'
                    })
                );
                onClose();
            }
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        if (Capacitor.getPlatform() === 'android') {
            const user = JSON.parse(localStorage.getItem('user') as any);
            try {
                await new FiscalReceiptService(new DeviceCommuncationService()).createReceiptForAmount(
                    FiscalReceiptObjectTypeTranslations[selectedType].toUpperCase(),
                    String(amount),
                    user
                );
            } catch (e) {
                dispatch(
                    LayoutActions.snackbarState({
                        show: true,
                        isError: true,
                        message: 'Проблем с връзката с фискалното устройство, опитайте отново'
                    })
                );
            }
        }
        setLoading(false);
        handleClear();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                pt: 2, // Adjust padding top to accommodate close button
            }}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <FormControl fullWidth sx={{ mb: 2, mt: 4 }}>
                    <InputLabel id="fiscal-receipt-type-label">Тип</InputLabel>
                    <Select
                        labelId="fiscal-receipt-type-label"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        label="Тип"
                    >
                        {Object.entries(FiscalReceiptObjectTypeTranslations).map(([key, value]) => (
                            <MenuItem key={key} value={key}>{value}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Typography variant="h6" component="h2">
                    Въведете сума
                </Typography>
                <TextField
                    fullWidth
                    value={amount}
                    onChange={handleInputChange}
                    inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
                    sx={{ mt: 2, mb: 2 }}
                />
                {warning && (
                    <Typography variant="body2" color="warning">
                        Сумата надвишава 100 лв.
                    </Typography>
                )}
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1, mt: 2 }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0].map((num) => (
                        <Button
                            key={num}
                            variant="contained"
                            onClick={() => handleKeypadClick(num.toString())}
                            sx={{ width: '100%', margin: '5px', background: "#044D5E", color: "#ABFFC1" }}
                        >
                            {num}
                        </Button>
                    ))}
                    <Button
                        variant="contained"
                        onClick={handleClear}
                        sx={{ width: '100%', margin: '5px', background: "#044D5E", color: "#ABFFC1" }}
                    >
                        C
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                    <Button
                        variant="contained"
                        disabled={loading}
                        onClick={handleSubmit}
                        sx={{
                            mb: 2, // Margin bottom to create space between buttons
                            background: 'black',
                            color: theme.palette.primary.contrastText,
                            fontSize: '0.875rem' // Smaller font size
                        }}
                    >
                        Принтирай
                    </Button>
                    <Button
                        onClick={handleCancelStartedFiscalReceipt}
                        disabled={loading}
                        sx={{
                            background: loading ? "#044D5E" : "#ee5a5a",
                            color: loading ? "#C5FFFF" : "#fff",
                            fontSize: '0.875rem' // Smaller font size
                        }}
                    >
                        Анулирай започнат касов бон
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateFiscalReceiptForAmountModal;
