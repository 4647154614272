import {
    SAVE_LAYOUT,
    APPROVED_LAYOUT,
    PENDING_LAYOUT,
    VENUE_DETAIL_LAYOUT,
    LOADING
} from "./types";

// Utility function to create an action creator
const createAction = (type: string) => (payload: any) => ({
    type,
    payload,
});

// Generate action creators using the utility function
const saveLayout = createAction(SAVE_LAYOUT);
const approvedLayout = createAction(APPROVED_LAYOUT);
const pendingLayout = createAction(PENDING_LAYOUT);
const venueDetailLayout = createAction(VENUE_DETAIL_LAYOUT);

const loadingState = createAction(LOADING);

export const LayoutActions = {
    saveLayout,
    approvedLayout,
    pendingLayout,
    venueDetailLayout,
    loadingState
};
