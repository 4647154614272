import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useMedia } from "../../utils/useMedia";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch } from "../../hooks";
import { resetPassword } from "./async-actions";

const Reset = () => {
    const dispatch = useAppDispatch();
    const { tablet } = useMedia();

    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [sent, setSent] = useState(false);
    const [resetToken, setResetToken] = useState('');

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseDownRepeatPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handlePassword = (event: any) => {
        setPassword(event.target.value);
    }

    const handleRepeatPassword = (event: any) => {
        setRepeatPassword(event.target.value);
    }

    const handleActivate = () => {
        dispatch(resetPassword(resetToken ? resetToken : '', password));
        setSent(true);
        setPassword('');
        setRepeatPassword('');
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('resetToken');
        if (token) {
            setResetToken(token);
        }
    }, [location]);

    useEffect(() => {
        if ((password === repeatPassword) && (password !== '' && repeatPassword !== '') && resetToken) {
            setIsPasswordValid(true);
        } else {
            setIsPasswordValid(false);
        }
    }, [password, repeatPassword]);

    return (
        <Box sx={{
            p: 3,
            height: '100%'
        }}>
            <Box sx={{
                background: '#fff',
                borderRadius: 2,
                p: 2,
                position: 'relative',
                height: '100%'
            }}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '500px',
                    height: '300px',
                    maxWidth: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    borderRadius: '16px',
                    padding: '20px 16px'
                }}>
                    {(!resetToken || !resetToken.length) &&
                        <Box sx={{
                            background: '#06191E',
                            width: '100%',
                            borderRadius: 2,
                            color: '#fff',
                            p: 1
                        }}>
                            Невалиден код за смяна на парола!
                        </Box>
                    }
                    {sent &&
                        <Box sx={{
                            color: '#044D5E',
                            background: '#ABFFC1',
                            width: '100%',
                            p: 2,
                            borderRadius: 2
                        }}>
                            Паролата е променена успешно! <Link to="/">Към логин</Link>
                        </Box>
                    }
                    <FormControl
                        sx={{
                            mt: 2,
                            width: tablet ? '400px' : '320px',
                        }}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="password">Парола</InputLabel>
                        <OutlinedInput
                            id="password"
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Парола"
                            onChange={handlePassword}
                        />
                    </FormControl>
                    <FormControl
                        sx={{
                            mt: 2,
                            width: tablet ? '400px' : '320px',
                        }}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="repeatPassword">Повторете паролата</InputLabel>
                        <OutlinedInput
                            id="repeatPassword"
                            value={repeatPassword}
                            type={showRepeatPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowRepeatPassword}
                                        onMouseDown={handleMouseDownRepeatPassword}
                                        edge="end"
                                    >
                                        {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Повторете паролата"
                            onChange={handleRepeatPassword}
                        />
                    </FormControl>
                    <Button
                        sx={{
                            mt: 1
                        }}
                        variant="contained"
                        disabled={!isPasswordValid}
                        onClick={handleActivate}
                    >
                        Запази
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Reset;
