import * as React from "react";
import {useSelector} from "react-redux";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";

import {getSelectedDate, getSelectedReservedSpot} from "../sidepanel/selectors";

import CloseIcon from "@mui/icons-material/Close";
import {FiscalReceiptService} from "../../../mobile/FiscalReceiptService.ts";
import {DeviceCommuncationService} from "../../../mobile/daisy-communication-protocol.ts";
import {Capacitor} from "@capacitor/core";
import {LayoutActions} from "./actions.ts";
import {useAppDispatch} from "../../../hooks.ts";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MarkReceiptIssuedModal = ({
  handleConfirm,
  handleClose,
  isComplete,
}: any) => {

  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const selectedReservedSpot = useSelector(getSelectedReservedSpot);

  const selectedDate = useSelector(getSelectedDate);

  const user = JSON.parse(localStorage.getItem('user') as any);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (Capacitor.getPlatform() === 'android') {
                await new FiscalReceiptService(new DeviceCommuncationService()).createReceiptForReservation(selectedReservedSpot, user);
                console.log(`success`);
            }
            handleConfirm();
        } catch (e) {
            dispatch(
                LayoutActions.snackbarState({
                    show: true,
                    isError: true,
                    message: 'Проблем с връзката с фискалното устройство, опитайте отново'
                })
            );
            console.error(e);
            handleClose(e);
        }
        setLoading(false);
    }

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="confirm-reserve-dialog"
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {"Принтиране на касов бон"}
        </DialogTitle>

        <DialogContent sx={{textAlign: 'center'}}>
            {loading ? <Box><CircularProgress/> <p className="ma-0">Принтиране...</p></Box> : null}
        </DialogContent>
        <DialogActions
          sx={{
              display: "flex",
              justifyContent: "center",
          }}
        >
         {!loading && (
                <Button
                    onClick={handleSubmit}
                    sx={{
                        background: isComplete ? "#044D5E" : "#5a9c34",
                        color: isComplete ? "#C5FFFF" : "#fff",
                    }}
                >
                    Принтирай
                </Button>
            )}
        </DialogActions>

        <IconButton
          sx={{
            position: "absolute",
            right: "7px",
            top: "10px",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </div>
  );
};

export default MarkReceiptIssuedModal;
