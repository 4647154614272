import * as React from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  MenuItem,
  Autocomplete,
  Card,
  CardContent,
  Alert,
  CircularProgress,
  Snackbar,
} from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  submitVenue,
  editPendingVenue,
  editApprovedVenue,
} from "./async-actions";
import { useAppDispatch } from "../../hooks";
import { useState, useEffect } from "react";
import DropBox from "./DropBox";
import { DeleteIcon } from "../../assets/icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import dayjs, { Dayjs } from "dayjs";

import { useSelector } from "react-redux";
import { getLoading, getSnackbar } from "./selectors";
import { LayoutActions } from "./actions";
import LocationMap from "./LocationMap";
import { hasPermission } from "../../store/permissions";
import { RefundTypesEnum } from "../../utils";

const amenitiesList = [
  {
    label: "Umbrellas",
    value: "UMBRELLAS",
  },
  {
    label: "Wi-Fi",
    value: "WI_FI",
  },
  {
    label: "Sunbeds",
    value: "SUNBEDS",
  },
  {
    label: "Restaurant",
    value: "RESTAURANT",
  },
  {
    label: "Tents",
    value: "TENTS",
  },
  {
    label: "Bar",
    value: "BAR",
  },
  {
    label: "Shower",
    value: "SHOWER",
  },
  {
    label: "Medical point",
    value: "MEDICAL_POINT",
  },
  {
    label: "Pool",
    value: "POOL",
  },
  {
    label: "Volleyball field",
    value: "VOLLEYBALL_FIELD",
  },
  {
    label: "Dressing room",
    value: "DRESSING_ROOM",
  },
  {
    label: "Kid’s playground",
    value: "KIDS_PLAYGROUND",
  },
  {
    label: "Other(s)",
    value: "OTHER",
  },
];

const refundPolicyTranslations = {
  [RefundTypesEnum.FULL_REFUND]: "Пълно възстановяване",
  [RefundTypesEnum.PARTIAL_REFUND]: "Частично възстановяване",
  [RefundTypesEnum.NO_REFUND]: "Без възстановяване",
};

const CreateLocation = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const loading = useSelector(getLoading);

  const snackbar = useSelector(getSnackbar);

  const initialDataFromLocation = location.state ? location.state.row : null;

  const listType = location.state ? location.state.listType : null;

  const [initialData, setInitialData] = useState(initialDataFromLocation);

  const [type, setType] = useState<string>("");
  const [locationName, setLocationName] = useState<string>("");
  const [priceLevel, setPriceLevel] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [contactEmail, setContactEmail] = useState<string>("");
  const [contactPhone, setContactPhone] = useState<string>("");
  const [keywords, setKeywords] = useState<string[]>([]);
  const [amenities, setAmenities] = useState<{ label: string }[]>([]);
  const [latitude, setLatitude] = useState<number | undefined>(undefined);
  const [longitude, setLongitude] = useState<number | undefined>(undefined);
  const [city, setCity] = useState<string>("");
  const [openingHour, setOpeningHour] = useState<Dayjs | null>(null);
  const [closingHour, setClosingHour] = useState<Dayjs | null>(null);
  const [acceptReservationUntilHour, setAcceptReservationUntilHour] =
    useState<Dayjs | null>(null);
  const [reservationValidUntilHour, setReservationValidUntilHour] =
    useState<Dayjs | null>(null);
  const [coverImage, setCoverImage] = useState<File[]>([]);
  const [galleryImages, setGalleryImages] = useState<File[]>([]);
  const [refundPolicy, setRefundPolicy] = useState<RefundTypesEnum>(RefundTypesEnum.FULL_REFUND);
  const [refundPercentage, setRefundPercentage] = useState<number|undefined>(undefined);
  const [refundValidityDays, setRefundValidityDays] = useState<number|undefined>(undefined);

  const [imagesToBeDeleted] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  const types = [{ value: "BEACH", label: "Beach" }];

  const [inputError, setInputError] = useState<string>("");

  const priceLevels = [
    { value: "HIGH", label: "High" },
    { value: "MEDIUM", label: "Medium" },
    { value: "LOW", label: "Low" },
  ];

  useEffect(() => {
    if (!hasPermission("SUBMIT_VENUE") && !hasPermission("UPDATE_VENUE")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (initialData) {
      setType(initialData.type || "");
      setLocationName(initialData.name || "");
      setPriceLevel(initialData.priceLevel || "");
      setDescription(initialData.description || "");
      setContactEmail(initialData.contactEmail || "");
      setContactPhone(initialData.contactPhoneNumber || "");
      setKeywords(initialData.keywords || []);
      setAmenities(initialData.amenities || []);
      setLatitude(initialData.latitude || 0);
      setLongitude(initialData.longitude || 0);
      setCity(initialData.city || "");
      setOpeningHour(
        initialData.openingHour ? dayjs(initialData.openingHour, "HH:mm") : null
      );
      setClosingHour(
        initialData.closingHour ? dayjs(initialData.closingHour, "HH:mm") : null
      );
      setAcceptReservationUntilHour(
        initialData.acceptReservationsUntilHour
          ? dayjs(initialData.acceptReservationsUntilHour, "HH:mm")
          : null
      );
      setReservationValidUntilHour(
        initialData.reservationsValidUntilHour
          ? dayjs(initialData.reservationsValidUntilHour, "HH:mm")
          : null
      );
      setRefundPolicy(
          initialData.refundPolicy ? initialData.refundPolicy :
              RefundTypesEnum.FULL_REFUND
      );
      setRefundValidityDays(
          initialData.refundValidityDays ? initialData.refundValidityDays :
              null
      );
      setRefundPercentage(
            initialData.refundPercentage ? initialData.refundPercentage :
                null
      );

      setCoverImage([initialData.coverImageUrl]);
      setGalleryImages(initialData.galleryImageUrls);
    } else {
      console.log("Resetting form fields...");
    }
  }, [initialData]);

  const onChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const onChangePriceLevel = (event: SelectChangeEvent) => {
    setPriceLevel(event.target.value as string);
  };

  const handleKeyword = (newTags: string[]) => {
    setKeywords(newTags);
  };

  const handleCoverImageDrop = (files: File[]) => {
    if (files.length > 0) {
      setCoverImage([files[0]]);
    }
  };

  const clearCoverImage = () => {
    const clearedCoverImage = coverImage[0];
    if (typeof clearedCoverImage === "string") {
      imagesToBeDeleted.push(clearedCoverImage);
    }
    setCoverImage([]);
  };

  const removeGalleryImage = (indexToRemove: number) => {
    setGalleryImages((prevImages) => {
      const removedImage = prevImages[indexToRemove];
      if (typeof removedImage === "string") {
        imagesToBeDeleted.push(removedImage);
      }

      return prevImages.filter((_, index) => index !== indexToRemove);
    });
  };

  const handleGalleryImagesDrop = (files: File[]) => {
    setGalleryImages((prevImages) => [...prevImages, ...files]);
  };

  const handleLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setLatitude(isNaN(value) ? undefined : value);
  };

  const handleLongitudeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value);
    setLongitude(isNaN(value) ? undefined : value);
  };

  const handleRefundPolicyChange = (event: SelectChangeEvent) => {
    const newPolicy = event.target.value as RefundTypesEnum;
    setRefundPolicy(newPolicy);
    setRefundValidityDays(null);
    setRefundPercentage(null);
  };

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(
      LayoutActions.snackbarState({
        show: false,
        isError: snackbar.isError,
        message: "",
      })
    );
  };

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!type) {
      setInputError("Please provide a type.");
      return false;
    }
    if (!locationName) {
      setInputError("Please provide a location name.");
      return false;
    }
    if (!priceLevel) {
      setInputError("Please provide a price level.");
      return false;
    }
    if (!description) {
      setInputError("Please provide a description.");
      return false;
    }

    if (!contactEmail) {
      setInputError("Please provide a contact email.");
      return false;
    } else if (!emailRegex.test(contactEmail)) {
      setInputError("Please provide a valid email address.");
      return false;
    }

    if (!contactPhone) {
      setInputError("Please provide a contact phone number.");
      return false;
    }

    if (keywords.length == 0) {
      setInputError("Keywords is required");
      return false;
    } else if (keywords.length < 3) {
      setInputError("Minimum 3 keywords is required");
      return false;
    }

    if (amenities.length == 0) {
      setInputError("Amenities is required");
      return false;
    }

    if (!latitude) {
      setInputError("Please provide a latitude.");
      return false;
    }
    if (!longitude) {
      setInputError("Please provide a longitude.");
      return false;
    }
    if (!city) {
      setInputError("Please provide a city.");
      return false;
    }
    if (!openingHour) {
      setInputError("Please provide an opening hour.");
      return false;
    }
    if (!closingHour) {
      setInputError("Please provide a closing hour.");
      return false;
    }
    if (!acceptReservationUntilHour) {
      setInputError("Please provide an accept reservation until hour.");
      return false;
    }

    setInputError("");
    return true;
  };

  const saveChanges = () => {
    const isValid = validate();
    if (!isValid) return;

    const formData = new FormData();

    const fieldData = [
      {
        key: "type",
        value: JSON.stringify(type),
        contentType: "application/json",
      },
      {
        key: "priceLevel",
        value: JSON.stringify(priceLevel),
        contentType: "application/json",
      },
      { key: "name", value: locationName, contentType: "text/plain" },
      { key: "description", value: description, contentType: "text/plain" },
      { key: "contactEmail", value: contactEmail, contentType: "text/plain" },
      {
        key: "contactPhoneNumber",
        value: contactPhone,
        contentType: "text/plain",
      },
      {
        key: "keywords",
        value: JSON.stringify(keywords),
        contentType: "application/json",
      },
      {
        key: "amenities",
        value: JSON.stringify(amenities),
        contentType: "application/json",
      },
      {
        key: "openingHour",
        value: JSON.stringify(dayjs(openingHour).format("HH:mm")),
        contentType: "application/json",
      },
      {
        key: "closingHour",
        value: JSON.stringify(dayjs(closingHour).format("HH:mm")),
        contentType: "application/json",
      },
      {
        key: "acceptReservationsUntilHour",
        value: JSON.stringify(
          dayjs(acceptReservationUntilHour).format("HH:mm")
        ),
        contentType: "application/json",
      },
      {
        key: "reservationsValidUntilHour",
        value: JSON.stringify(dayjs(reservationValidUntilHour).format("HH:mm")),
        contentType: "application/json",
      },
      {
        key: "refundPolicy",
        value: JSON.stringify(refundPolicy),
        contentType: "application/json"
      },
      {
        key: "refundPercentage",
        value: refundPercentage !== undefined ? JSON.stringify(refundPercentage) : undefined,
        contentType: "application/json"
      },
      {
        key: "refundValidityDays",
        value: refundValidityDays !== undefined ? JSON.stringify(refundValidityDays) : undefined,
        contentType: "application/json"
      },
      {
        key: "latitude",
        value: JSON.stringify(latitude),
        contentType: "application/json",
      },
      {
        key: "longitude",
        value: JSON.stringify(longitude),
        contentType: "application/json",
      },
      { key: "city", value: city, contentType: "text/plain" },
    ];

    fieldData.forEach(({ key, value, contentType }) => {
      if (value !== undefined) {
        const blob = new Blob([value], { type: contentType });
        formData.append(key, blob, `blob`);
      }
    });

    coverImage.forEach((image) => {
      if (image instanceof File) {
        formData.append("coverImage", image, image.name);
      }
    });

    galleryImages.forEach((image) => {
      if (image instanceof File) {
        formData.append("galleryImages", image, image.name);
      }
    });

    if (imagesToBeDeleted.length !== 0) {
      const blob = new Blob([JSON.stringify(imagesToBeDeleted)], { type: "application/json" });
      formData.append("imagesToBeDeleted", blob, `blob`);
    }

    if (initialData) {
      if (listType === "pending") {
        dispatch(editPendingVenue(initialData.id, formData));
      } else if (listType === "approved") {
        dispatch(editApprovedVenue(initialData.id, formData));
      }
    } else {
      dispatch(submitVenue(formData));
    }
  };

  return (
<Box
  sx={{
    pt: 3,
    pr: 3,
    pl: 3,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: 2,
    position: "relative",
  }}
>
  <Card variant="outlined">
    <CardContent>
      <Typography
        variant="h1"
        gutterBottom
        sx={{
          fontSize: "19px",
          fontWeight: "bold",
        }}
      >
        Основна информация
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <p className="fieldLabels">Тип</p>
          <Select
            value={type}
            fullWidth
            size="small"
            onChange={onChangeType}
          >
            {types.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={6}>
          <p className="fieldLabels">Име на локация</p>
          <TextField
            value={locationName}
            size="small"
            onChange={(event) => setLocationName(event.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <p className="fieldLabels">Ценово ниво</p>
          <Select
            value={priceLevel}
            fullWidth
            size="small"
            onChange={onChangePriceLevel}
          >
            {priceLevels.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <p className="fieldLabels">Описание</p>
          <TextField
            value={description}
            multiline
            onChange={(event) => setDescription(event.target.value)}
            size="small"
            fullWidth
            className="descriptionArea"
          />
        </Grid>
      </Grid>

      <Typography
        variant="h1"
        gutterBottom
        sx={{
          fontSize: "19px",
          fontWeight: "bold",
          marginTop: "30px",
        }}
      >
        Контактна информация
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <p className="fieldLabels">Имейл за контакт</p>
          <TextField
            value={contactEmail}
            onChange={(event) => setContactEmail(event.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <p className="fieldLabels">Телефон за контакт</p>
          <TextField
            value={contactPhone}
            onChange={(event) => setContactPhone(event.target.value)}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography
        variant="h1"
        gutterBottom
        sx={{
          fontSize: "19px",
          fontWeight: "bold",
          marginTop: "30px",
        }}
      >
        Ключови думи и удобства
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <p className="fieldLabels">Ключови думи</p>
          <TagsInput value={keywords} onChange={handleKeyword} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <p className="fieldLabels">Удобства</p>
          <Autocomplete
            multiple
            disableCloseOnSelect
            id="tags-standard"
            value={amenitiesList.filter((amenity: any) =>
              amenities.includes(amenity.value)
            )}
            options={amenitiesList}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue: any) =>
              setAmenities(newValue.map((item: any) => item.value))
            }
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
      </Grid>

      <Typography
        variant="h1"
        gutterBottom
        sx={{
          fontSize: "19px",
          fontWeight: "bold",
          marginTop: "30px",
        }}
      >
        Работно време
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <p className="fieldLabels">Час на отваряне</p>
            <TimeField
              value={openingHour}
              size="small"
              fullWidth
              format="HH:mm"
              onChange={(newValue) =>
                newValue !== null && setOpeningHour(newValue)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <p className="fieldLabels">Час на затваряне</p>
            <TimeField
              value={closingHour}
              size="small"
              fullWidth
              format="HH:mm"
              onChange={(newValue) =>
                newValue !== null && setClosingHour(newValue)
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <p className="fieldLabels">Приемане на резервации до</p>
            <TimeField
              value={acceptReservationUntilHour}
              size="small"
              fullWidth
              format="HH:mm"
              onChange={(newValue) =>
                newValue !== null && setAcceptReservationUntilHour(newValue)
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <p className="fieldLabels">Резервацията е валидна до</p>
            <TimeField
              value={reservationValidUntilHour}
              size="small"
              fullWidth
              format="HH:mm"
              onChange={(newValue) =>
                newValue !== null && setReservationValidUntilHour(newValue)
              }
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Typography
        variant="h1"
        gutterBottom
        sx={{
          fontSize: "19px",
          fontWeight: "bold",
          marginTop: "30px",
        }}
      >
        Местоположение
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <p className="fieldLabels">Географска ширина</p>
          <TextField
            value={latitude === undefined ? "" : latitude.toString()}
            onChange={handleLatitudeChange}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <p className="fieldLabels">Географска дължина</p>
          <TextField
            value={longitude === undefined ? "" : longitude.toString()}
            size="small"
            onChange={handleLongitudeChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <p className="fieldLabels">Град</p>
          <TextField
            value={city}
            size="small"
            onChange={(event) => setCity(event.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      <LocationMap
        initialLatitude={latitude}
        initialLongitude={longitude}
        onMapClick={(lat, lng) => {
          setLatitude(lat.toString());
          setLongitude(lng.toString());
        }}
      />

      <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: "19px",
            fontWeight: "bold",
            marginTop: "30px",
          }}
      >
        Политика за анулиране
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <p className="fieldLabels">Тип политика</p>
          <Select
              value={refundPolicy}
              fullWidth
              size="small"
              onChange={handleRefundPolicyChange}
          >
            {Object.entries(RefundTypesEnum).map(([key, value]) => (
                <MenuItem key={value} value={value}>
                  {refundPolicyTranslations[value]}
                </MenuItem>
            ))}
          </Select>
        </Grid>
        {refundPolicy === RefundTypesEnum.PARTIAL_REFUND && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <p className="fieldLabels">Процент на възстановяване</p>
              <TextField
                  type="number"
                  value={refundPercentage}
                  onChange={(e) => setRefundPercentage(parseInt(e.target.value))}
                  size="small"
                  fullWidth
              />
            </Grid>
        )}
        {refundPolicy !== RefundTypesEnum.NO_REFUND && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <p className="fieldLabels">Валидни дни</p>
              <TextField
                  type="number"
                  value={refundValidityDays}
                  onChange={(e) => setRefundValidityDays(parseInt(e.target.value))}
                  size="small"
                  fullWidth
              />
            </Grid>
        )}
      </Grid>


      <Typography
        variant="h1"
        gutterBottom
        sx={{
          fontSize: "19px",
          fontWeight: "bold",
          marginTop: "30px",
        }}
      >
        Прикачени файлове
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <p className="fieldLabels">Основна снимка</p>
          <DropBox onDrop={handleCoverImageDrop} />
          {coverImage.map((image, index) => (
            <div className="d-flex">
              <div className="attachment-wrapper">
                <img
                  src={
                    image instanceof File
                      ? URL.createObjectURL(image)
                      : image
                  }
                  alt="Cover Image"
                  className="coverPhoto"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />

                <div onClick={clearCoverImage} className="delete">
                  <DeleteIcon />
                </div>
              </div>
            </div>
          ))}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <p className="fieldLabels">Галерия снимки</p>
          <DropBox onDrop={handleGalleryImagesDrop} />

          <div className="gallery-box">
            {galleryImages.map((image, index) => (
              <div className="attachment-wrapper" key={index}>
                <img
                  src={
                    image instanceof File
                      ? URL.createObjectURL(image)
                      : image
                  }
                  alt={`Gallery Image ${index + 1}`}
                  className="galleryPhoto"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
                <div
                  className="delete"
                  onClick={() => removeGalleryImage(index)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>

      {inputError && (
        <div>
          <Alert sx={{ marginTop: "30px" }} severity="error">
            {inputError}
          </Alert>
        </div>
      )}

      {hasPermission("SUBMIT_VENUE") && hasPermission("UPDATE_VENUE") && (
            <div className="d-flex">
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  sx={{ marginTop: "20px", marginBottom: "30px" }}
                  variant="contained"
                  disabled={loading}
                  onClick={saveChanges}
                >
                  Запази промените
                </Button>

                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-16px",
                      marginLeft: "-16px",
                    }}
                  />
                )}
              </Box>
            </div>
          )}
        </CardContent>
      </Card>

  <br />
  <br />

  <Snackbar
    open={snackbar.show}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    autoHideDuration={6000}
    onClose={handleSnackBarClose}
  >
    <Alert
      severity={snackbar.isError ? "error" : "success"}
      variant="filled"
      sx={{ width: "100%" }}
      onClose={handleSnackBarClose}
    >
      {snackbar.message}
    </Alert>
  </Snackbar>
</Box>
  );
};

export default CreateLocation;
