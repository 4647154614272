import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
`;

const Carousel = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  height: 300px;
`;

const Slide = styled.div`
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  height: 300px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Dot = styled.div<{ active: any }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props: any) => (props.active ? '#333' : '#ccc')};
  margin: 0 5px;
  cursor: pointer;
`;

const GalleryCarousel = ({ images, activeSlide, onPrevImage, onNextImage, onClick, onDotClick }: any) => {
    return (
        <CarouselContainer>
            <Carousel style={{ transform: `translateX(-${activeSlide * 100}%)` }}>
                {images.map((image: any, index: any) => (
                    <Slide key={index} onClick={() => onClick(index)}>
                        <Image src={image} alt={`Slide ${index + 1}`} />
                    </Slide>
                ))}
            </Carousel>
            <DotsContainer>
                {images.map((_: any, index: any) => (
                    <Dot
                        key={index}
                        active={index === activeSlide}
                        onClick={() => onDotClick(index)}
                    />
                ))}
            </DotsContainer>
            <IconButton
                sx={{ position: 'absolute', top: '50%', left: '24px', transform: 'translateY(-50%)', background: '#044D5E' }}
                onClick={onPrevImage}
            >
                <ChevronLeft sx={{ fill: '#C5FFFF' }} />
            </IconButton>
            <IconButton
                sx={{ position: 'absolute', top: '50%', right: '24px', transform: 'translateY(-50%)', background: '#044D5E' }}
                onClick={onNextImage}
            >
                <ChevronRight sx={{ fill: '#C5FFFF' }} />
            </IconButton>
        </CarouselContainer>
    );
};

export default GalleryCarousel;
