export const resetPasswordRequest = (email: string) => (dispatch: any, _: any, { api }: any) => {
    api.employeesAPI.resetPasswordRequest(email)
        .catch((error: any) => {
            console.log(error);
        })
        .then((res: any) => {
            const { data } = res;
            if (data || data === '') {
            }
        })
}

export const resetPassword = (resetToken: string, email: string) => (dispatch: any, _: any, { api }: any) => {
    api.employeesAPI.resetPassword(resetToken, email)
        .catch((error: any) => {
            console.log(error);
        })
        .then((res: any) => {
            const { data } = res;
            if (data || data === '') {
            }
        })
}