import { useEffect, useState } from "react";
import { Box, Button, FormControl, InputLabel, OutlinedInput} from "@mui/material";
import { useMedia } from "../../utils/useMedia";
import { useAppDispatch } from "../../hooks";
import { resetPasswordRequest } from "./async-actions";

const Request = () => {
    const dispatch = useAppDispatch();
    const { tablet } = useMedia();
    const [email, setEmail] = useState('');
    const [repeatEmail, setRepeatEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [sent, setSent] = useState(false);

    const handleEmail = (event: any) => {
        setEmail(event.target.value);
    }

    const handleRepeatEmail = (event: any) => {
        setRepeatEmail(event.target.value)
    }

    useEffect(() => {
        if ((email === repeatEmail) && (email !== '' && repeatEmail !== '')) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }, [email, repeatEmail]);

    const handleActivate = () => {
        dispatch(resetPasswordRequest(email));
        setSent(true)
        setEmail('');
        setRepeatEmail('');
    }

    return (
        <Box sx={{
            p: 3,
            height: '100%'
        }}>
            <Box sx={{
                background: '#fff',
                borderRadius: 2,
                p: 2,
                position: 'relative',
                height: '100%'
            }}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '500px',
                    height: '300px',
                    maxWidth: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    borderRadius: '16px',
                    padding: '20px 16px'
                }}>
                    {sent &&
                        <Box sx={{
                            color: '#044D5E',
                            background: '#ABFFC1',
                            width: '100%',
                            p: 2,
                            borderRadius: 2
                        }}>
                            Заявката беше изпратена успешно. Ако имате активна регистрация с посочения имейл {email}, ще получите линк за промяна на паролата.
                        </Box>
                    }
                    <FormControl
                        sx={{
                            mt: 2,
                            width: tablet ? '400px' : '320px',
                        }}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="email">Имейл</InputLabel>
                        <OutlinedInput
                            id="email"
                            type='email'
                            label="Имейл"
                            value={email}
                            onChange={handleEmail}
                        />
                    </FormControl>
                    <FormControl
                        sx={{
                            mt: 2,
                            width: tablet ? '400px' : '320px',
                        }}
                        variant="outlined"
                    >
                        <InputLabel htmlFor="repeatEmail">Повторете имейла</InputLabel>
                        <OutlinedInput
                            id="repeatEmail"
                            type='email'
                            label="Повторете имейла"
                            value={repeatEmail}
                            onChange={handleRepeatEmail}
                        />
                    </FormControl>
                    <Button
                        sx={{
                            mt: 1
                        }}
                        variant="contained"
                        disabled={!isEmailValid}
                        onClick={handleActivate}
                    >
                        Заяви промяна
                    </Button>
                </Box>
            </Box>
        </Box >
    )
}

export default Request;