import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { getSelectedInitilizeSpot, getSelectedDate } from "../sidepanel/selectors";

import { getActiveLocationMetadata } from "../../../store/locations/selectors";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MakeReservationModal = ({ handleReservation, handleClose }: any) => {
  const selectedInitilizeSpot = useSelector(getSelectedInitilizeSpot);

  const activeLocation = useSelector(getActiveLocationMetadata);

  const selectedDate = useSelector(getSelectedDate);

  const handleSubmit = () => {
    const formData = {
      spotId: selectedInitilizeSpot.id,
      venueId: activeLocation.id,
      startDate: selectedDate,
      endDate: selectedDate
    };


    handleReservation(formData);
  };

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Направи резервация"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p className="ma-0">
                Тип: <b>{selectedInitilizeSpot.type}</b>
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p className="ma-0">
                Номер на място: <b>{selectedInitilizeSpot.spot.label}</b>
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p className="ma-0">
                Капацитет: <b>{selectedInitilizeSpot.spot.capacity}</b>
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <p className="ma-0">
                Цена: <b>{selectedInitilizeSpot.spot.costAmount} лв.</b>
              </p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отказ</Button>
          <Button onClick={handleSubmit}>Резервирай</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MakeReservationModal;
