import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  TentIcon,
  UmbrellaIcon,
  UmbrellaSunbedIcon,
  PathVerticalIcon,
  PathHorizontaIcon,
  TreeIcon,
  LifeguardIcon,
  BarIcon,
  MedicalIcon,
  PoolIcon,
  VolleyballIcon,
  SunbedIcon,
  PlaygroundIcon,
  RestaurantIcon,
} from "./BeachIcons";
import Box from "@mui/material/Box";
import {
  getDimensions,
  getLayoutItems,
  getLocationReservationByDate,
  getSearchedSpots,
  getSpots,
  getLoading,
} from "./selector";
import { Sea } from "../../../assets/icons";
import { Typography, styled, LinearProgress, IconButton } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { DashboardSidepanelActions } from "../sidepanel/actions";
import { useAppDispatch } from "../../../hooks";
import {
  getSelectedSpots,
  getSelectedInitilizeSpot,
  getSelectedReservedSpot,
} from "../sidepanel/selectors";
import { useMedia } from "../../../utils";
import MiniLogo from "../../../assets/mini-logo.png";
import { hasPermission } from "../../../store/permissions";

import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw";

import { getActiveLocationMetadata } from "../../../store/locations/selectors";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0px 4px 28px 0px #00000040",
    borderRadius: "12px",
    padding: "10px",
  },
  ["& .MuiTooltip-popperArrow"]: {
    background: "red",
    color: "green",
  },
  ["& .MuiTooltip-arrow"]: {
    color: "#fff",
    border: "none",
    outline: "none",
  },
}));

const PATH_HEIGHT = 36;
const PATH_WIDTH = 36;
const ICON_SIZE = 54;
const ICON_OFFSET = 8;

const Layout = ({ rotate, handleRotate }: any) => {
  const { tablet, mobileOnly } = useMedia();
  const dispatch = useAppDispatch();
  const loading = useSelector(getLoading);

  const [layout, setLayout] = useState<Array<any> | null>(null);
  const [metadata, setMetadata] = useState<{ [key: string]: any }>({});

  const activeLocation = useSelector(getActiveLocationMetadata);

  const [zoom, setZoom] = useState(1);

  const containerRef = useRef<any | null>(null);

  const builderSpots = useSelector(getLayoutItems);

  const spots = useSelector(getSpots);
  const dimensions = useSelector(getDimensions);
  const selectedSpots = useSelector(getSelectedSpots);
  const selectedInitilizeSpot = useSelector(getSelectedInitilizeSpot);
  const selectedReservedSpot = useSelector(getSelectedReservedSpot);

  const searchedSpots = useSelector(getSearchedSpots);
  const locationReservationsByDate = useSelector(getLocationReservationByDate);

  useEffect(() => {
    setLayout(builderSpots);
  }, [builderSpots]);

  useEffect(() => {
    const updatedMetadata = builderSpots?.reduce((acc: any, obj: any) => {
      if (obj.spot) {
        acc[obj.id] = obj;
      }
      return acc;
    }, {});
    setMetadata(updatedMetadata);
  }, [builderSpots]);

  const unSelectAllSpots = () => {
    unSelectSpot();
    unSelectInitilizeSpot();
    unSelectReservedSpot();
  };

  const unSelectSpot = () => {
    dispatch(DashboardSidepanelActions.toggleSelectedSpots(null));
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.4, 3)); // Limit max zoom level to 3
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.4, 0.5)); // Limit min zoom level to 0.5
  };

  const handlePinchZoom = (event: React.WheelEvent) => {
    if (mobileOnly) {
      if (event.deltaY > 0) {
        handleZoomOut();
      } else {
        handleZoomIn();
      }
    }
  };

  const unSelectInitilizeSpot = () => {
    dispatch(DashboardSidepanelActions.toggleInitilizeSpot(null));
  };

  const unSelectReservedSpot = () => {
    dispatch(DashboardSidepanelActions.toggleReservedSpot(null));
  };

  const handleElementClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: any
  ) => {
    event.stopPropagation();

    const currentItemReservations =
      locationReservationsByDate &&
      locationReservationsByDate.filter((r: any) => r.spotId === item.id);

    const hasActiveOnlineReservation =
      currentItemReservations &&
      currentItemReservations.find((c: any) => c.status === "ACTIVE");

    const spotReservationStatus = spots.find(
      (spot: any) => spot.id === item.id
    );

    const isInitialized = spotReservationStatus && !hasActiveOnlineReservation;

    const isReserved =
      spotReservationStatus &&
      spotReservationStatus.reserved === true &&
      hasActiveOnlineReservation;

    if (
      item.type === "TENT" ||
      item.type === "UMBRELLA" ||
      item.type === "UMBRELLA_SUNBED" ||
      item.type === "SUNBED" ||
      item.plateTag
    ) {
      unSelectAllSpots();

      if (isInitialized && hasPermission("CREATE_RESERVATION")) {
        dispatch(DashboardSidepanelActions.toggleInitilizeSpot(item));
      } else if (isReserved && hasPermission("MANAGE_RESERVATIONS")) {
        dispatch(DashboardSidepanelActions.toggleReservedSpot(item));
      } else if (
        !isInitialized &&
        !isReserved &&
        hasPermission("MANAGE_SPOTS")
      ) {
        dispatch(DashboardSidepanelActions.toggleSelectedSpots(item));
      }
    }
  };

  const imageHandler = (
    type: string,
    isInitialized: boolean,
    isReserved: boolean,
    isSelected: boolean,
    isFiscalIssued: boolean
  ) => {
    const commonProps = {
      isInitialized,
      isReserved,
      isSelected,
      isFiscalIssued,
      enableInactiveHover: true,
      enableInitializedHover: true,
      enableReservedHover: true,
    };

    switch (type) {
      case "tent":
        return <TentIcon {...commonProps} />;
      case "sunbed":
        return <SunbedIcon {...commonProps} />;
      case "umbrella_sunbed":
        return <UmbrellaSunbedIcon {...commonProps} />;
      case "pool":
        return <PoolIcon />;
      case "bar":
        return <BarIcon />;
      case "medical":
        return <MedicalIcon />;
      case "path_horizontal":
        return <PathHorizontaIcon />;
      case "path_vertical":
        return <PathVerticalIcon />;
      case "volleyball_court":
        return <VolleyballIcon />;
      case "path_corner":
        return <></>;
      case "tree":
        return <TreeIcon />;
      case "lifeguard":
        return <LifeguardIcon />;
      case "trampoline":
        return <PlaygroundIcon />;
      case "restaurant":
        return <RestaurantIcon />;
      default:
        return <UmbrellaIcon {...commonProps} />;
    }
  };

  const renderLayout = () => {
    return (
      <div className="layout-box">
        {loading && <LinearProgress />}

        <div className="zoom-in-out-section">
          <IconButton onClick={handleRotate}>
            <Rotate90DegreesCwIcon />
          </IconButton>

          <IconButton onClick={handleZoomIn}>
            <ZoomInIcon />
          </IconButton>
          <IconButton onClick={handleZoomOut}>
            <ZoomOutIcon />
          </IconButton>
        </div>

        {rotate && (
          <>
            <Sea rotate={rotate} />
          </>
        )}
        <Box
          sx={{
            overflow: "auto",
            width: "100%",
            transform: `${rotate ? "rotate(180deg)" : ""}`,
          }}
        >
          <Box
            ref={containerRef}
            sx={{
              position: "relative",
              width: `${dimensions.width * ICON_SIZE}px`,
              height: `${dimensions.height * ICON_SIZE}px`,
              minHeight: `${dimensions.height * ICON_SIZE}px`,
              margin: tablet ? "32px auto" : "0 auto 16px auto",
              display: "flex",
              alignItems: "center",
              // transform: props.rotate ? "rotate(180deg)" : "none",
              transform: `scale(${zoom})`,
              transformOrigin: "0 0",
              justifyContent: "center",
              maxWidth: tablet ? "100%" : "100vw",
              transition: "transform 0.2s ease-in-out",
              // overflowX: "auto",
            }}
            onClick={() => unSelectAllSpots()}
            // onWheel={handlePinchZoom}
          >

            
            {layout &&
              layout?.map((item: any) => {
                const isPath =
                  item.type === "PATH_HORIZONTAL" ||
                  item.type === "PATH_VERTICAL" ||
                  item.type === "PATH_CORNER";
                const isHorizontalPath = item.type === "PATH_HORIZONTAL";
                const isVerticalPath = item.type === "PATH_VERTICAL";
                const isCornerPath = item.type === "PATH_CORNER";

                const itemPositionX = item.startPositionX * ICON_SIZE;
                const itemPositionY = item.startPositionY * ICON_SIZE;
                // const itemPositionX = item.positionX * ICON_SIZE;
                // validate that arrays are not null
                // const isSearched = searchedSpots.find(
                //   (spot: any) => spot.id === item.id && !spot.reserved
                // );

                const spotsReservationStatus = spots.find(
                  (spot: any) => spot.id === item.id
                );

                const currentItemReservations =
                  locationReservationsByDate &&
                  locationReservationsByDate.filter(
                    (r: any) => r.spotId === item.id
                  );

                const hasActiveReservation =
                  currentItemReservations &&
                  currentItemReservations.find(
                    (c: any) => c.status === "ACTIVE"
                  );

                const isInitialized =
                  spotsReservationStatus && !hasActiveReservation;

                const isReserved =
                  spotsReservationStatus &&
                  spotsReservationStatus.reserved === true &&
                  hasActiveReservation;

                const hasActiveOnlineReservation =
                  currentItemReservations &&
                  currentItemReservations.find(
                    (c: any) => c.type === "ONLINE" && c.status === "ACTIVE"
                  );

                const isFiscalIssued =
                  currentItemReservations &&
                  currentItemReservations.find(
                    (c: any) =>
                      c.type === "ON_SITE" &&
                      c.status === "ACTIVE" &&
                      c.fiscalReceiptIssued == false &&
                      activeLocation.fiscalDeviceSupported == true
                  );

                const isSelected =
                  selectedSpots.some((spot: any) => spot.id === item.id) ||
                  (selectedInitilizeSpot &&
                    selectedInitilizeSpot.id == item.id) ||
                  (selectedReservedSpot && selectedReservedSpot.id == item.id);

                return (
                  <Box
                    key={item.id}
                    onClick={(event) => handleElementClick(event, item)}
                    sx={{
                      userSelect: "none",
                      display: "flex",
                      flexDirection: "column",
                      transform: rotate ? "rotate(180deg)" : "none",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      left: itemPositionX, // Adjust the multiplier based on your canvas dimensions
                      top: itemPositionY, // Adjust the multiplier based on your canvas dimensions
                      width: item.width * ICON_SIZE, // Adjust the dimensions of the div element as needed
                      height: item.height * ICON_SIZE, // Adjust the dimensions of the div element as needed,
                      background: isPath ? "#EDEFF2" : "transparent",
                    }}
                  >
                    <HtmlTooltip
                      arrow
                      title={
                        spotsReservationStatus ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              minWidth: "150px",
                            }}
                          >
                            <Box
                              sx={{
                                color: "#044D5E",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography>Код</Typography>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                {metadata[item.id]?.spot.label}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography>
                                {metadata[item.id]?.spot.capacity} шезлонги
                              </Typography>
                              <Typography>
                                {metadata[item.id]?.spot.costAmount} лв.
                              </Typography>
                            </Box>
                          </Box>
                        ) : null
                      }
                    >
                      <Box
                        sx={{
                          width: isHorizontalPath
                            ? item.height * PATH_WIDTH
                            : item.width * ICON_SIZE - ICON_OFFSET,
                          height: isVerticalPath
                            ? item.height * PATH_HEIGHT
                            : item.height * ICON_SIZE - ICON_OFFSET,
                          borderRadius: `${
                            isPath
                              ? "0"
                              : item.width === 1 && item.height === 1
                              ? "100%"
                              : "12px"
                          }`,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          // border: isSelected ? '5px solid #044D5E1A' : '5px solid transparent',
                          cursor: `${
                            item.type === "SUNBED" ||
                            item.type === "UMBRELLA_SUNBED" ||
                            item.type === "TENT" ||
                            item.type === "UMBRELLA"
                              ? "pointer"
                              : "auto"
                          }`,
                          // boxShadow: isSearched ? '-1px 0px 10px 6px rgba(4,77,94,1)' : 'none',
                          position: "relative",
                        }}
                      >
                        {spotsReservationStatus && (
                          <Typography
                            sx={{
                              color: `${
                                isSelected && !isInitialized && !isReserved
                                  ? "#044D5E"
                                  : "#070707"
                              }`,
                              fontSize: "12px",
                              lineHeight: "12px",

                              fontWeight: "700",
                              letterSpacing: ".15px",
                              marginTop: "3px",
                            }}
                          >
                            {metadata[item.id]?.spot.label}
                          </Typography>
                        )}
                        {imageHandler(
                          item.type.toLocaleLowerCase(),
                          isInitialized,
                          isReserved,
                          isSelected,
                          isFiscalIssued
                        )}
                        {isReserved && !!hasActiveOnlineReservation && (
                          <Box
                            sx={{
                              display: "flex",
                              position: "absolute",
                              bottom: "-5px",
                              right: "-5px",
                              width: "15px",
                              height: "15px",
                              background: "red",
                              borderRadius: "100%",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={MiniLogo}
                              style={{
                                width: "100%",
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </HtmlTooltip>
                  </Box>
                );
              })}
          </Box>
        </Box>
        {!rotate && (
          <>
            <Sea rotate={rotate} />
          </>
        )}
      </div>
    );
  };

  const miniMap = {
    position: "fixed",
    bottom: "80px",
    right: "10px",
    width: "150px",
    height: "100px",
    overflow: "auto",
    backgroundColor: "#f2f2f2",
    border: "1px solid #ccc",
  };

  const miniDiv = {
    position: "absolute",
    width: "10px",
    height: "10px",
    backgroundColor: "red",
    /* Apply scaling or any other styling as desired */
  };
  // const MiniMap = () => {
  //   const miniMapWidth = 1000; // Width of the mini map
  //   const miniMapHeight = 2000; // Height of the mini map
  //   // Calculate the scaling factors for width and height
  //   const scaleX = miniMapWidth / ((dimensions.width + 1) * ICON_SIZE);
  //   const scaleY = miniMapHeight / ((dimensions.height + 1) * ICON_SIZE);

  //   return (
  //     <div
  //       style={{
  //         position: "fixed",
  //         bottom: "80px",
  //         right: "10px",
  //         width: "150px",
  //         height: "100px",
  //         overflow: "auto",
  //         backgroundColor: "#f2f2f2",
  //         border: "1px solid #ccc",
  //       }}
  //     >

  //       {/* Render the div elements */}
  //       {layout &&
  //         layout.map((item: any) => {
  //           const scaledPositionX = (item.positionX + 1) * scaleX;
  //           const scaledPositionY = (item.positionY + 1) * scaleY;
  //           const scaledWidth = (item.width + 1) * scaleX;
  //           const scaledHeight = (item.height + 1) * scaleY;

  //           const itemPositionX = item.positionX * ICON_SIZE;
  //           const metadata = spots.find((spot: any) => spot.id === item.id);
  //           const isSearched = searchedSpots.find(
  //             (spot: any) => spot.id === item.id && !spot.reserved
  //           );
  //           const isSelected = selectedSpots.find(
  //             (spot: any) => spot.id === item.id
  //           );

  //           const isDisabled = metadata && metadata.disabled;
  //           const isReserved = metadata && metadata.reserved;
  //           const isInitialized = !!metadata;
  //           return (
  //             // <div
  //             //     key={item.id}
  //             //     style={{
  //             //         position: 'absolute',
  //             //         backgroundColor: 'red',
  //             //         top: scaledPositionY,
  //             //         left: scaledPositionX,
  //             //         width: scaledWidth,
  //             //         height: scaledHeight,
  //             //     }}
  //             // />
  //             <Box
  //               key={item.id}
  //               sx={{
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 alignItems: "center",
  //                 justifyContent: "center",
  //                 position: "absolute",
  //                 left: scaledPositionX, // Adjust the multiplier based on your canvas dimensions
  //                 top: scaledPositionY, // Adjust the multiplier based on your canvas dimensions
  //                 width: scaledWidth, // Adjust the dimensions of the div element as needed
  //                 height: scaledHeight, // Adjust the dimensions of the div element as needed,
  //                 "@media (hover:hover)": {
  //                   "&:hover .MuiBox-root": {
  //                     background: `${
  //                       (item.type === "SUNBED" || item.type === "TENT") &&
  //                       !isSelected &&
  //                       !isReserved &&
  //                       !isDisabled &&
  //                       "#000"
  //                     }`,
  //                   },
  //                 },
  //               }}
  //             >
  //               <Box
  //                 sx={{
  //                   width: item.width * ICON_SIZE - ICON_OFFSET,
  //                   height: item.height * ICON_SIZE - ICON_OFFSET,
  //                   borderRadius: `${
  //                     item.width === 1 || item.height === 1 ? "100%" : "12px"
  //                   }`,
  //                   background: `${
  //                     isInitialized
  //                       ? isReserved || isDisabled
  //                         ? "#EDEFF2"
  //                         : isSelected
  //                         ? "#C5FFFF"
  //                         : "#044D5E"
  //                       : item.type === "SUNBED" ||
  //                         item.type === "TENT" ||
  //                         item.type === "UMBRELLA" ||
  //                         item.type === "UMBRELLA_SUNBED"
  //                       ? "#EDEFF2"
  //                       : "#044D5E"
  //                   }`,
  //                   display: "flex",
  //                   flexDirection: "column",
  //                   alignItems: "center",
  //                   justifyContent: "center",
  //                   border: isSelected
  //                     ? "5px solid #044D5E1A"
  //                     : "5px solid transparent",
  //                   cursor: `${
  //                     item.type === "SUNBED" ||
  //                     item.type === "UMBRELLA" ||
  //                     item.type === "UMBRELLA_SUNBED" ||
  //                     item.type === "TENT"
  //                       ? "pointer"
  //                       : "auto"
  //                   }`,
  //                   boxShadow: isSearched
  //                     ? "-1px 0px 10px 6px rgba(4,77,94,1)"
  //                     : "none",
  //                 }}
  //               />
  //             </Box>
  //           );
  //         })}
  //     </div>
  //   );
  // };
  return (
    <>
      {renderLayout()}
      {/* {document.body.offsetWidth < 769 &&
                <MiniMap />
            } */}
    </>
  );
};

export default Layout;
