import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Button,
  CircularProgress,
  Typography,
  Divider,
} from "@mui/material";
import { fabric } from "fabric";
import AddBeachObjectModal from "./widgets/AddBeachObjectModal";
import RemoveIconModal from "./widgets/RemoveModal";
import EditIcon from "@mui/icons-material/Edit";

import { saveLayout, getVenueDetail } from "./venues/async-actions";
import { venueDetail, savedLayout, getLoading } from "./venues/selectors";

import { v4 as uuidv4 } from "uuid";

import { LayoutActions } from "./venues/actions";

import IconGalleryComponent, {
  IconGalleryImage,
} from "./widgets/IconGallleryComponent";
import { useAppDispatch } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { hasPermission } from "../../store/permissions";
// Define BeachObject and BeachZones constants

const BeachObject = {
  // Lifeguard: "Lifeguard",
  Sunbed: "UMBRELLA_SUNBED",
  Tent: "TENT",
  Umbrella: "UMBRELLA",
  HorizontalPath: "PATH_HORIZONTAL",
  VerticalPath: "PATH_VERTICAL",
};

// [UMBRELLA, LIFEGUARD, UMBRELLA_SUNBED, MEDICAL, BAR, SUNBED, TENT, VOLLEYBALL_COURT, POOL, TRAMPOLINE, PATH_HORIZONTAL, PATH_VERTICAL, TREE]

const BeachZones = {
  Bar: "Bar",
  Medical: "Medical",
  Lifeguard: "Lifeguard",
  Trampoline: "Trampoline",
  Pool: "Pool",
  Restaurant: "Restaurant",
  Playfield: "Volleyball_Court",
};

interface AddBeachObjModalData {
  perRow: number;
  count: number;
  direction: AppendDirection;
  image: {
    imageUrl: string;
    beachObjType: string;
  };
  width: number;
  height: number;
}

enum AppendDirection {
  Vertical = "Vertical",
  Horizontal = "Horizontal",
}

const BeachMapBuilder: React.FC = () => {

  const navigate = useNavigate();

  const { id } = useParams();
  const loading = useSelector(getLoading);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [gridSize, setGridSize] = useState<number>(32);
  const [openBeachObjModal, setOpenBeachObjModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [beachObjects, setBeachObjects] = useState<IconGalleryImage[]>([]);
  const [beachZones, setBeachZones] = useState<IconGalleryImage[]>([]);
  const [previousGroup, setPreviousGroup] = useState(null);
  const [selectedImage, setSelectedImage] = useState<IconGalleryImage | null>(
    null
  );
  const dispatch = useAppDispatch();
  const venueInfo = useSelector(venueDetail);
  const savedCompleted = useSelector(savedLayout);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [overlapDetected, setOverlapDetected] = useState(false);

  useEffect(() => {
    if (!canvasRef.current || !containerRef.current) return;

    const newCanvas = new fabric.Canvas(canvasRef.current, {
      // height: 600,
      height: containerRef.current.offsetHeight,
      width: containerRef.current.offsetWidth,
      // selection: false,
      allowTouchScrolling: true,
      enableRetinaScaling: true,
    });
    setCanvas(newCanvas);
    loadUpIcons();
    return () => {
      newCanvas.dispose();
    };
  }, [canvasRef, containerRef]);

  useEffect(() => {
    //console.log("overlapDetected >>>>>>>>>>>>: ", overlapDetected);
    //console.log("editMode >>>>>>>>>>>>: ", editMode);
    //console.log("createNew >>>>>>>>>>>>: ", createNew);
    // console.log("previousGroup >>>>>>>>>>>>: ", previousGroup);
    if (!canvas) return;
    canvas.on("selection:cleared", handleSelectionCleared);
    // Clean-up function to remove event listeners
    return () => {
      canvas.off("selection:cleared", handleSelectionCleared);
    };
  }, [overlapDetected, editMode, createNew, previousGroup]);

  useEffect(() => {
    if (canvas) {
      // Handle group creation and lock scaling
      canvas.on("selection:created", function (e) {
        var activeSelection = canvas.getActiveObject();
        if (activeSelection && activeSelection.type === "activeSelection") {
          activeSelection.toGroup();
          var group = canvas.getActiveObject();
          if (!group) return;
          setPreviousGroup(group);
          group.lockRotation = true;
          group.lockScalingX = true;
          group.lockScalingY = true;
          group.setControlsVisibility({
            mt: false, // middle top
            mb: false, // middle bottom
            ml: false, // middle left
            mr: false, // middle right
            tl: false, // top left
            tr: false, // top right
            bl: false, // bottom left
            br: false, // bottom right
          });
          canvas.requestRenderAll();
        }
      });

      canvas.on("object:modified", handleObjectModified);
      canvas.on("object:scaling", handleObjectScaling);
      // Set event listeners for selection events

      dispatch(getVenueDetail(id ?? ""));
      // Clean-up function to remove event listeners
      return () => {
        canvas.off("object:modified", handleObjectModified);
        canvas.off("object:scaling", handleObjectScaling);
      };
    }
  }, [canvas, id, dispatch, savedCompleted]);

  useEffect(() => {
    if (canvas) {
      const handleDrop = async (e) => {
        e.preventDefault();
        console.log(e);
        if (!canvas || !containerRef.current) return;
        const objectType = e.dataTransfer.getData("objectType");
        if (
          objectType === "image" &&
          (editMode || createNew) &&
          !overlapDetected
        ) {
          const imageUrl = e.dataTransfer.getData("imageUrl");
          const beachObjType = e.dataTransfer.getData("beachObjType");
          const customWidth = 1;
          const customHeight = 1;

          const canvasRect = canvasRef.current.getBoundingClientRect();
          const elementType = e.dataTransfer.getData("elementType");
          const offsetX = elementType === "beach" ? 23 : 43;
          const offsetY = elementType === "beach" ? 27 : 57;

          const left = Math.max(e.clientX - canvasRect.left - offsetX, 0);
          const top = Math.max(e.clientY - canvasRect.top - offsetY, 0);

          const loadImage = (url) => {
            return new Promise((resolve, reject) => {
              fabric.Image.fromURL(url, (img) => {
                if (img) {
                  resolve(img);
                } else {
                  reject(new Error("Failed to load image"));
                }
              });
            });
          };

          try {
            const img = await loadImage(imageUrl);
            img.toObject = ((toObject) => {
              return function () {
                return fabric.util.object.extend(toObject.call(this), {
                  id: uuidv4(),
                  beachType: beachObjType,
                });
              };
            })(img.toObject);

            img.set({
              left,
              top,
              scaleX: customWidth,
              scaleY: customHeight,
              lockRotation: true,
              selectable: true,
              hoverCursor: "pointer",
              centeredScaling: false,
            });

            // Disable center scaling and restrict scaling to corners
            img.setControlVisible("mt", false);
            img.setControlVisible("mb", false);
            img.setControlVisible("ml", false);
            img.setControlVisible("mr", false);
            canvas.add(img);
            snapObjectToGrid(img);
            // canvas.requestRenderAll();
            updateCanvasSize(false);
          } catch (error) {
            console.error("Error loading image:", error);
          }
        }
      };

      const handleDragOver = (e) => {
        e.preventDefault();
      };

      if (containerRef.current) {
        containerRef.current.addEventListener("drop", handleDrop);
        containerRef.current.addEventListener("dragover", handleDragOver);
      }

      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("drop", handleDrop);
          containerRef.current.removeEventListener("dragover", handleDragOver);
        }
      };
    }
  }, [canvas, editMode, createNew, overlapDetected]);

  useEffect(() => {
    if (venueInfo && Object.keys(venueInfo).length > 0) {
      setEditMode(false);
      setCreateNew(false);
      loadCanvas(true);
    }
  }, [venueInfo]);

  useEffect(() => {
    if (!hasPermission("CREATE_VENUE_LAYOUT")) {
      navigate("/");
    }
    // Keydown event listener
    const handleKeydown = (event) => {
      if (event.key === "Delete") {
        event.preventDefault();
        handleRemoveClick();
      }
    };
    document.addEventListener("keydown", handleKeydown);
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  });

  const handleDragStart = (e, params, width, height, type) => {
    // console.log("handleDragStart :", e)
    // console.log("params :", params)
    // console.log("width :", width)
    // console.log("height :", height)
    e.dataTransfer.setData("imageUrl", params.imageUrl);
    e.dataTransfer.setData("beachObjType", params.beachObjType);
    e.dataTransfer.setData("objectType", "image");
    e.dataTransfer.setData("elementType", type);

    // Set the drag image with custom dimensions
    const img = new Image();
    img.src = params.imageUrl;
    img.width = width;
    img.height = height;
    e.dataTransfer.setDragImage(img, width / 2, height / 2);
  };

  // Event handler for 'selection:cleared' event
  const handleSelectionCleared = () => {
    console.log("Selection clear");
    // console.log("previousGroup : ", previousGroup);
    if (canvas && previousGroup) {
      const group = previousGroup;
      const objectsInGroup = group.getObjects();
      // Ungrouping process
      group._restoreObjectsState(); // Restore objects state before grouping
      canvas.remove(group); // Remove group from canvas
      objectsInGroup.forEach((obj) => canvas.add(obj)); // Add objects back to canvas
      // canvas.requestRenderAll(); // Render canvas with updated objects
      // Clear previousGroup state after handling
      setPreviousGroup(null);
      const filteredObjects = canvas
        .getObjects()
        .filter((item) => item.type === "image");
      filteredObjects.forEach((obj) => {
        obj.selectable = true;
      });

      // const filteredObjects1 = canvas.getObjects().filter(item => item.type !== 'line');
      // console.log(JSON.stringify(filteredObjects1));
      canvas.requestRenderAll(); // Render canvas with updated objects
    }
  };

  const handleObjectModified = (e) => {
    console.log("Object update to canvas");
    const obj = e.target;
    if (obj) {
      snapObjectToGrid(obj);
    }
    updateCanvasSize(false);
  };

  const handleObjectScaling = (e) => {
    const activeObject = e.target;
    if (activeObject) {
      if (activeObject.width * activeObject.scaleX < gridSize) {
        activeObject.scaleX = gridSize / activeObject.width;
      }
      if (activeObject.height * activeObject.scaleY < gridSize) {
        activeObject.scaleY = gridSize / activeObject.height;
      }
      activeObject.setCoords(); // Update the coordinates
    }
  };

  const openAddBeachObjModal = (image: IconGalleryImage) => {
    if ((!editMode || overlapDetected) && !createNew) return;
    setOpenBeachObjModal(true);
    setSelectedImage(image);
  };

  const handleConfirmModal = (data: AddBeachObjModalData) => {
    addMultipleImages(data);
    setOpenBeachObjModal(false);
  };

  const handleCloseModal = () => {
    setOpenBeachObjModal(false);
    setOpenRemoveModal(false);
  };

  // Remove action
  const handleRemoveClick = () => {
    if (!editMode || !canvas) return;
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      setOpenRemoveModal(true);
    } else {
      console.log("No Active Object");
    }
  };

  const handleRemoveConfirmModal = () => {
    setOpenRemoveModal(false);
    if (!canvas) return;
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      canvas.remove(activeObject);
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
  };

  const createCanvas = () => {
    if (canvas) {
      canvas.clear();
      setCreateNew(true);
      setEditMode(true);
      canvas.setWidth(1300);
      canvas.setHeight(560);
      canvas.requestRenderAll();
      drawGrid();
    }
  };

  const editCanvas = () => {
    if (canvas) {
      setEditMode(true);
      canvas.clear();
      loadCanvas(false);
    }
  };

  const cancelCanvas = () => {
    if (canvas) {
      canvas.clear();
      setEditMode(false);
      setCreateNew(false);
      setOverlapDetected(false);
      loadCanvas(true);
    }
  };

  const clearCanvas = () => {
    if (canvas) {
      canvas.clear();
      canvas.setWidth(1300);
      canvas.setHeight(560);
      canvas.requestRenderAll();
      drawGrid();
    }
  };

  const updateCanvasSize = (view) => {
    console.log("updateCanvasSize calling ...  view ", view);
    if (!canvas) {
      return;
    }
    const objects = canvas.getObjects();
    // console.log("updateCanvasSize objects.length : ", objects.length);
    if (objects.length === 0) return;

    let maxX = 0;
    let maxY = 0;

    const filteredObjects = canvas
      .getObjects()
      .filter((item) => item.type === "image");
    filteredObjects.forEach((obj) => {
      // objects.forEach((obj) => {
      const bounds = obj.getBoundingRect();
      maxX = Math.max(maxX, bounds.left + bounds.width);
      maxY = Math.max(maxY, bounds.top + bounds.height);
    });
    canvas.setWidth(maxX + 1100);
    canvas.setHeight(maxY + 600);
    canvas.requestRenderAll();
    if (!view) {
      drawGrid();
    }
  };

  const checkForOverlapsAndChangeColor = () => {
    if (!canvas) return;
    console.log("Calling...");
    let overlap = false;
    setOverlapDetected(overlap);
    let overlappedObjs: fabric.Object[] = []; // Declare as array of fabric objects
    // Iterate through canvas objects to find overlaps
    const filteredObjects = canvas
      .getObjects()
      .filter((item) => item.type === "image");
    filteredObjects.forEach((obj) => {
      // canvas.forEachObject((obj) => {
      const newLeft = Math.round(obj.left / gridSize) * gridSize;
      const newTop = Math.round(obj.top / gridSize) * gridSize;

      // Calculate right and bottom positions relative to the left/top
      const rightDiff = obj.left + obj.width * obj.scaleX - newLeft;
      const bottomDiff = obj.top + obj.height * obj.scaleY - newTop;
      const newWidth = Math.round(rightDiff / gridSize) * gridSize;
      const newHeight = Math.round(bottomDiff / gridSize) * gridSize;
      // Check overlaps with other objects
      const filteredObjects_others = canvas
        .getObjects()
        .filter((item) => item.type === "image");
      filteredObjects_others.forEach((otherObj) => {
        // canvas.forEachObject((otherObj) => {
        if (otherObj !== obj) {
          const otherBoundingRect = otherObj.getBoundingRect(true);
          // Check for overlap using simplified conditions
          const overlapX =
            newLeft < otherBoundingRect.left + otherBoundingRect.width &&
            newLeft + newWidth > otherBoundingRect.left;
          const overlapY =
            newTop < otherBoundingRect.top + otherBoundingRect.height &&
            newTop + newHeight > otherBoundingRect.top;

          if (overlapX && overlapY) {
            overlap = true;
            setOverlapDetected(overlap);
            overlappedObjs.push(otherObj);
          }
        }
      });

      obj.set({
        left: newLeft,
        top: newTop,
        scaleX: newWidth / obj.width,
        scaleY: newHeight / obj.height,
      });
      obj.setCoords(); // Update object coordinates
      // canvas.renderAll(); // Render canvas to apply changes
    });

    if (overlap) {
      overlappedObjs.forEach((obj) => {
        obj.set({
          stroke: "#FFA500", // Red stroke color for overlap
          strokeWidth: 2,
        });
        obj.setCoords(); // Update object coordinates
      });
    }
    canvas.renderAll(); // Render canvas to apply changes
    return overlap;
  };

  const snapObjectToGrid = (obj) => {
    if (!canvas) return;
    const newLeft = Math.round(obj.left / gridSize) * gridSize;
    const newTop = Math.round(obj.top / gridSize) * gridSize;
    // Calculate right and bottom positions relative to the left/top
    const rightDiff = obj.left + obj.width * obj.scaleX - newLeft;
    const bottomDiff = obj.top + obj.height * obj.scaleY - newTop;
    const newWidth = Math.round(rightDiff / gridSize) * gridSize;
    const newHeight = Math.round(bottomDiff / gridSize) * gridSize;
    let overlap = false;
    // Iterate through canvas objects to find overlaps
    // Assuming 'canvas' is your fabric canvas object
    const filteredObjects = canvas
      .getObjects()
      .filter((item) => item.type === "image");

    filteredObjects.forEach((otherObj, index) => {
      // canvas.forEachObject((otherObj) => {
      //console.log("INDEX", index);
      if (otherObj !== obj) {
        otherObj.set({
          stroke: "#155868", // Change color to red #044d5e
          strokeWidth: 0, // Add stroke width to make the border visible
        });
        const otherBoundingRect = otherObj.getBoundingRect(true);
        // Check for overlap using simplified conditions
        const overlapX =
          newLeft < otherBoundingRect.left + otherBoundingRect.width &&
          newLeft + newWidth > otherBoundingRect.left;
        const overlapY =
          newTop < otherBoundingRect.top + otherBoundingRect.height &&
          newTop + newHeight > otherBoundingRect.top;

        if (
          overlapX &&
          overlapY &&
          otherObj.type == "image" &&
          obj.type == "image"
        ) {
          // console.log(JSON.stringify(otherBoundingRect));
          overlap = true;
          otherObj.set({
            stroke: "#FFA500", // Highlight stroke color for overlap
            strokeWidth: 2, // Stroke width for highlighting
          });
        }
      }
      return false;
    });

    setOverlapDetected(overlap);
    obj.set({
      left: newLeft < 0 ? 0 : newLeft,
      top: newTop < 0 ? 0 : newTop,
      scaleX: newWidth / obj.width,
      scaleY: newHeight / obj.height,
      stroke: overlap ? "#f60303" : "#155868",
      strokeWidth: overlap ? 2 : 0,
    });
    obj.setCoords(); // Update object coordinates
    disableSelections(obj, !overlap);
    canvas.renderAll(); // Render canvas to apply changes
  };

  const disableSelections = (activeObject, selectable) => {
    if (!canvas) return;
    const filteredObjects = canvas
      .getObjects()
      .filter((item) => item.type === "image");
    filteredObjects.forEach((obj) => {
      obj.selectable = !selectable && obj == activeObject ? true : selectable;
    });
  };

  const drawGrid = () => {
    if (!canvas) return;
    const canvasWidth = canvas.getWidth();
    const canvasHeight = canvas.getHeight();

    //console.log("canvasWidth:", canvasWidth);
    //console.log("canvasHeight:", canvasHeight);

    // Check if horizontal and vertical grid lines already exist
    const lines = canvas.getObjects("line");
    let horizontalLinesExist = lines.some(
      (line) => line.get("x1") === 0 && line.get("x2") === canvasWidth
    );
    let verticalLinesExist = lines.some(
      (line) => line.get("y1") === 0 && line.get("y2") === canvasHeight
    );

    // If both horizontal and vertical lines exist, skip redraw
    if (horizontalLinesExist && verticalLinesExist) {
      //console.log("Grid lines already exist. Skipping redraw.");
      return;
    }

    // Helper function to check if a label exists at specified coordinates
    function labelExistsAt(x, y) {
      const texts = canvas.getObjects("text");
      return texts.some((text) => text.left === x && text.top === y);
    }

    // Track if any new elements are added
    let elementsAdded = false;

    // Draw horizontal grid lines and labels
    for (let i = 0; i <= canvasHeight; i += gridSize) {
      if (!lineExistsAt(0, i, canvasWidth, i)) {
        const line = new fabric.Line([0, i, canvasWidth, i], {
          stroke: "#ccc",
          selectable: false,
        });
        canvas.add(line);
        canvas.sendToBack(line);
        elementsAdded = true;
      }

      if (!labelExistsAt(0, i)) {
        const rowNumberLabel = new fabric.Text((i / gridSize + 1).toString(), {
          left: 0,
          top: i,
          fontSize: 12,
          selectable: false,
        });
        canvas.add(rowNumberLabel);
        elementsAdded = true;
      }
    }

    // Draw vertical grid lines and labels
    for (let i = 0; i <= canvasWidth; i += gridSize) {
      if (!lineExistsAt(i, 0, i, canvasHeight)) {
        const line = new fabric.Line([i, 0, i, canvasHeight], {
          stroke: "#ccc",
          selectable: false,
        });
        canvas.add(line);
        canvas.sendToBack(line);
        elementsAdded = true;
      }

      if (!labelExistsAt(i, 0)) {
        const columnNumberLabel = new fabric.Text(
          (i / gridSize + 1).toString(),
          {
            left: i,
            top: 0,
            fontSize: 12,
            selectable: false,
          }
        );
        canvas.add(columnNumberLabel);
        elementsAdded = true;
      }
    }

    // Render canvas only if new elements were added
    if (elementsAdded) {
      canvas.requestRenderAll();
    }

    // Helper function to check if a line exists at specified coordinates
    function lineExistsAt(x1, y1, x2, y2) {
      return lines.some(
        (line) =>
          line.get("x1") === x1 &&
          line.get("y1") === y1 &&
          line.get("x2") === x2 &&
          line.get("y2") === y2
      );
    }
  };

  const addMultipleImages = async (data: AddBeachObjModalData) => {
    if (!canvas) return;
    const numCols = data.perRow;
    const numRows = Math.round(data.count / numCols);

    //console.log("numCols :", numCols);
    //console.log("numRows :", numRows);

    let previousBound: fabric.Object | null = null;
    for (let i = 0; i < data.count; i++) {
      let row, col;
      if (data.direction === AppendDirection.Vertical) {
        row = Math.floor(i / numCols);
        col = i % numCols;
      } else {
        col = Math.floor(i / numRows);
        row = i % numRows;
      }
      const left = col * gridSize;
      const top = previousBound
        ? previousBound.top + previousBound.height
        : row * gridSize;

      const addImage = (
        imageUrl: string,
        left: number,
        top: number,
        beachObjType: string,
        customWidth: number,
        customHeight: number
      ): Promise<fabric.Object> => {
        return new Promise((resolve) => {
          fabric.Image.fromURL(imageUrl, (img) => {
            img.toObject = (function (toObject) {
              return function () {
                return fabric.util.object.extend(toObject.call(this), {
                  id: uuidv4(),
                  beachType: beachObjType,
                });
              };
            })(img.toObject);
            //console.log("top :", top);
            img.set({
              left,
              top,
              scaleX: customWidth,
              scaleY: customHeight,
              // scaleX: width / img.width!,
              // scaleY: height / img.height!,
              lockRotation: true,
              selectable: true, // Change to true if you want it selectable initially
              hoverCursor: "pointer",
            });

            // Disable center scaling and restrict scaling to corners
            img.setControlVisible("mt", false);
            img.setControlVisible("mb", false);
            img.setControlVisible("ml", false);
            img.setControlVisible("mr", false);
            img.set({
              centeredScaling: false, // Ensure center scaling is disabled
            });

            canvas.add(img);
            // canvas.requestRenderAll();
            previousBound = img.getBoundingRect(true);
            resolve(img);
          });
        });
      };
      const imageUrl = data.image.imageUrl;
      const beachObjType = data.image.beachObjType;
      const customWidth = data.width; //1;
      const customHeight = data.height; //1;

      await addImage(
        imageUrl,
        left,
        top,
        beachObjType,
        customWidth,
        customHeight
      );
    }
    canvas.requestRenderAll();
    updateCanvasSize(false);
    checkForOverlapsAndChangeColor();
  };

  const exportLayout = () => {
    if (!canvas || checkForOverlapsAndChangeColor()) return;
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    canvas.forEachObject((obj) => {
      if (obj.type === "image") {
        const boundingRect = obj.getBoundingRect();
        minX = Math.min(minX, boundingRect.left);
        minY = Math.min(minY, boundingRect.top);
        maxX = Math.max(maxX, boundingRect.left + boundingRect.width);
        maxY = Math.max(maxY, boundingRect.top + boundingRect.height);
      }
    });

    const width = maxX - minX;
    const height = maxY - minY;

    const elements = [];

    canvas.forEachObject((obj) => {
      if (obj.type === "image") {
        const img = fabric.util.object.clone(obj);
        img.set({ left: img.left - minX, top: img.top - minY });
        // console.log("img >>>>>>>>>>>>>>>" , JSON.stringify(img));
        let customField = {};
        if (img.id) {
          customField = img;
          // console.log("Already img >>>>>>>>>>>>>>>", customField.beachType);
        } else {
          // console.log(" Image >>>>>>>>", JSON.stringify(img));
          customField = JSON.parse(JSON.stringify(img));
          // console.log("New img >>>>>>>>>>>>>>>", customField.beachType);
        }

        // console.log('obj' , obj)
        // console.log('img.beachType' , img)
        const imageProperties = {
          startPositionX: Math.round(Number(img.left / gridSize)),
          startPositionY: Math.round(Number(img.top / gridSize)),
          width:
            Math.round(Math.round(img.width * img.scaleY) / gridSize) < 1
              ? 1
              : Math.round(Math.round(img.width * img.scaleY) / gridSize),
          height:
            Math.round(Math.round(img.height * img.scaleX) / gridSize) < 1
              ? 1
              : Math.round(Math.round(img.height * img.scaleX) / gridSize),
          type: customField.beachType.toUpperCase(),
          id: customField.id,
        };
        elements.push(imageProperties);
      }
      if (obj.type === "line" || obj.type === "text") {
        canvas.remove(obj);
      }
    });

    const result = {
      venueId: id,
      canvas: {
        content: JSON.stringify(
          canvas.toJSON(["beachType", "abstractWidth", "abstractHeight", "id"])
        ),
        gridCellSize: gridSize,
        columns:
          Math.round(width / gridSize) === -Infinity
            ? 0
            : Math.round(width / gridSize),
        rows:
          Math.round(height / gridSize) === -Infinity
            ? 0
            : Math.round(height / gridSize),
      },
      elements,
    };

    // console.log("Elements Length:", elements.length);
    // console.log("Elements :", JSON.stringify(elements));
    // return;

    // if (elements.length != 0) {
    //   canvas.forEachObject((obj) => {
    //     if (obj.type === "line" || obj.type === "text") {
    //       canvas.remove(obj);
    //     }
    //   });
    //   // dispatch(saveLayout(result));
    // }
    setEditMode(false);
    setCreateNew(false);
    dispatch(saveLayout(result));
  };

  const loadCanvas = (view) => {
    if (!canvas) return;

    dispatch(LayoutActions.loadingState(true));

    try {
      // Extend fabric.Object to include custom properties
      fabric.Object.prototype.toObject = (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            id: this.id,
            beachType: this.beachType,
          });
        };
      })(fabric.Object.prototype.toObject);

      // console.log("actual content :", venueInfo.content);
      // Parse the content
      const data = JSON.parse(venueInfo.content);

      // Function to replace URL
      function replaceUrl(obj, oldUrl, newUrl) {
        if (typeof obj === "object" && obj !== null) {
          for (const key in obj) {
            if (typeof obj[key] === "string" && obj[key].includes(oldUrl)) {
              obj[key] = obj[key].replace(oldUrl, newUrl);
            } else if (typeof obj[key] === "object") {
              replaceUrl(obj[key], oldUrl, newUrl);
            }
          }
        }
      }

      replaceUrl(data, "http://localhost:1948", window.location.origin);
      // You can convert it back to JSON string if needed
      const updatedContent = JSON.stringify(data);
      // console.log("updatedContent :", updatedContent);

      // Load the canvas from JSON and set custom properties
      canvas.loadFromJSON(updatedContent, () => {
        // console.log("loadFromJSON handling...")
        setCanvasObject(view);
        canvas.requestRenderAll();
        updateCanvasSize(view);

        dispatch(LayoutActions.loadingState(false));
      });
    } catch (e) {
      console.log("Error " + e);
      dispatch(LayoutActions.loadingState(false));
    }
  };

  const setCanvasObject = (view) => {
    // console.log("setCanvasObject handling...");
    if (!canvas) return;
    const objects = canvas.getObjects();
    objects.forEach((obj, index) => {
      if (obj.type === "image") {
        // const parsedUrl = new URL(obj.getSrc());
        // let baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
        // // console.log("baseUrl : ", baseUrl);
        // // console.log("current_baseUrl : ", window.location.origin);
        // const newSrc = obj.getSrc().replace(baseUrl, window.location.origin);
        // // console.log("newSrc : ", newSrc);
        // const imgElement = new Image();
        // imgElement.src = newSrc;

        // imgElement.onload = () => {
        //   obj.setElement(imgElement);
        //   // console.log('Sucess:', newSrc);
        //   canvas.requestRenderAll();
        // };
        // imgElement.onerror = () => {
        //   console.error("Failed to load new image:", newSrc);
        // };
        // console.log(JSON.stringify(obj));
        obj.lockRotation = true;
        obj.lockScalingY = view;
        obj.lockScalingX = view;
        obj.lockMovementX = view;
        obj.lockMovementY = view;
        obj.selectable = !view;
        obj.hoverCursor = "pointer";
        // Disable center scaling and restrict scaling to corners
        obj.setControlVisible("mt", false);
        obj.setControlVisible("mb", false);
        obj.setControlVisible("ml", false);
        obj.setControlVisible("mr", false);
        obj.set({
          centeredScaling: false, // Ensure center scaling is disabled
        });
      } else {
        obj.lockRotation = true;
        obj.lockScalingY = true;
        obj.lockScalingX = true;
        obj.lockMovementX = true;
        obj.lockMovementY = true;
        obj.selectable = false;
        // Disable center scaling and restrict scaling to corners
        obj.setControlVisible("mt", false);
        obj.setControlVisible("mb", false);
        obj.setControlVisible("ml", false);
        obj.setControlVisible("mr", false);
        obj.set({
          centeredScaling: false, // Ensure center scaling is disabled
        });
      }
      // console.log("Object Data:", JSON.stringify(obj))
    });
  };

  const loadUpIcons = () => {
    // Load up beach objects
    const objects: IconGalleryImage[] = [];
    // Example code to load objects, replace with your actual logic
    for (const objectType in BeachObject) {
      if (Object.prototype.hasOwnProperty.call(BeachObject, objectType)) {
        objects.push({
          imageUrl: `/assets/layout-builder/objects/Type=${objectType}, State=Default.svg`,
          beachObjType: BeachObject[objectType],
        });
      }
    }
    setBeachObjects(objects);

    // Load up beach zones
    const zones: IconGalleryImage[] = [];
    // Example code to load zones, replace with your actual logic
    for (const objectType in BeachZones) {
      if (Object.prototype.hasOwnProperty.call(BeachZones, objectType)) {
        zones.push({
          imageUrl: `/assets/layout-builder/zones/${objectType}.svg`,
          beachObjType: BeachZones[objectType],
        });
      }
    }
    setBeachZones(zones);
  };

  return (
    <div>
      <Box sx={{ p: 3, position: "relative" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Box sx={{ position: "relative" }}>
              <Card className="canvas-card" sx={{ p: 2 }}>
                <div ref={containerRef}>
                  <canvas ref={canvasRef}></canvas>
                </div>
              </Card>

              {loading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "#ffffffe8",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              )}

              {/* skipped && Object.keys(venueInfo).length == 0  */}
              {!loading &&
                venueInfo &&
                Object.keys(venueInfo).length == 0 &&
                !createNew && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      backgroundColor: "#fff",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "700",
                        color: "##06191e",
                      }}
                    >
                      No Layout found
                    </Typography>

                    <p>Please change your selected location.</p>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Button
                        variant="contained"
                        onClick={createCanvas}
                        disableElevation
                        fullWidth
                      >
                        Create
                      </Button>
                    </Grid>
                  </Box>
                )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Card
              // skipped
              //  || (venueInfo && Object.keys(venueInfo).length == 0) && !createNew
              sx={{
                p: 2,
                opacity:
                  loading ||
                  (venueInfo &&
                    Object.keys(venueInfo).length == 0 &&
                    !createNew)
                    ? "0.3"
                    : "1.0",
                pointerEvents:
                  loading ||
                  (venueInfo &&
                    Object.keys(venueInfo).length == 0 &&
                    !createNew)
                    ? "none"
                    : "all",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: "20px",
                }}
              >
                {(editMode || createNew) && (
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      variant="contained"
                      onClick={exportLayout}
                      disabled={!editMode || overlapDetected}
                      disableElevation
                      fullWidth
                    >
                      Save
                    </Button>
                  </Grid>
                )}

                {(editMode || createNew) && (
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      variant="outlined"
                      onClick={cancelCanvas}
                      disabled={!editMode}
                      disableElevation
                      fullWidth
                      color="success"
                    >
                      Reset
                    </Button>
                  </Grid>
                )}

                {!editMode &&
                  venueInfo &&
                  venueInfo &&
                  Object.keys(venueInfo).length != 0 && (
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Button
                        variant="contained"
                        onClick={editCanvas}
                        disableElevation
                        fullWidth
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </Grid>
                  )}

                {(editMode || createNew) && (
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      variant="outlined"
                      disableElevation
                      fullWidth
                      onClick={handleRemoveClick}
                      disabled={!editMode || overlapDetected}
                      color="error"
                    >
                      Remove Item
                    </Button>
                  </Grid>
                )}

                {(editMode || createNew) && (
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      variant="outlined"
                      onClick={clearCanvas}
                      disableElevation
                      fullWidth
                      color="error"
                    >
                      Clear All
                    </Button>
                  </Grid>
                )}
              </Grid>

              <Divider
                sx={{
                  marginBottom: "20px",
                }}
              />

              <IconGalleryComponent
                draggable
                onDragStart={handleDragStart}
                images={beachZones}
                onImageSelected={openAddBeachObjModal}
                width={gridSize * 3}
                height={gridSize * 3}
                type="zone"
              />
              <br />
              <IconGalleryComponent
                draggable
                onDragStart={handleDragStart}
                images={beachObjects}
                columnsInMd={3}
                onImageSelected={openAddBeachObjModal}
                width={gridSize}
                height={gridSize}
                type="beach"
              />
            </Card>
          </Grid>
        </Grid>

        {selectedImage && (
          <AddBeachObjectModal
            isOpen={openBeachObjModal}
            image={selectedImage}
            onClose={handleCloseModal}
            onConfirm={handleConfirmModal}
          />
        )}

        <RemoveIconModal
          isOpen={openRemoveModal}
          onClose={handleCloseModal}
          onConfirm={handleRemoveConfirmModal}
        />
      </Box>
    </div>
  );
};

export default BeachMapBuilder;
