import api from "./auth";

const submitActivation = (data: any) => {
  return api.post("/employees/activate", data);
};

const accountApi = {
  submitActivation,
};

export default accountApi;
