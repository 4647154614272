import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material';

export const MobileAppDownload = () => {
    const [debugMode, setDebugMode] = useState<boolean>(false);

    useEffect(() => {
        const storedDebugMode = localStorage.getItem('enable_debug_mode');
        if (storedDebugMode) {
            setDebugMode(storedDebugMode === 'true');
        }
    }, []);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setDebugMode(isChecked);
        localStorage.setItem('enable_debug_mode', isChecked.toString());
        window.location.reload();
    };

    const downloadUrl = `https://myspot-business-app.s3.eu-west-1.amazonaws.com/MySpotBusiness-${import.meta.env.VITE_APP_ENVIRONMENT}.apk`;

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" height="100vh" paddingTop={4}>
            <Box display="flex" justifyContent="center" width="100%">
                <Typography variant="h6" gutterBottom>
                    Натиснете на иконата от долу за изтеглите приложението на вашето устройство
                </Typography>
            </Box>
            <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                <img src="/assets/google-play-badge.svg" alt="Download on Google Play" />
            </a>
            <FormControlLabel
                control={<Checkbox checked={debugMode} onChange={handleCheckboxChange} />}
                label="Активирай режим на отстраняване на грешки"
            />
        </Box>
    );
};
