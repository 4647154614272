import { SET_END_DATE, SET_LOCATION_RESERVATIONS, SET_START_DATE, ADD_STATUS, REMOVE_STATUS } from "./types";

const addLocationReservations = (payload: any) => ({
    type: SET_LOCATION_RESERVATIONS,
    payload
});

const setStartDate = (payload: any) => ({
    type: SET_START_DATE,
    payload
});

const setEndDate = (payload: any) => ({
    type: SET_END_DATE,
    payload
});

const addStatus = (payload: any) => ({
    type: ADD_STATUS,
    payload
});

const removeStatus = (payload: any) => ({
    type: REMOVE_STATUS,
    payload
});

export const ReportsActions = {
    addLocationReservations,
    setStartDate,
    setEndDate,
    addStatus,
    removeStatus
}