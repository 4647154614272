import api from "./auth";
import {Dayjs} from "dayjs";

const reservedSpots = (venueId: string, startDate: any, endDate: any) => {
  return api.get("/spots/reserved", {
    params: {
      venueId,
      startDate,
      endDate,
    },
  });
};

const initializeSpot = (spotId: string, formData: any) => {
  return api.post(`spots/${spotId}/initialize`, formData);
};

const reLinkSpot = (locationId: string, spotId: string, plateId: string) => {
  return api.patch(`spots/${spotId}/location/${locationId}/relink`, {
    plateId,
  });
};

const updateSpot = (spotId: string, data: any) => {
  return api.patch(`spots/${spotId}`, data);
};

// disable spot
const createSchedule = (
  locationId: string,
  spotIds: Array<string>,
  dates: Array<string>
) => {
  return api.post("disable-schedules", {
    locationId,
    spotIds,
    dates,
  });
};

// get spot schedules
const getSpotSchedules = (spotId: string) => {
  return api.get(`disable-schedules/spot/${spotId}`);
};

// enable spot
const removeSchedule = (scheduleId: string) => {
  return api.delete(`disable-schedules/${scheduleId}`);
};

const checkSpotAvailability = (
  afterDate: string,
  beforeDate: string,
  spotId?: string,
  plateId?: string
) => {
  return api.getCheck(
    `spots/check-availability?spotId=${spotId}&plateId=${plateId}&after=${afterDate}&before=${beforeDate}`
  );
};

const settingsSpot = (spotId: string) => {
  return api.get(`spots/${spotId}/settings`);
};

const createSpotNote = (spotId: string, body: { venueId: string, content: string, forDay: string }) => {
  return api.post(`spots/${spotId}/notes`, body);
}

const spotNotes = (spotId: string, day: Dayjs) => {
  return api.get(`spots/${spotId}/notes?forDay=${day}`);
};

const accociateSpot = (plateId: string, body: any) => {
  return api.post(`plates/${plateId}/associate`, body);
};

const disassociateSpot = (plateId: string) => {
  return api.post(`plates/${plateId}/disassociate`);
};

const spotsAPI = {
  reservedSpots,
  initializeSpot,
  reLinkSpot,
  updateSpot,
  checkSpotAvailability,
  createSpotNote,
  spotNotes,
  createSchedule,
  getSpotSchedules,
  removeSchedule,
  settingsSpot,
  accociateSpot,
  disassociateSpot,
};
export default spotsAPI;
