import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";

import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
  FieldError,
  UseFormSetError,
  UseFormClearErrors,
} from "react-hook-form";
import {
  AppendDirection,
  AddBeachObjModalData,
  IconGalleryImage,
} from "../dto/types";

interface AddBeachObjectModalProps {
  isOpen: boolean;
  image: IconGalleryImage;
  onClose: () => void;
  onConfirm: (data: AddBeachObjModalData) => void;
}

const AddBeachObjectModal: React.FC<AddBeachObjectModalProps> = ({
  isOpen,
  image,
  onClose,
  onConfirm,
}) => {
  const { handleSubmit, control, watch, setError, clearErrors, reset } =
    useForm<AddBeachObjModalData>({
      defaultValues: {
        count: 1,
        perRow: 1,
        width: 1,
        height: 1,
        direction: AppendDirection.Horizontal,
        image: image,
      },
    });

  const count = watch("count");
  const perRow = watch("perRow");

  useEffect(() => {
    if (parseInt(perRow) > parseInt(count)) {
      console.log("Per Row " , perRow);
      console.log("count " , count);
      setError("perRow", {
        type: "manual",
        message: "Per Row must not be greater than Count",
      });
    } else {
      clearErrors("perRow");
    }
  }, [count, perRow, setError, clearErrors]);

  const onSubmit: SubmitHandler<AddBeachObjModalData> = (data) => {
    onConfirm({ ...data, image });
    reset();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add Icons</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <InputLabel>Beach Object: {image.beachObjType}</InputLabel>
          </div>
          <Controller
            name="count"
            control={control}
            rules={{
              required: "Count is required",
              min: { value: 1, message: "Count must be at least 1" },
            }}
            render={({
              field,
              fieldState,
            }: {
              field: FieldValues;
              fieldState: FieldError;
            }) => (
              <TextField
                {...field}
                label="Count"
                type="number"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                onInput={(e) => {
                  const input = e.target;
                  if (input.value.length == 1 && input.value == 0) {
                    input.value = '';
                  }else if (input.value.length > 2) {
                    input.value = input.value.slice(0, 2);
                  }
                }}
              />
            )}
          />
          <Controller
            name="perRow"
            control={control}
            rules={{
              required: "Per Row is required",
              min: { value: 1, message: "Per Row must be at least 1" },
            }}
            render={({
              field,
              fieldState,
            }: {
              field: FieldValues;
              fieldState: FieldError;
            }) => (
              <TextField
                {...field}
                label="Per Row"
                type="number"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                onInput={(e) => {
                  const input = e.target;
                  if (input.value.length == 1 && input.value == 0) {
                    input.value = '';
                  }else if (input.value.length > 2) {
                    input.value = input.value.slice(0, 2);
                  }
                }}
              />
            )}
          />
          <Controller
            name="width"
            control={control}
            rules={{
              required: "Width is required",
              min: { value: 1, message: "Width must be at least 1" },
            }}
            render={({
              field,
              fieldState,
            }: {
              field: FieldValues;
              fieldState: FieldError;
            }) => (
              <TextField
                {...field}
                label="Width"
                type="number"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                onInput={(e) => {
                  const input = e.target;
                  if (input.value.length == 1 && input.value == 0) {
                    input.value = '';
                  }else if (input.value.length > 2) {
                    input.value = input.value.slice(0, 2);
                  }
                }}

              />
            )}
          />
          <Controller
            name="height"
            control={control}
            rules={{
              required: "Height is required",
              min: { value: 1, message: "Height must be at least 1" },
            }}
            render={({
              field,
              fieldState,
            }: {
              field: FieldValues;
              fieldState: FieldError;
            }) => (
              <TextField
                {...field}
                label="Height"
                type="number"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                onInput={(e) => {
                  const input = e.target;
                  if (input.value.length == 1 && input.value == 0) {
                    input.value = '';
                  }else if (input.value.length > 2) {
                    input.value = input.value.slice(0, 2);
                  }
                }}
              />
            )}
          />
          <Controller
            name="direction"
            control={control}
            render={({ field }: { field: FieldValues }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel>Append Direction</InputLabel>
                <Select {...field} label="Append Direction">
                  <MenuItem value={AppendDirection.Horizontal}>
                    Horizontal
                  </MenuItem>
                  <MenuItem value={AppendDirection.Vertical}>Vertical</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              Close
            </Button>
            <Button type="submit" color="primary" disabled={parseInt(perRow) > parseInt(count)}>
              Confirm
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddBeachObjectModal;
