import produce from 'immer';

import {
    ADD_LOCATIONS,
    ADD_ACTIVE_LOCATION,
    CHANGE_ACTIVE_LOCATION,
    Location,
    ADD_LOCATION_INFORMATION
} from "./types";
import { ACTIVATE_LOCATION } from '../../views/dashboard/beach-location/types';

interface ActiveLocation {
    id: string | null;
    information: any | null;
}
interface InitialState {
    list: Array<Location>;
    active: ActiveLocation;
}

const initialState: InitialState = <InitialState>({
    list: [],
    active: {
        id: null,
        information: null
    }
});

export default function (state = initialState, action: any) {
    const { type, payload } = action;
    return produce(state, (draftState) => {
        switch (type) {
            case ADD_LOCATIONS: {
                draftState.list = action.payload;
                if (action.payload.length === 0) {
                    draftState.active.id = null;
                }
                break;
                // return state.setIn(['locations', 'list'], payload);
            }
            case CHANGE_ACTIVE_LOCATION: {
                draftState.active.id = payload.id;
                localStorage.setItem('lastSelectedLocation', JSON.stringify(payload));
                break;
            }
            case ADD_ACTIVE_LOCATION: {
                // return state.setIn(['locations', 'active'], payload);
                break;
            }
            case ADD_LOCATION_INFORMATION: {
                draftState.active.information = payload;
                break;
            }
            case ACTIVATE_LOCATION: {
                draftState.active.information.configured = true;
                break;
            }
            default:
                return state;
        }
    })
}
