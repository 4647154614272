import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Chip, TextField } from '@mui/material';
import { useAppDispatch } from '../../../hooks';
import { getSpotSchedules } from './async-actions';
import { useSelector } from 'react-redux';
import { getSchedules } from './selector';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: document.body.offsetWidth > 769 ? 526 : '90%',
    maxHeight: document.body.offsetWidth > 769 ? '100%' : '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '16px 24px',
    borderRadius: '6px'
};

const SpotEnableModal = ({
    selectedSpot,
    handleEnable,
    handleClose
}: any) => {
    const dispatch = useAppDispatch();
    const schedules = useSelector(getSchedules);
    
    const initialSchedulesToShow = 8;
    const [selectedSchedules, setSelectedSchedules] = useState([]) as any;
    const [showMore, setShowMore] = useState(false);
    const [visibleItemCount, setVisibleItemCount] = useState(initialSchedulesToShow);
    const displayedItems = schedules && schedules.slice(0, visibleItemCount);

    useEffect(() => {
        dispatch(getSpotSchedules(selectedSpot.id))
    }, [selectedSpot])

    const handleRemoveSchedule = (selectedSchedule: any) => {
        const isAdded = selectedSchedules.length > 0 && selectedSchedules.find((s: any) => s.id === selectedSchedule.id);
        if (!!isAdded) {
            const filtered = selectedSchedules.filter((s: any) => s.id !== selectedSchedule.id);
            setSelectedSchedules(filtered)
        }

        if (!isAdded) {
            setSelectedSchedules([...selectedSchedules, selectedSchedule]);
        }
    }

    const handleShowMoreClick = () => {
        setShowMore(true);
        setVisibleItemCount(schedules.length);
    };

    const handleShowLessClick = () => {
        setShowMore(false);
        setVisibleItemCount(initialSchedulesToShow);
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography variant="h6" component="h2">
                        Потвърждаване на отблокирането
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '16px 0',
                        gap: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mt: 2
                        }}>
                            <TextField
                                value={selectedSpot.label}
                                label="Избрано място"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={{
                                    flex: 1
                                }} />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <Typography variant="h6" component="h2" sx={{
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    lineHeight: '32px',
                                    letterSpacing: '.15px'
                                }}>
                                    Изберете дата
                                </Typography>
                                {schedules && (schedules.length > initialSchedulesToShow) && (
                                    <div>
                                        {showMore ? (
                                            <Button
                                                sx={{
                                                    cursor: 'pointer',
                                                    background: '#C5FFFF',
                                                    fontWeight: '600',
                                                    color: '#06191E'
                                                }}
                                                onClick={handleShowLessClick}
                                            >
                                                Скрий
                                            </Button>
                                        ) : (
                                            <Button
                                                sx={{
                                                    cursor: 'pointer',
                                                    background: '#C5FFFF',
                                                    fontWeight: '600',
                                                    color: '#06191E'
                                                }}
                                                onClick={handleShowMoreClick}
                                            >
                                                Покажи всички
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                mt: 2
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 1,
                                }}>
                                    {displayedItems && displayedItems.map((schedule: any, index: number) => {
                                        const isSelected = !!selectedSchedules.find((s: any) => s.id === schedule.id);
                                        return (
                                            <Chip
                                                key={index}
                                                label={schedule.date}
                                                onClick={() => handleRemoveSchedule(schedule)}
                                                sx={{
                                                    color: '#fff',
                                                    background: isSelected ? '#044D5E' : '#06191E',
                                                    fontWeight: 700,
                                                    fontSize: '12px',
                                                    lineHeight: '12px',
                                                    letterSpacing: '.15px',
                                                    '&:hover': {
                                                        background: isSelected ? '#044D5E' : '#06191E',
                                                    }
                                                }}
                                            />
                                        )
                                    })}
                                    {schedules && !showMore && (schedules.length > initialSchedulesToShow) && (
                                        <Chip
                                            label={`+${schedules.length - initialSchedulesToShow} оставащи`}
                                            sx={{
                                                color: '#fff',
                                                background: '#044D5E',
                                                fontWeight: 700,
                                                fontSize: '12px',
                                                lineHeight: '12px',
                                                letterSpacing: '.15px',
                                                '&:hover': {
                                                    background: '#044D5E'
                                                }
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'end'
                        }}>
                            <Button onClick={handleClose}>Отказ</Button>
                            <Button sx={{
                                background: '#044D5E',
                                color: '#C5FFFF',
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '24px'
                            }}
                                onClick={() => handleEnable(selectedSchedules)}>
                                Отблокирай
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default SpotEnableModal;