import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import { DashboardSidepanelActions } from "../sidepanel/actions";
import { useAppDispatch } from "../../../hooks";
import { useSelector } from "react-redux";
import { getSelectedDate } from "../sidepanel/selectors";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useMedia } from "../../../utils";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DashboardSidepanel from "../sidepanel/DashboardSidepanel";

const Calendar = () => {
  const dispatch = useAppDispatch();
  const { desktop } = useMedia();
  const selectedDate = useSelector(getSelectedDate);

  const [menuDialog, setMenuDialog] = useState(false);

  // Currently, we are saving the date in the redux state in YYYY-MM-DD format.
  // In terms of moving through the weeks from the calendar, we need to transform the YYYY-MM-DD format
  // and manipulate the weeks inside the object, keeping the redux data as it is
  // and using the useEffect hook to watch for changes in the selected date.
  const [localDateFormat, setLocalDateFormat] = useState(dayjs(selectedDate));

  const weekdays = [
    "Неделя",
    "Понеделник",
    "Вторник",
    "Сряда",
    "Четвъртък",
    "Петък",
    "Събота",
  ];

  useEffect(() => {
    setLocalDateFormat(dayjs(selectedDate));
  }, [selectedDate]);

  const handlePrevWeek = () => {
    setLocalDateFormat((prevDate: any) => {
      return dayjs(prevDate).subtract(1, "week");
    });
  };

  const handleNextWeek = () => {
    setLocalDateFormat((prevDate: any) => dayjs(prevDate).add(1, "week"));
  };

  const handleDayClick = (date: any) => {
    const formatedDate = dayjs(date).format("YYYY-MM-DD");
    setLocalDateFormat(dayjs(date));
    dispatch(DashboardSidepanelActions.setSelectedDate(formatedDate));
    dispatch(DashboardSidepanelActions.resetSelectedSpots());
  };

  const today = dayjs().format("YYYY-MM-DD");

  const renderCalendar = () => {
    const today = dayjs();
    const formatedSelectedDay = dayjs(selectedDate);
    const startOfWeek = dayjs(localDateFormat).startOf("week");
    const endOfWeek = dayjs(localDateFormat).endOf("week");
    const totalDays = endOfWeek.diff(startOfWeek, "day") + 1;
    const calendarDays = [];

    for (let i = 0; i < totalDays; i++) {
      const day = startOfWeek.add(i, "day");
      const isToday = day.isSame(today, "day");
      const isSelected = day.isSame(dayjs(formatedSelectedDay), "day");

      calendarDays.push(
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: `calc(100% / ${totalDays})`,
            padding: "0 5px",
            cursor: "pointer",
            userSelect: "none",
          }}
          key={day.format("YYYY-MM-DD")}
          className={`calendar-day ${isToday ? "today" : ""} ${
            isSelected ? "selected" : ""
          }`}
          onClick={() => handleDayClick(day)}
        >
          <Box
            className="day"
            sx={{
              color: `${isSelected ? "#044D5E" : "rgba(0,0,0, .87)"}`,
              background: `${isSelected ? "#C5FFFF" : "transparent"}`,
              border: `${
                isToday ? "2px solid #044D5E" : "2px solid transparent"
              }`,
              borderRadius: "100%",
              width: "24px",
              height: "24px",
              fontWeight: `${isSelected ? "700" : "400"}`,
              fontSize: "15px",
              lineHeight: "21.45px",
              letterSpacing: "0.11px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              userSelect: "none",
            }}
          >
            {day.format("D")}
          </Box>
          {desktop && (
            <Box
              className="name-day"
              sx={{
                color: `${isSelected ? "#044D5E" : "rgba(0,0,0, .5)"}`,
                fontWeight: `${isSelected ? "700" : "400"}`,
                fontSize: "15px",
                lineHeight: "21.45px",
                letterSpacing: "0.11px",
              }}
            >
              {weekdays[i]}
            </Box>
          )}
          {/* <Box sx={{
                        color: '#5A6977',
                        fontWeight: '400',
                        fontSize: '15px',
                        lineHeight: '21.45px',
                        letterSpacing: '0.11px',
                    }}>
                        {reservedSpotsCount}/83
                    </Box> */}
        </Box>
      );
    }

    return calendarDays;
  };

  const hangleGoToToday = () => {
    const formatedDate = dayjs(today).format("YYYY-MM-DD");
    dispatch(DashboardSidepanelActions.setSelectedDate(formatedDate));
    dispatch(DashboardSidepanelActions.resetSelectedSpots());
  };
  const handleMenuOpen = () => {
    setMenuDialog(true);
  };

  const handleMenuClose = () => {
    setMenuDialog(false);
  };

  return (
    <Box
      className="calendar"
      sx={{
        padding: "16px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "0 16px",
          gap: 2,
          userSelect: "none",
        }}
        className="calendar-header"
      >
        <Chip
          className="current-week"
          label={`${dayjs(localDateFormat)
            .startOf("week")
            .format("D MMM")} - ${dayjs(localDateFormat)
            .endOf("week")
            .format("D MMM YYYY")}`}
          sx={{
            background: "#044D5E",
            color: "#fff",
            fontWeight: "600",
          }}
        />
        {selectedDate === today && (
          <Chip
            className="go-to-today"
            label="Днес"
            onClick={hangleGoToToday}
            sx={{
              background: "#C5FFFF",
              fontWeight: "600",
            }}
          />
        )}

        {!desktop && (
          <IconButton onClick={handleMenuOpen}>
            <CalendarMonthIcon />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            // width: desktop ? '100%' : '100vw'
          }}
        >
          <Box
            sx={{
              flex: "0 0 auto",
            }}
          >
            <IconButton aria-label="arrow back" onClick={handlePrevWeek}>
              <ArrowBackIosNewIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: 1,
            }}
            className="calendar-days"
          >
            {renderCalendar()}
          </Box>
          <Box
            sx={{
              flex: "0 0 auto",
            }}
          >
            <IconButton aria-label="arrow forward" onClick={handleNextWeek}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={menuDialog}
        onClose={handleMenuClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DashboardSidepanel />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMenuClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Calendar;
