import { createSelector } from "@reduxjs/toolkit"

const getAllLocations = (state: any) => state.locations;
export const getLocationsList = createSelector(getAllLocations, locations => locations.list);
const getActiveLocation = createSelector(getAllLocations, locations => locations.active && locations.active);
const getActiveLocationId = createSelector(getAllLocations, locations => locations.active && locations.active.id);

export const getActiveLocationMetadata = createSelector(
    [getActiveLocationId, getLocationsList],
    (activeLocation, locationsList) => {
        if(activeLocation && locationsList) {
            return locationsList.find((location: any) => location.id === activeLocation);
        }
        return null;
    }
)

export const getActiveLocationInformation = createSelector(getActiveLocation, location => {
    return location && location.information;
});

export const getIsLocationActive = createSelector(getActiveLocationInformation, information => {
    return information && information.configured;
});