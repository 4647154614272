import { hasPermission } from "../../store/permissions";
import { LayoutActions } from "./actions";

export const submitVenue =
  (formData: FormData) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("SUBMIT_VENUE")) {
      dispatch(LayoutActions.loadingState(true));
      api.vanueApi
        .submitVanueApi(formData)
        .then((res: any) => {
          console.log("submitVanue RESPONSE: ", JSON.stringify(res));

          dispatch(LayoutActions.submitVenue());

          dispatch(LayoutActions.loadingState(false));

          dispatch(
            LayoutActions.snackbarState({
              show: true,
              isError: false,
              message: "Location saved successfully",
            })
          );
        })
        .catch((error: any) => {
          console.log("submitVanue Error", error);
          dispatch(LayoutActions.loadingState(false));
          dispatch(
            LayoutActions.snackbarState({
              show: true,
              isError: true,
              message: error.response.data.message,
            })
          );
        });
    }
  };

export const editApprovedVenue =
  (vanueId: string, formData: FormData) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("UPDATE_VENUE")) {
      dispatch(LayoutActions.loadingState(true));
      api.vanueApi
        .editApprovedVenue(vanueId, formData)

        .then((res: any) => {
          console.log("submitVanue RESPONSE: ", JSON.stringify(res));

          dispatch(LayoutActions.saveEditVenue());

          dispatch(LayoutActions.loadingState(false));
          dispatch(
            LayoutActions.snackbarState({
              show: true,
              isError: false,
              message: "Location saved successfully",
            })
          );
        })
        .catch((error: any) => {
          console.log("submitVanue Error", error);
          dispatch(LayoutActions.loadingState(false));

          dispatch(
            LayoutActions.snackbarState({
              show: true,
              isError: true,
              message: error.response.data.message,
            })
          );
        });
    }
  };

export const editPendingVenue =
  (vanueId: string, formData: FormData) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("UPDATE_UNAPPROVED_LOCATION")) {
      dispatch(LayoutActions.loadingState(true));
      api.vanueApi
        .editPendingVenue(vanueId, formData)

        .then((res: any) => {
          console.log("submitVanue RESPONSE: ", JSON.stringify(res));

          dispatch(LayoutActions.saveEditVenue());

          dispatch(LayoutActions.loadingState(false));

          dispatch(
            LayoutActions.snackbarState({
              show: true,
              isError: false,
              message: "Location saved successfully",
            })
          );
        })
        .catch((error: any) => {
          console.log("submitVanue Error", error);
          dispatch(
            LayoutActions.snackbarState({
              show: true,
              isError: true,
              message: error.response.data.message,
            })
          );
          dispatch(LayoutActions.loadingState(false));
        });
    }
  };
