import { getUserRole } from "../storage/storage";

const PERMISSIONS: Record<string, Set<string>> = {
  ASSISTANT: new Set([
    "FETCH_SPOTS",
    "FETCH_APPROVED_VENUES",
    "FETCH_RESERVATIONS",
    "CREATE_RESERVATION",
    "MANAGE_RESERVATIONS",
  ]),
  ASSOCIATE: new Set([
    "ASSOCIATE_PLATE",
    "FETCH_APPROVED_VENUES",
    "UPDATE_VENUE",
    "FETCH_RESERVATIONS",
    "CREATE_RESERVATION",
    "MANAGE_RESERVATIONS",
    "FETCH_SPOTS",
    "MANAGE_SPOTS",
    "FETCH_PLATES",
    "FETCH_STATISTICS",
    "FETCH_RESERVATION_REPORTS",
  ]),
  MANAGER: new Set([
    "ASSOCIATE_PLATE",
    "FETCH_APPROVED_VENUES",
    "UPDATE_VENUE",
    "FETCH_PENDING_APPROVAL_VENUES",
    "SUBMIT_VENUE",
    "UPDATE_UNAPPROVED_LOCATION",
    "FETCH_UNAPPROVED_LOCATION_REVIEWS",
    "CREATE_VENUE_LAYOUT",
    "FETCH_PLATES",
    "FETCH_RESERVATIONS",
    "CREATE_RESERVATION",
    "MANAGE_RESERVATIONS",
    "FETCH_EMPLOYEES",
    "CREATE_EMPLOYEE",
    "FETCH_SPOTS",
    "MANAGE_SPOTS",
    "FETCH_PAYOUTS",
    "FETCH_STATISTICS",
    "FETCH_RESERVATION_REPORTS",
    "PUBLISH_VENUE",
  ]),
  OWNER: new Set([
    "ASSOCIATE_PLATE",
    "FETCH_APPROVED_VENUES",
    "CREATE_LOCATION",
    "UPDATE_VENUE",
    "DELETE_LOCATION",
    "FETCH_PENDING_APPROVAL_VENUES",
    "SUBMIT_VENUE",
    "UPDATE_UNAPPROVED_LOCATION",
    "FETCH_UNAPPROVED_LOCATION_REVIEWS",
    "CREATE_VENUE_LAYOUT",
    "FETCH_PLATES",
    "FETCH_RESERVATIONS",
    "CREATE_RESERVATION",
    "MANAGE_RESERVATIONS",
    "FETCH_EMPLOYEES",
    "CREATE_EMPLOYEE",
    "UPDATE_EMPLOYEE",
    "DELETE_EMPLOYEE",
    "FETCH_SPOTS",
    "MANAGE_SPOTS",
    "FETCH_PAYOUTS",
    "FETCH_STATISTICS",
    "FETCH_RESERVATION_REPORTS",
    "PUBLISH_VENUE",
  ]),
};

export const hasPermission = (permission: string): boolean => {
  const userRole = getUserRole();

  // console.log('Callled has permission,' + ' permission => ' + permission);

  const rolePermissions = PERMISSIONS[userRole];
  if (!rolePermissions) {
    return false;
  }
  return rolePermissions.has(permission);
};
