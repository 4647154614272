import {DeviceCommuncationService} from './daisy-communication-protocol';
import {BluetoothSerial} from "@awesome-cordova-plugins/bluetooth-serial";
import {Buffer} from 'buffer';

function getReservationTypeTranslation(type: string): string {
    const translations: { [key: string]: string } = {
        UMBRELLA: "Чадър",
        UMBRELLA_SUNBED: "Шезлонг с чадър",
        TENT: "Шатра",
    };
    return translations[type] || type;
}

export class FiscalReceiptService {

    private deviceCommunicationService!: DeviceCommuncationService;


    constructor(readonly deviceCommunication: DeviceCommuncationService) {
        this.deviceCommunicationService = deviceCommunication;
    }

    async cancelStartedFiscalReceipt() {
        // check is BT enabled
        await this.checkDeviceBluetooth();
        await this.establishConnectionWithDevice();
        const command = this.cancelFiscalReceipt();
        await BluetoothSerial.write(command);
    }

    createReceiptForReservation(reservedBeachObj: any, user: any) {
        const objectType = String(getReservationTypeTranslation(reservedBeachObj.type));
        const spotNumber = String(reservedBeachObj.spot.label);
        const costAmount = String(reservedBeachObj.spot.costAmount);
        const userNames = String(`${user.firstname} ${user.lastname}`);

        const commands = [
            this.startFiscalReceipt("1", "1", ""),
            this.addSaleItem(`Комплект N${spotNumber}`, "А", `${costAmount}'`, "1", "0", "0", false),
            this.printTotal(`MySpot Оператор:`, `${userNames}`, "P", `${costAmount}`),
            this.endFiscalReceipt(),
            this.clearDisplayedInfo()
        ];

        return this.executePrintingCommands(commands);
    }

    createReceiptForAmount(itemDescription: string, costAmount: string, user: any) {

        const userNames = String(`${user.firstname} ${user.lastname}`);
        const commands = [
            this.startFiscalReceipt("1", "1", ""),
            this.addSaleItem(`${itemDescription}`, "А", `${costAmount}'`, "1", "0", "0", false),
            this.printTotal(`MySpot Оператор:`, `${userNames}`, "P", `${costAmount}`),
            this.endFiscalReceipt(),
            this.clearDisplayedInfo()
        ];


        return this.executePrintingCommands(commands);
    }

    private async executePrintingCommands(commands: Array<Uint8Array>) {
        // check is BT enabled
        await this.checkDeviceBluetooth();

        await this.establishConnectionWithDevice();

        await this.executeCommandsSequentially(commands);
    }

    private async establishConnectionWithDevice() {

        try {
            console.log(`Checking is Bluetooth connected`);
            const isBluethoothConnected = await BluetoothSerial.isConnected();
            console.log(`Bluetooth connected: ${isBluethoothConnected}`);
            if (isBluethoothConnected) {
                console.log(`Bluetooth is connected, disconnecting...`);
                await BluetoothSerial.disconnect();
                console.log(`Bluetooth disconnected`);
            }
        } catch (e) {
            console.log(`Error while trying to disconnect`, e);
        }

        let fdAddress = localStorage.getItem('fiscal_device_address');

        if (!fdAddress) {
            console.log(`No fiscal device address found, searching for paired devices`);
            const pairedDevices = await BluetoothSerial.list();
            const fd = pairedDevices.find((device: any) => /^DY\d{6}$/.test(device.name));
            fdAddress = fd.address;
            console.log(`Found fiscal device: `, JSON.stringify(fd));
            localStorage.setItem('fiscal_device_address', fd.address);
        }

        if (!fdAddress) throw new Error('No fiscal device address found');

        console.debug(`Connecting to fiscal device with address: ${String(fdAddress)}`);
        await new Promise((resolve, reject) => {
            setTimeout(() => {
                localStorage.removeItem('fiscal_device_address');
                reject(new Error('No connection with fiscal device'));
            }, 10000);
            BluetoothSerial.connect(fdAddress as string).subscribe((response) => {
                resolve(response);
            }, (error) => {
                console.error(`Error while connecting to fiscal device: `);
                localStorage.removeItem('fiscal_device_address');
                reject(error);
            });
        });
        console.log(`Connected to fiscal device`, await BluetoothSerial.isConnected());
    }

    private async checkDeviceBluetooth() {
        console.log(`Checking is Bluetooth enabled`);
        let bluetoothStatus = await BluetoothSerial.isEnabled();
        console.log(`Bluetooth status: ${bluetoothStatus}`);
        if (bluetoothStatus !== 'OK') {
            console.log(`Bluetooth is not enabled, enabling...`);
            await BluetoothSerial.enable();
            console.log(`Bluetooth enabled`);
        }
    }

    private async executeCommandsSequentially(commands: Array<Uint8Array>) {
        return new Promise(async (resolve, reject) => {
            // timeout for the whole process
            const resolveTimeout = setTimeout(() => {
                console.log(`going to reject`);
                reject(new Error('Problem while communication with the device'));
            }, 20000);

            try {
                let currentIndex = 0;

                const executeNextCommand = async () => {
                    if (currentIndex < commands.length) {
                        const command = commands[currentIndex];
                        const commandBuffer = Buffer.from(command);
                        const hexResult: string[] = [];
                        commandBuffer.forEach((byte) => hexResult.push(byte.toString(16).toUpperCase()));
                        console.log(`Executing `, hexResult);
                        await BluetoothSerial.write(command);
                        console.log(`Command executed successfully`);
                        currentIndex++;
                    } else {
                        console.log(`Bluetooth commands executed successfully, disconnecting`);
                        bluethoothDataSubscr.unsubscribe();
                        await BluetoothSerial.disconnect();
                        console.log(`Bluetooth disconnected`)
                        resolve(true);
                    }
                };

                const bluethoothDataSubscr = BluetoothSerial.subscribeRawData().subscribe(async (data: Uint8Array) => {

                    const successfulStatusArray = ['4', '88', '80', '88', '80', '80', 'B0', '5'];
                    const successfulStatusArray2 = ['4', '88', '80', '88', '80', '80', 'B8', '5'];

                    const receivedData = Buffer.from(data);
                    const res: string[] = [];
                    receivedData.forEach((byte) => res.push(byte.toString(16).toUpperCase()));

                    // FD still printing, so it will wait
                    if (res.length === 1 && res.includes('16')) {
                        console.log(`Printer is printing, so waiting a bit...`);
                        await new Promise(resolve => setTimeout(resolve, 505));
                        await executeNextCommand();
                    } else {
                        if (this.containsSubarray(res, successfulStatusArray) || this.containsSubarray(res, successfulStatusArray2)) {
                            console.log(`command is success response: `, res);
                            await executeNextCommand();
                        } else {
                            console.log(`Command not success response: `, res);
                            await BluetoothSerial.disconnect();
                            reject(new Error(`Command not success response: ${res}`));
                        }
                    }
                });

                await executeNextCommand();
            } catch (e) {
                console.error(`Error while executing commands: `, e);
                reject(e);
            } finally {
                clearTimeout(resolveTimeout);
            }
        });
    }

    private containsSubarray(mainArray: string[], subArray: string[]): boolean {
        for (let i = 0; i <= mainArray.length - subArray.length; i++) {
            if (mainArray.slice(i, i + subArray.length).every((val, index) => val === subArray[index])) {
                return true;
            }
        }
        return false;
    }

    private startFiscalReceipt(operatorID: string, operatorPass: string, uniqueSaleNumber: string) {
        const str = `${operatorID},${operatorPass},${uniqueSaleNumber}`;
        const data = this.deviceCommunicationService.getPacket(str, 48);
        // console.debug('Prepared command for start Fiscal Receipt', data);
        return data;
    }

    private addSaleItem(text1: string, taxGroup: string, price: string, quantity: string, percent: string, netto: string, toCorrectPrice: boolean) {
        if (quantity.length > 0) {
            quantity = `*${quantity}`;
        }
        const str = `${text1}\t${taxGroup}${toCorrectPrice ? '-' : '+'}${price}${quantity},${percent}$${netto}`;
        const data = this.deviceCommunicationService.getPacket(str, 52);
        // console.debug('Prepared command for start Add Sale Item', data);
        return data;
    }

    private printTotal(text1: string, text2: string, paymentType: string, amount: string) {
        const str = `${text1}\n${text2}\t${paymentType}${amount}`;
        const data = this.deviceCommunicationService.getPacket(str, 53);
        // console.debug('Prepared command for Print Total', data);
        return data;
    }

    private endFiscalReceipt() {
        const data = this.deviceCommunicationService.getPacket('TEST TEXT', 56);
        // console.debug('Prepared command for End Fiscal Receipt', data);
        return data;
    }

    private clearDisplayedInfo() {
        const data = this.deviceCommunicationService.getPacket('', 33);
        // console.log('Prepared command for clear display', data);
        return data;
    }

    private cancelFiscalReceipt() {
        return this.deviceCommunicationService.getPacket("", 130);
    }

    private getFDStatus() {
        return this.deviceCommunicationService.getPacket("", 74);
    }

    byteToHex(byte) {
        // convert the possibly signed byte (-128 to 127) to an unsigned byte (0 to 255).
        // if you know, that you only deal with unsigned bytes (Uint8Array), you can omit this line
        const unsignedByte = byte & 0xff;

        // If the number can be represented with only 4 bits (0-15),
        // the hexadecimal representation of this number is only one char (0-9, a-f).
        if (unsignedByte < 16) {
            return '0' + unsignedByte.toString(16);
        } else {
            return unsignedByte.toString(16);
        }
    }

// bytes is an typed array (Int8Array or Uint8Array)
    toHexString(bytes) {
        // Since the .map() method is not available for typed arrays,
        // we will convert the typed array to an array using Array.from().
        return Array.from(bytes)
            .map(byte => this.byteToHex(byte))
            .join('');
    }

    private transformData(data: Uint8Array): string {
        let buf = '';
        for (let b of data) {
            let half = (b >>> 4) & 0x0F;
            let twoHalves = 0;
            do {
                buf += (half <= 9 ? String.fromCharCode('0'.charCodeAt(0) + half) : String.fromCharCode('a'.charCodeAt(0) + half - 10));
                half = b & 0x0F;
            } while (twoHalves++ < 1);
            buf += " ";
        }
        return buf;
    }

}
