import { useState } from "react";
import { useSelector } from "react-redux";
import {
    ReportsContainer
} from "./styles";
import { FilterAlt, LocationOn } from "@mui/icons-material";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import Table from "./table/Table";
import tableConfig from "./table-config";
import { getStatuses } from "./selectors";
import FiltersModal from "./FiltersModal";
import { useMedia } from "../../utils/useMedia";

const ReservationReports = ({ data }: any) => {
    const { mobileOnly } = useMedia();
    const searchByTerms = [
        {
            value: 'reservation-id',
            text: 'Номер на резервация'
        },
        // {
        //     value: 'qr-plate-number',
        //     text: 'QR Plate number'
        // },
        // {
        //     value: 'umbrella-label',
        //     text: 'Umbrella label'
        // },
    ]
    const [filterOpened, setFilterOpened] = useState(false);
    const [searchBy, setSearchBy] = useState(searchByTerms[0].text);
    const [searchTerm, setSearchTerm] = useState('');

    const handleLocationChange = (event: SelectChangeEvent) => {
        setSearchBy(event.target.value)
    };

    const handleSearchTermChange = (event: any) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const statuses = useSelector(getStatuses);

    const filteredData = data && data.filter((d: any) => statuses.includes(d.status));
    const searchedData = filteredData && filteredData.filter((d: any) => {
        if (searchTerm !== "") {
            return d.id.toLowerCase().includes(searchTerm);
        }
        return d;
    });

    const handleFilterClick = () => {
        setFilterOpened(!filterOpened);
    }

    const handleFiltersModalClose = () => {
        setFilterOpened(!filterOpened);
    }

    return (
        <ReportsContainer>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2,
            }}>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <Button variant="contained"
                        sx={{
                            background: '#044D5E',
                            color: '#fff',
                            '&:hover': {
                                background: '#044D5E',
                                color: '#fff'
                            }
                        }}
                        onClick={handleFilterClick}
                    >
                        <FilterAlt sx={{ mr: 1 }} />
                        Филтри
                    </Button>
                </Box>
                <Box sx={{
                    width: { xs: '100%', sm: 'auto' },
                    flex: { xs: '100%', sm: '0 0 30%' },
                }}>
                    <TextField
                        label="Търсене"
                        id="search-field"
                        placeholder="Номер на резервация, статус и др."
                        size="small"
                        sx={{
                            background: '#F4F7FE',
                            color: '#000',
                            boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
                            width: '100%',
                            fontWeight: '600',
                            '& fieldset': {
                                border: 'none',
                            }
                        }}
                        onChange={handleSearchTermChange}
                    />
                </Box>
                <FormControl sx={{
                    m: 1,
                    minWidth: 120,
                    width: { xs: '100%', sm: 'auto' },
                    flex: { xs: '100%', sm: '0 0 30%' },
                }} size="small">
                    <Select
                        label="Търсене по"
                        id="location-select"
                        value={searchBy}
                        onChange={handleLocationChange}
                        renderValue={(value) => {
                            return (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    <LocationOn sx={{
                                        fill: '#422AFB'
                                    }} />
                                    {value}
                                </Box>
                            );
                        }}
                        sx={{
                            background: '#F4F7FE',
                            color: '#000',
                            boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
                            fontWeight: '600',
                            '& fieldset': {
                                border: 'none',
                            }
                        }}
                    >
                        {searchByTerms.map((searchBy, index) => (
                            <MenuItem value={searchBy.text} key={index}>{searchBy.text}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {data && data.length >= 0 &&
                <Box sx={{ mt: 2, width: { xs: 'calc(100vw - 48px)', sm: 'auto' } }}>
                    <Table columns={tableConfig} data={searchedData} />
                </Box>
            }
            {mobileOnly && filterOpened &&
                < FiltersModal
                    handleClose={handleFiltersModalClose}
                />
            }
        </ReportsContainer>
    )
}

export default ReservationReports;