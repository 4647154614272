import React, { useState, useEffect, SyntheticEvent } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ReservationReports from "./ReservationReportsTab";
import SpotReportsTab from "./SpotReportsTab";
import { getActiveLocationMetadata } from "../../store/locations/selectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks";
import { getReport } from "./async-actions";
import {
  getLocationReports,
  getReportEndDate,
  getReportStartDate,
  getStatuses,
} from "./selectors";
import { hasPermission } from "../../store/permissions";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3, pr: 3, pl: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Reports = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);
  const activeLocation = useSelector(getActiveLocationMetadata);
  const locationReservations = useSelector(getLocationReports);
  const startDate = useSelector(getReportStartDate);
  const endDate = useSelector(getReportEndDate);
  const statuses = useSelector(getStatuses);
  const limit = 100;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!hasPermission("FETCH_RESERVATION_REPORTS") && !hasPermission("UPDATE_EMPLOYEE")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (activeLocation) {
      dispatch(
        getReport(activeLocation.id, limit, startDate, endDate, statuses)
      );
    }
  }, [activeLocation, startDate, endDate, statuses]);

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs value={value} onChange={handleChange} aria-label="reports-tabs">
          <Tab label="Резервации" {...a11yProps(0)} />
          {/* <Tab label="Spot reports" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ReservationReports data={locationReservations} />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
                <SpotReportsTab />
            </TabPanel> */}
    </Box>
  );
};

export default Reports;
