import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getReportEndDate, getReportStartDate, getStatuses } from "./selectors";
import { ReportsActions } from "./actions";
import { useAppDispatch } from "../../hooks";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: document.body.offsetWidth > 769 ? 526 : '90%',
    maxHeight: document.body.offsetWidth > 769 ? '100%' : '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '16px 24px',
    borderRadius: '6px'
};

const FiltersModal = ({
    handleClose
}: any) => {
    const dispatch = useAppDispatch();
    const startDate = useSelector(getReportStartDate);
    const endDate = useSelector(getReportEndDate);
    const statuses = useSelector(getStatuses);
    const statusess = [
        {
            status: 'COMPLETED',
            text: 'Completed'
        },
        {
            status: 'REVOKED',
            text: 'Revoked'
        },
        {
            status: 'ACTIVE',
            text: 'Active'
        },
        {
            status: 'CANCELLED',
            text: 'Cancelled'
        }
    ];

    const handleButtonClick = (option: any) => {
        if (statuses.includes(option)) {
            dispatch(ReportsActions.removeStatus(option));
        } else {
            dispatch(ReportsActions.addStatus(option));
        }
    };

    const handleStartDateChange = (event: any) => {
        dispatch(ReportsActions.setStartDate(dayjs(event).format('YYYY-MM-DD')));
    }

    const handleEndDateChange = (event: any) => {
        dispatch(ReportsActions.setEndDate(dayjs(event).format('YYYY-MM-DD')));
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Филтри
                </Typography>
                <Box sx={{
                    mt: 2
                }}>
                    <Typography sx={{
                        fontWeight: 500,
                        fontSize: '10px',
                        lineHeight: '14px'
                    }}>Филтрирай по статус</Typography>
                    <Box sx={{
                        mt: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1
                    }}>
                        {statusess.map((status: any, index: number) => {
                            return (
                                <Button
                                    key={index}
                                    sx={{
                                        padding: '4px 6px',
                                        lineHeight: 'auto',
                                        outline: 'none',
                                        border: '1px solid #044D5E',
                                        color: '#044D5E',
                                        '&:hover': {
                                            borderColor: '#044D5E'
                                        },
                                        '&.MuiButton-contained': {
                                            background: '#044D5E',
                                            color: '#C5FFFF'
                                        }
                                    }}
                                    variant={statuses.includes(status.status) ? 'contained' : 'outlined'}
                                    onClick={() => handleButtonClick(`${status.status}`)}
                                >
                                    {status.text}
                                </Button>
                            )
                        })}
                    </Box>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box>
                    <Typography sx={{
                        fontWeight: 500,
                        fontSize: '10px',
                        lineHeight: '14px'
                    }}>Сортирай по дати</Typography>
                    <Box sx={{
                        mt: 1
                    }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="От дата" value={dayjs(startDate)} onChange={handleStartDateChange} />
                            </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} sx={{
                                mt: 1
                            }}>
                                <DatePicker label="До дата" value={dayjs(endDate)} onChange={handleEndDateChange} />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Box>
                </Box>
                <Box sx={{
                    mt: 2
                }}>
                    <Button
                        variant="contained"
                        sx={{
                            background: '#044D5E',
                            color: '#fff',
                            '&:hover': {
                                background: '#044D5E',
                                color: '#fff',
                            }
                        }}
                        onClick={handleClose}
                    >
                        Затвори
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default FiltersModal;