import api from "./auth";

const submitVanueApi = (body: FormData) => {
  return api.post('venues', body, {
    headers: {
      'Content-Type': 'application/vnd.myspotapi.v1+json'
    }
  });
}

const editApprovedVenue = (venueId: string, body: Object) => {
  return api.patch(`venues/${venueId}`, body);
}

const editPendingVenue = (venueId: string, body: Object) => {
  return api.patch(`venues/pending-approval/${venueId}`, body);
}

const vanueApi = {
  submitVanueApi,
  editApprovedVenue,
  editPendingVenue
};

export default vanueApi;
