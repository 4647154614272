import {
  SUBMIT_VENUE,
  SAVE_EDIT_VENUE,
  SET_LOADING,
  SHOW_SNACKBAR,
} from "./types";

interface InitialState {
  loading: boolean;
  snackbar: Object;
}

const initialState: InitialState = <InitialState>{
  loading: false,
  snackbar: {
    show: false,
    isError: false,
    message: "",
  },
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: action.payload,
      };
    case SUBMIT_VENUE:
      return {
        ...state,
      };
    case SAVE_EDIT_VENUE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
