import {
  SUBMIT_VENUE,
  SAVE_EDIT_VENUE,
  SET_LOADING,
  SHOW_SNACKBAR,
} from "./types";

const submitVenue = () => ({
  type: SUBMIT_VENUE,
});

const saveEditVenue = () => ({
  type: SAVE_EDIT_VENUE,
});

const loadingState = (isLoading: boolean) => ({
  type: SET_LOADING,
  payload: isLoading,
});

const snackbarState = (snackbar: {
  show: boolean;
  isError: boolean;
  message: string;
}) => ({
  type: SHOW_SNACKBAR,
  payload: snackbar,
});

export const LayoutActions = {
  submitVenue,
  saveEditVenue,
  loadingState,
  snackbarState,
};
