const ACTION_TYPE = "BEACH-LOCATION";

export const LOADING = `${ACTION_TYPE}/LOADING`;

export const ADD_LAYOUT = `${ACTION_TYPE}/ADD_LAYOUT`;
export const SHOW_SNACKBAR = `${ACTION_TYPE}/SHOW_SNACKBAR`;

export const ADD_SPOTS = `${ACTION_TYPE}/ADD_SPOTS`;
export const UPDATE_SPOT = `${ACTION_TYPE}/UPDATE_SPOT`;

export const SET_DIMENSIONS = `${ACTION_TYPE}/SET_DIMENSIONS`;
export const SET_PLATES = `${ACTION_TYPE}/SET_PLATES`;

export const SET_SEARCH_RESULTS = `${ACTION_TYPE}/SET_SEARCH_RESULTS`;
export const ACTIVATE_LOCATION = `${ACTION_TYPE}/ACTIVATE_LOCATION`;
export const SET_SCANNER_DATA = `${ACTION_TYPE}/SET_SCANNER_DATA`;
export const RESET_SCANNER_DATA = `${ACTION_TYPE}/RESET_SCANNER_DATA`;

export const SET_SPOT_AVAILABILITY = `${ACTION_TYPE}/SET_SPOT_AVAILABILITY`;
export const RESET_SPOT_AVAILABILITY = `${ACTION_TYPE}/RESET_SPOT_AVAILABILITY`;

export const SET_RESERVATIONS = `${ACTION_TYPE}/SET_RESERVATIONS`;
export const SET_NEW_RESERVATION = `${ACTION_TYPE}/SET_NEW_RESERVATION`;
export const REVOKE_RESERVATION = `${ACTION_TYPE}/REVOKE_RESERVATION`;
export const ADD_SPOT_RESERVATION_NOTE = `${ACTION_TYPE}/ADD_SPOT_RESERVATION_NOTE`;
export const SET_SPOT_RESERVATION_NOTES = `${ACTION_TYPE}/SET_SPOT_RESERVATION_NOTES`;

export const ADD_SCHEDULE = `${ACTION_TYPE}/ADD_SCHEDULE`;
export const REMOVE_SCHEDULE = `${ACTION_TYPE}/REMOVE_SCHEDULE`;
