import { LocationsActions } from "./actions";
import {Dayjs} from "dayjs";
import {LayoutActions} from "../../views/dashboard/beach-location/actions.ts";
import Layout from "../../views/dashboard/beach-location/Layout.tsx";

export const getSingleLocation =
  (guid: string) =>
  (dispatch: any, getState: any, { api }: any) => {
    api.locationsAPI
      .getLocation(guid)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        if (!res) return;
        const { data } = res;
        if (data) {
          dispatch(LocationsActions.addLocationInformation(data));
        }
      });
  };

export const getLocations =
  () =>
  (dispatch: any, getState: any, { api }: any) => {
    api.locationsAPI
      .getEmploymentLocations()
      .then((res: any) => {
        if (res) {
          const { data } = res;
            if (data) {
                dispatch(LocationsActions.addLocations(data));

                const lastSelectedLocation =
                    JSON.parse(localStorage.getItem("lastSelectedLocation") as string);
                if (data.filter((el: { id: any; }) => el.id === lastSelectedLocation?.id).length === 0) {
                    localStorage.removeItem("lastSelectedLocation");
                    dispatch(LocationsActions.setActiveLocation(data[0]));
                } else {
                    dispatch(LocationsActions.setActiveLocation(lastSelectedLocation));
                }
            }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

export const addSpotReservationNote = (spotId, body) => {
    return (dispatch: any, getState: any, { api }: any) => {
        api.spotsAPI
            .createSpotNote(spotId, body)
            .then((res: any) => {
                return res;
            })
            .catch((error: any) => {
                console.log(error);
            });
    }
}

export const spotNotes = (spotId: string, forDay: string) => {
    return (dispatch: any, getState: any, {api}: any) => {
        api.spotsAPI.spotNotes(spotId, forDay)
            .then((res: any) => {
                dispatch(LayoutActions.setSpotReservationNotes(res.data));
            })
            .catch((error: any) => {
                console.log(error);
            });
    }
}

export const deleteLocation =
  (guid: string) =>
  (dispatch: any, getState: any, { api }: any) => {
    api.locationsAPI
      .deleteLocation(guid)
      .then((res: any) => {
        dispatch(getLocations());
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

// not tested
export const completeLocationConfiguration =
  (guid: string, config: any) =>
  (dispatch: any, getState: any, { api }: any) => {
    api.locationsAPI
      .completeLocationConfiguration(guid, config)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data) {
          // dispatch(LocationsActions.addLocations(data))
        }
      });
  };

export const activateLocation =
  (guid: string) =>
  (dispatch: any, getState: any, { api }: any) => {
    api.locationsAPI
      .activateLocation(guid)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data) {
        }
      });
  };

// export const deactivateLocation =
//   (guid: string) =>
//   (dispatch: any, getState: any, { api }: any) => {
//     api.locationsAPI
//       .deactivateLocation(guid)
//       .catch((error: any) => {
//         console.log(error);
//       })
//       .then((res: any) => {
//         const { data } = res;
//         if (data) {
//         }
//       });
//   };

// export const getPendingLocations =
//   (guid: string) =>
//   (dispatch: any, getState: any, { api }: any) => {
//     api.locationsAPI
//       .pendingLocations(guid)
//       .catch((error: any) => {
//         console.log(error);
//       })
//       .then((res: any) => {
//         const { data } = res;
//         if (data) {
//         }
//       });
//   };

// export const submitLocation =
//   (form: string) =>
//   (dispatch: any, getState: any, { api }: any) => {
//     api.locationsAPI
//       .submitLocation(form)
//       .catch((error: any) => {
//         console.log(error);
//       })
//       .then((res: any) => {
//         const { data } = res;
//         if (data) {
//         }
//       });
//   };

// export const updateLocation =
//   (form: any, guid: string) =>
//   (dispatch: any, getState: any, { api }: any) => {
//     api.locationsAPI
//       .updateLocation(form, guid)
//       .catch((error: any) => {
//         console.log(error);
//       })
//       .then((res: any) => {
//         const { data } = res;
//         if (data) {
//         }
//       });
//   };

// export const approveSubmitedLocation =
//   (form: any, guid: string) =>
//   (dispatch: any, getState: any, { api }: any) => {
//     api.locationsAPI
//       .approveSubmitedLocation(form, guid)
//       .catch((error: any) => {
//         console.log(error);
//       })
//       .then((res: any) => {
//         const { data } = res;
//         if (data) {
//         }
//       });
//   };
