import { DashboardSidepanelActions } from "./actions";
import { getLayout, getReservedSpots } from "../beach-location/async-actions";
import { LayoutActions } from "../beach-location/actions";
import { hasPermission } from "../../../store/permissions";

export const createReservation =
  (form: any) =>
  (dispatch: any, _: any, { api }: any) => {
    dispatch(DashboardSidepanelActions.setReservationLoading(true));

    if (hasPermission("CREATE_RESERVATION")) {
      api.reservationsAPI
        .createReservation(form)
        .then((res: any) => {
          const { data } = res;
          if (data) {
            dispatch(LayoutActions.setNewReservation(data));
            dispatch(getLayout(form.venueId));
            dispatch(
              getReservedSpots(form.venueId, form.startDate, form.endDate)
            );
            dispatch(DashboardSidepanelActions.setReservationLoading(false));
            dispatch(DashboardSidepanelActions.resetSelectedSpots());
          }
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(
            LayoutActions.snackbarState({
              show: true,
              isError: true,
              message: error.response.data.message,
            })
          );
          dispatch(DashboardSidepanelActions.setReservationLoading(false));
          dispatch(DashboardSidepanelActions.resetSelectedSpots());
        });
    }
  };
