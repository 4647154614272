import {
  LOADING,
  ADD_EMPLOYEES,
  ADD_ROLES,
  ADD_EMPLOYEE,
  REMOVE_EMPLOYEE,
  SET_EMPLOYEE_ACTIVE_STATUS,
  SET_EMPLOYEE_INACTIVE_STATUS,
  SHOW_SNACKBAR,
} from "./types";

const loadingState = (payload: boolean) => ({
  type: LOADING,
  payload,
});

const addEmployees = (payload: any) => ({
  type: ADD_EMPLOYEES,
  payload,
});

const addRoles = (payload: any) => ({
  type: ADD_ROLES,
  payload,
});

const addEmployee = (payload: any) => ({
  type: ADD_EMPLOYEE,
  payload,
});

const removeEmployee = (payload: any) => ({
  type: REMOVE_EMPLOYEE,
  payload,
});

const setEmployeeActiveStatus = () => ({
  type: SET_EMPLOYEE_ACTIVE_STATUS,
});

const setEmployeeInactiveStatus = () => ({
  type: SET_EMPLOYEE_INACTIVE_STATUS,
});

const snackbarState = (snackbar: {
  show: boolean;
  isError: boolean;
  message: string;
}) => ({
  type: SHOW_SNACKBAR,
  payload: snackbar,
});

export const EmployeesActions = {
  loadingState,
  addEmployees,
  addRoles,
  addEmployee,
  removeEmployee,
  setEmployeeActiveStatus,
  setEmployeeInactiveStatus,
  snackbarState,
};
