import { configureStore, combineReducers } from "@reduxjs/toolkit";
import LocationsReducer from "./locations/reducer";
import LayoutReducer from "../views/dashboard/beach-location/reducer";
import DashboardSidepanelReducer from "../views/dashboard/sidepanel/reducer";
import ReportsReducer from "../views/reports/reducer";
import EmployeesReducer from "../views/user-management/reducer";
import VenuesReducer from "../views/map-page/venues/reducer";
import CreateLocationReducer from "../views/create-location/reducer";
import LoginReducer from "../views/login/reducer";
import AccountReducer from "../views/account-activation/reducer";
import StatisticsReducer from "../views/statistics/reducer";

import api from "../../src/api/index";

const rootReducer = combineReducers({
  locations: LocationsReducer,
  layout: LayoutReducer,
  dashboardSidepanel: DashboardSidepanelReducer,
  reports: ReportsReducer,
  employees: EmployeesReducer,
  venues: VenuesReducer,
  createLocation: CreateLocationReducer,
  login: LoginReducer,
  account: AccountReducer,
  statistics: StatisticsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          api,
        },
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
