import api from "./auth";

const getLocationReservations = (
  venueId: string,
  selectedDate: string,
) => {
  const formData = {
    venueId: venueId,
    afterDate: selectedDate,
    beforeDate: selectedDate,
    status: "ACTIVE",
  };
  // statuses: "ACTIVE",

  return api.get(`reservations`, {
    params: formData,
  });
};

const createReservation = (fromData: any) => {
  return api.post("reservations/on-site", fromData);
};

const completeReservation = (reservationId: string) => {
  return api.post(`reservations/${reservationId}/complete`);
};

const cancelReservation = (reservationId: string) => {
  return api.post(`reservations/${reservationId}/revoke`);
};

const cancelAllReservations = (spotIds: Array<string>) => {
  return api.post(`reservations/revoke-all`, {
    spotIds,
  });
};

/**
 * @deprecated - Use ticketsAPI instead
 * @param receiptId
 */
const scanReservation = (receiptId: string) => {
  return api.get(`receipts/${receiptId}/preview`);
};

const markIssuedSpot = (reservationId: string) => {
  return api.post(`reservations/${reservationId}/mark-receipt-issued`);
};

const reservationsAPI = {
  getLocationReservations,
  createReservation,
  completeReservation,
  cancelReservation,
  cancelAllReservations,
  scanReservation,
  markIssuedSpot
};
export default reservationsAPI;
