export interface Data {
    name: string;
    contactEmail: string;
    contactPhoneNumber: string;
    city: string;
    type: string;
    priceLevel: string;
    actions: string;
}

interface Column {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const cols: Column[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'contactEmail',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'contactPhoneNumber',
        numeric: false,
        disablePadding: false,
        label: 'Phone',
    },
    {
        id: 'city',
        numeric: false,
        disablePadding: false,
        label: 'City',
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'priceLevel',
        numeric: false,
        disablePadding: false,
        label: 'Price level',
    },
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Action',
    },
];

export default {
    cols
}