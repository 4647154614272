import React, {MutableRefObject, useEffect, useRef} from "react";
import {Html5Qrcode} from "html5-qrcode";
import {Box, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const QRScanner = ({handleResult, onClose}) => {
    const lastScannedValueRef: MutableRefObject<string | null> = useRef(null);

    useEffect(() => {
        const onScanSuccess = (result: string | null) => {
            if (result !== lastScannedValueRef.current) {
                lastScannedValueRef.current = result;
                handleResult(result);
            }
        };

        const onScanFailure = (err: any) => {
        };

        const startCamera = async () => {
            try {
                const config = {fps: 10, qrbox: {width: 250, height: 250}};
                const html5QrCode = new Html5Qrcode("scanner");
                await html5QrCode.start(
                    {facingMode: "environment"},
                    config,
                    onScanSuccess,
                    onScanFailure
                );
                return html5QrCode;
            } catch (err) {
                console.error("Error starting camera: ", err);
            }
        };

        let camera: Html5Qrcode | undefined;

        startCamera().then((c) => camera = c);

        return () => {
            camera?.stop().then(() => camera?.clear()).catch((err) => {
                console.error("Error stopping camera: ", err);
            });
        };
    }, [handleResult]);

    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: 9999,
                background: "#fff",
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
            }}
        >
            <Box sx={{position: "relative", width: "100%"}}>
                <Box
                    id="scanner"
                    sx={{
                        flex: 1,
                        border: "none !important",
                        // removes info icon
                        "& div:first-of-type > img": {
                            display: "none",
                        },
                        // hide scan from image
                        "#html5-qrcode-anchor-scan-type-change": {
                            display: "none !important",
                        },
                    }}
                />
                <IconButton
                    sx={{
                        position: "fixed",
                        top: 80,
                        right: 16,
                        zIndex: "10000",
                    }}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
        </Box>
    );
};

export default QRScanner;
