import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Logo from "../../assets/logo.svg";
import { useAppDispatch } from "../../hooks";
import { login } from "./async-actions";
import {
  GraphImage1,
  GraphImage2,
  GraphImage3,
  LoginLines,
} from "../../assets/icons";
import LoginImage from "../../assets/login-image.jpg";
import { useMedia } from "../../utils/useMedia";

import { useSelector } from "react-redux";
import { getLoading, getSnackbar } from "./selectors";
import { LoginActions } from "./actions";

const Image = styled.img.attrs((props: any) => ({
  src: props.src || "",
  alt: props.alt || "",
}))`
  width: 100%;
  height: 100%;
`;

const GraphImageContainer = styled.div<{
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}>`
  background: #fff;
  padding: 14px 18px;
  position: absolute;
  border-radius: 12px;
  top: ${(props: any) => props.top}px;
  right: ${(props: any) => props.right}px;
  bottom: ${(props: any) => props.bottom}px;
  left: ${(props: any) => props.left}px;
  -webkit-box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.47);
  box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.47);
`;

const Login = () => {
  const loading = useSelector(getLoading);

  const snackbar = useSelector(getSnackbar);

  const { tablet } = useMedia();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user") as any)
  );
  const [isEmailValid, setEmailValid] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user") as string));
  }, []);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(email);
    // return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  useEffect(() => {
    isEmailValid && password && password.length > 6
      ? setIsLoginDisabled(false)
      : setIsLoginDisabled(true);
  }, [email, password]);

  if (user) {
    return <Navigate to="/" replace />;
  }

  const handleSubmit = () => {
    dispatch(login(email, password, navigate));
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    setEmailValid(isValidEmail(event.target.value));
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(
      LoginActions.snackbarState({
        show: false,
        isError: snackbar.isError,
        message: "",
      })
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          position: "fixed",
          top: "41px",
          left: 0,
        }}
      >
        <LoginLines />
      </Box>
      <Grid
        container
        sx={{
          margin: 0,
        }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            minHeight: "100vh",
          }}
        >
          <Grid
            container
            sx={{
              margin: 0,
              "@media only screen and (max-width: 899px)": {
                mt: "96px",
                "& .MuiGrid-root.MuiGrid-item": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              },
              "@media only screen and (min-width: 900px) and (max-width: 979px)":
                {
                  padding: "90px 0 0 90px",
                },
              "@media only screen and (min-width: 980px)": {
                padding: "137px 0 0 150px",
              },
            }}
          >
            <Grid item xs={12}>
              <img src={Logo} alt="" />
              <Box
                sx={{
                  maxWidth: "400px",
                  mt: "36px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "48px",
                    lineHeight: "56px",
                    "@media only screen and (max-width: 899px)": {
                      textAlign: "center",
                      fontSize: "36px",
                    },
                  }}
                >
                  Добре дошли в административния панел!
                </Typography>
                <Divider sx={{ mt: 4 }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: "36px",
                  maxWidth: "400px",
                }}
                noValidate
                autoComplete="off"
              >
                <FormControl
                  sx={{
                    width: tablet ? "400px" : "320px",
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="email">Имейл адрес</InputLabel>
                  <OutlinedInput
                    id="email"
                    type="email"
                    label="Имейл адрес"
                    onChange={handleEmailChange}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    mt: 2,
                    width: tablet ? "400px" : "320px",
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="password">Парола</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Парола"
                    onChange={handlePasswordChange}
                  />
                </FormControl>
                <Box sx={{ zIndex: 9999 }}>
                  <Link to="/reset-password-request">
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "20px",
                        letterSpacing: ".4px",
                        mt: 1,
                        ml: "14px",
                      }}
                    >
                      Забравена парола?
                    </Typography>
                  </Link>
                </Box>
              </Box>
              <Box
                sx={{
                  maxWidth: "400px",
                }}
              >
                <Box sx={{ mt: "74px", position: "relative" }}>
                  <Button
                    sx={{
                      background: "#044D5E",
                      color: "#C5FFFF",
                      width: tablet ? "400px" : "320px",

                      "&[disabled]": {
                        background: "#EDEFF2",
                        color: "#06191E",
                        opacity: "0.2",
                      },
                    }}
                    disabled={isLoginDisabled || loading}
                    onClick={handleSubmit}
                  >
                    Вход
                  </Button>

                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-16px",
                        marginLeft: "-16px",
                      }}
                    />
                  )}
                </Box>

                {/* todo */}
                {/* <Divider sx={{ mt: 2 }} />
                                <Typography sx={{
                                    textAlign: 'center',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    letterSpacing: '.4px',
                                    mt: 1,
                                    ml: '14px',
                                    width: tablet ? '400px' : '320px',
                                }}>
                                    Все още нямате акаунт?
                                </Typography>
                                <Button
                                    variant='text'
                                    sx={{
                                        color: '#044D5E',
                                        width: tablet ? '400px' : '320px',
                                    }}
                                    onClick={handleSubmit}>Регистрация</Button> */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            minHeight: "100vh",
            padding: "16px",
            "@media only screen and (max-width: 899px)": {
              display: "none",
            },
          }}
        >
          <Grid
            container
            sx={{
              margin: 0,
              minHeight: "100vh",
              height: "100%",
              position: "relative",
            }}
          >
            <Image src={LoginImage} />
            <GraphImageContainer top="60" right="13">
              <GraphImage1 />
            </GraphImageContainer>
            <GraphImageContainer top="214" left="-35">
              <GraphImage2 />
            </GraphImageContainer>
            <GraphImageContainer bottom="174" right="22">
              <GraphImage3 />
            </GraphImageContainer>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.show}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          severity={snackbar.isError ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
          onClose={handleSnackBarClose}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
