import { AccountAction } from "./actions";

export const submitAccountActivation =
  (formData: any) =>
  (dispatch: any, _: any, { api }: any) => {
    dispatch(AccountAction.loadingState(true));

    api.accountApi
      .submitActivation(formData)
      .then((res: any) => {
        console.log("submitVanue RESPONSE: ", JSON.stringify(res));
        dispatch(AccountAction.loadingState(false));
        dispatch(AccountAction.accountActivated(true));
      })
      .catch((error: any) => {
        console.log("submitVanue Error", error);
        // dispatch(AccountAction.accountFailed(true));
        dispatch(
          AccountAction.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
        dispatch(AccountAction.loadingState(false));
      });
  };
