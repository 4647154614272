import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import Logout from "@mui/icons-material/Logout";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { hasPermission } from "../../store/permissions";

const ProfileDropdown = () => {
  const [avatarLetters, setAvatarLetters] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user") as any)
  );
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateLocation = () => {
    navigate(`/create-location`);
  };

  const handleApprovedVenues = () => {
    navigate(`/approved-venues`);
  };

  const handlePendingVenues = () => {
    navigate(`/pending-venues`);
  };

  const handleLogout = () => {
    // dispatch(LoginActions.logoutSuccess());
    localStorage.removeItem("user");
    setUser(JSON.parse(localStorage.getItem("user") as string));
    // return <Navigate to='/login' replace/>
  };

  useEffect(() => {
    if (user) {
      const slicedName = user.firstname.charAt(0);
      const slicedSurname = user.lastname.charAt(0);
      const avatarLetters = `${slicedName}${slicedSurname}`;
      setAvatarLetters(avatarLetters);
    }
  }, [user]);

  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{
            ml: 2,
            background: "#044D5E",
          }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              background: "#044D5E",
              color: "#ABFFC1",
            }}
          >
            {avatarLetters}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* todo */}
        {/* <MenuItem onClick={handleClose}>
                    <Avatar /> Profile
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Add another account
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem> */}

        {hasPermission("SUBMIT_VENUE") && (
          <MenuItem onClick={handleCreateLocation}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            Добави локация
          </MenuItem>
        )}
        {hasPermission("FETCH_APPROVED_VENUES") && (
          <MenuItem onClick={handleApprovedVenues}>
            <ListItemIcon>
              <CheckCircleOutlineIcon fontSize="small" />
            </ListItemIcon>
            Одобрени локации
          </MenuItem>
        )}

        {hasPermission("FETCH_PENDING_APPROVAL_VENUES") && (
          <MenuItem onClick={handlePendingVenues}>
            <ListItemIcon>
              <AccessTimeIcon fontSize="small" />
            </ListItemIcon>
            Локации чакащи одобрение
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Изход
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileDropdown;
