import api from './auth';

const getDetailedLayout = (guid: string) => {
    return api.get(`layouts/${guid}`);
    // return api.get(`layouts/${guid}/detailed`);
}

const createVenueLayout = (body: Object) => {
    return api.post('layouts', body);
}
const getApprovedVenueLayout = () => {
    return api.get(`venues/approved`);
}
const getPendingVenueLayout = () => {
    return api.get(`venues/pending-approval`);
}

const getVenueDetailLayout = (guid: string) => {
    return api.get(`layouts/${guid}/canvas`);
}

const submitVenueLayout = (roleId: string, firstname: string, lastname: string, email: string, employmentLocationIds: Array<string>) => {
    return api.post('employees', {
        roleId,
        email,
        firstname,
        lastname,
        employmentLocationIds
    });
}

const editApprovedVenueLayout  = (guid: string, form: any) => {
    return api.patch(`locations/unapproved/${guid}`, {
        form
    })
}
const editapprovedPendingVenueLayout  = (guid: string, form: any) => {
    return api.patch(`locations/unapproved/${guid}`, {
        form
    })
}

const layoutsAPI = {
    getDetailedLayout,
    createVenueLayout,
    getApprovedVenueLayout,
    getPendingVenueLayout,
    getVenueDetailLayout,
    submitVenueLayout,
    editApprovedVenueLayout,
    editapprovedPendingVenueLayout
};

export default layoutsAPI;