import api from "./auth";

const getPlate = (guid: string) => {
  return api.get(`plates/${guid}`);
};

const getOrganisationPlates = (locationId: string) => {
  return api.get(`plates/unassociated?venueId=${locationId}`);
};

const plateAssociate = (
  plateId: string,
  locationId: string,
  spotId: string
) => {
  const data = {
    venueId: locationId,
    spotId: spotId,
  };

  return api.post(`plates/${plateId}/associate`, data);
};

const platesAPI = {
  getPlate,
  getOrganisationPlates,
  plateAssociate
};

export default platesAPI;
