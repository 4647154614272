// Get logged in user role
export const getUserRole = () => {
  const user: any = JSON.parse(localStorage.getItem("user") as any);
  if (!user) {
    console.log("User not found!!");
  }
  const role = user ? user.role : "";

  return role;
};
