import api from "./auth.ts";

const scanTicket = (ticketId: string) => {
    return api.get(`tickets/${ticketId}/valid`);
};

const ticketsAPI = {
    scanTicket,
};
export default ticketsAPI;
