import { LayoutActions } from "./actions";
import { DashboardSidepanelActions } from "../sidepanel/actions";
import { hasPermission } from "../../../store/permissions";

export const getLayout =
  (locationGuid: string) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("FETCH_SPOTS")) {
      dispatch(LayoutActions.loadingState(true));
      api.layoutsAPI
        .getDetailedLayout(locationGuid)
        .catch((error: any) => {
          console.log(error);
          dispatch(LayoutActions.loadingState(false));
        })
        .then((res: any) => {
          dispatch(LayoutActions.loadingState(false));
          const { data } = res;
          if (data) {
            dispatch(LayoutActions.addLayout(data));
            dispatch(LayoutActions.setDimensions(data));
          } else {
            dispatch(LayoutActions.addLayout({}));
            dispatch(LayoutActions.setDimensions({}));
          }
        });
    }
  };

export const getReservedSpots =
  (venueId: string, startDate: any, endDate: any) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("FETCH_RESERVATIONS")) {
      api.spotsAPI
        .reservedSpots(venueId, startDate, endDate)
        .then((res: any) => {
          if (res) {
            const { data } = res;
            if (data) {
              dispatch(LayoutActions.addSpots(data));
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

export const getOrganisationPlates =
  (locationId: string) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("FETCH_PLATES")) {
      api.platesAPI
        .getOrganisationPlates(locationId)
        .catch((error: any) => {
          console.log(error);
        })
        .then((res: any) => {
          const { data } = res;

          if (data) {
            dispatch(
              LayoutActions.setPlates(
                data.sort(
                  (
                    a: { id: string; serialNumber: number },
                    b: {
                      id: string;
                      serialNumber: number;
                    }
                  ) => a.serialNumber - b.serialNumber
                )
              )
            );
            // dispatch(LayoutActions.setPlates(data));
            // dispatch(LayoutActions.addLayout(data));
            // dispatch(LayoutActions.setDimensions(data));
          }
        });
    }
  };

export const plateAssociate =
  (plateId: string, locationId: string, spotId: string) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("ASSOCIATE_PLATE")) {
      api.platesAPI
        .plateAssociate(plateId, locationId, spotId)
        .catch((error: any) => {
          console.log(error);
        })
        .then((res: any) => {
          // const { data } = res;
          // if (data) {
          //   dispatch(LayoutActions.setPlates(data));
          // }
        });
    }
  };

export const initializeSpot =
  (
    spotId: string,
    formData: any,
    fromDate: string,
    untilDate: string,
    plateId?: string
  ) =>
  (dispatch: any, _: any, { api }: any) => {
    dispatch(DashboardSidepanelActions.setInitilizeLoading(true));
    api.spotsAPI
      .initializeSpot(spotId, formData)
      .then((res: any) => {
        dispatch(DashboardSidepanelActions.setInitilizeLoading(false));
        dispatch(plateAssociate(plateId ?? "", formData.venueId, spotId));
        dispatch(getLayout(formData.venueId));
        dispatch(getReservedSpots(formData.venueId, fromDate, untilDate));
        dispatch(getOrganisationPlates(formData.venueId));
        dispatch(DashboardSidepanelActions.resetSelectedSpots());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(DashboardSidepanelActions.setInitilizeLoading(false));
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
        dispatch(DashboardSidepanelActions.resetSelectedSpots());
      });
  };

export const markIssuedSpot =
  (reservationId: string, venueId: any, date: string) =>
  (dispatch: any, _: any, { api }: any) => {
    api.reservationsAPI
      .markIssuedSpot(reservationId)
      .then((res: any) => {
        dispatch(getLayout(venueId));
        dispatch(getReservedSpots(venueId, date, date));
        dispatch(DashboardSidepanelActions.resetSelectedSpots());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
      });
  };

export const reLinkSpot =
  (
    locationId: string,
    spotId: string,
    plateId: string,
    fromDate: string,
    untilDate: string
  ) =>
  (dispatch: any, _: any, { api }: any) => {
    api.spotsAPI
      .reLinkSpot(locationId, spotId, plateId)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data !== null) {
          dispatch(getLayout(locationId));
          dispatch(getReservedSpots(locationId, fromDate, untilDate));
          dispatch(DashboardSidepanelActions.resetSelectedSpots());
        }
      });
  };

export const updateSpot =
  (spotId: string, data: any, fromDate: string, untilDate: string) =>
  (dispatch: any, _: any, { api }: any) => {
    api.spotsAPI
      .updateSpot(spotId, data)
      .then((res: any) => {
        dispatch(getLayout(data.venueId));
        dispatch(getReservedSpots(data.venueId, fromDate, untilDate));
        dispatch(DashboardSidepanelActions.resetSelectedSpots());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
      });
  };

const cancelAllReservations =
  (spotIds: Array<string>) =>
  (dispatch: any, _: any, { api }: any) => {
    api.reservationsAPI
      .cancelAllReservations(spotIds)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data !== null) {
        }
      });
  };

export const getSpotSchedules =
  (spotId: string) =>
  (dispatch: any, _: any, { api }: any) => {
    api.spotsAPI
      .getSpotSchedules(spotId)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data !== null) {
          dispatch(LayoutActions.addSchedule(data));
        }
      });
  };

export const removeSchedule =
  (scheduleId: string, locationId: string, startOn: string, endOn: string) =>
  (dispatch: any, _: any, { api }: any) => {
    api.spotsAPI
      .removeSchedule(scheduleId)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data !== null) {
          dispatch(getLayout(locationId));
          dispatch(getReservedSpots(locationId, startOn, endOn));
          dispatch(DashboardSidepanelActions.resetSelectedSpots());
        }
      });
  };

export const removeSchedulesPromise =
  (schedules: any, locationId: string, startOn: string, endOn: string) =>
  async (dispatch: any, _: any, { api }: any) => {
    const deletePromises =
      schedules &&
      schedules.map((item: any) =>
        dispatch(removeSchedule(item.id, locationId, startOn, endOn))
      );

    try {
      await Promise.all(deletePromises);
    } catch (error) {
      console.log(error);
    }
  };

export const createSchedule =
  (
    locationId: string,
    spotIds: Array<string>,
    dates: Array<string>,
    startOn: string,
    endOn: string
  ) =>
  (dispatch: any, _: any, { api }: any) => {
    api.spotsAPI
      .createSchedule(locationId, spotIds, dates)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data !== null) {
          dispatch(getLayout(locationId));
          dispatch(getReservedSpots(locationId, startOn, endOn));
          dispatch(DashboardSidepanelActions.resetSelectedSpots());
          // TODO: to be discussed
          // dispatch(cancelAllReservations(spotIds));
        }
      });
  };

export const activateLocation =
  (locationId: string) =>
  (dispatch: any, _: any, { api }: any) => {
    api.locationsAPI
      .completeLocationConfiguration(locationId)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data !== null) {
          dispatch(LayoutActions.activateLocation());
        }
      });
  };

export const getLocationReservationsApi =
  (venueId: string, selectedDate: string) =>
  (dispatch: any, _: any, { api }: any) => {
    api.reservationsAPI
      .getLocationReservations(venueId, selectedDate)
      .then((res: any) => {
        const { data } = res;
        if (data !== null) {
          dispatch(LayoutActions.setReservations(data));
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
      });
  };

export const checkSpotAvailability =
  (afterDate: string, beforeDate: string, spotId?: string, plateId?: string) =>
  (dispatch: any, _: any, { api }: any) => {
    api.spotsAPI
      .checkSpotAvailability(afterDate, beforeDate, spotId, plateId)
      .catch((error: any) => {
        console.log(error);
      })
      .then((res: any) => {
        const { data } = res;
        if (data) {
          dispatch(LayoutActions.setSpotAvailability(data.available));
        }
      });
  };

export const checkReservation =
  (reservationId: string) =>
  (dispatch: any, _: any, { api }: any) => {
    // dispatch(LayoutActions.resetScannerData());
    api.ticketsAPI
      .scanTicket(reservationId)
      .catch((error: any) => {
        console.log(error);
        dispatch(
          LayoutActions.setScannerData({
            dates: [],
            status: "invalid",
          })
        );
      })
      .then((res: any) => {
        const { data } = res;
        if (data !== null) {
          dispatch(LayoutActions.setScannerData(data));
        }
      });
  };

export const completeReservation =
  (reservationId: string, venueId: string, selectedDate: string) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("MANAGE_RESERVATIONS")) {
      dispatch(DashboardSidepanelActions.setCompleteReservationLoading(true));
      api.reservationsAPI
        .completeReservation(reservationId)
        .then((res: any) => {
          dispatch(getLayout(venueId));
          dispatch(getReservedSpots(venueId, selectedDate, selectedDate));
          dispatch(getLocationReservationsApi(venueId, selectedDate));

          dispatch(DashboardSidepanelActions.resetSelectedSpots());
          dispatch(
            DashboardSidepanelActions.setCompleteReservationLoading(false)
          );
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(
            LayoutActions.snackbarState({
              show: true,
              isError: true,
              message: error.response.data.message,
            })
          );
          dispatch(
            DashboardSidepanelActions.setCompleteReservationLoading(false)
          );
        });
    }
  };

export const cancelReservation =
  (reservationId: string, venueId: string, selectedDate: string) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("MANAGE_RESERVATIONS")) {
      dispatch(DashboardSidepanelActions.setCancelReservationLoading(true));
      api.reservationsAPI
        .cancelReservation(reservationId)
        .then((res: any) => {
          dispatch(getLayout(venueId));
          dispatch(getReservedSpots(venueId, selectedDate, selectedDate));
          dispatch(getLocationReservationsApi(venueId, selectedDate));

          dispatch(DashboardSidepanelActions.resetSelectedSpots());
          dispatch(
            DashboardSidepanelActions.setCancelReservationLoading(false)
          );
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(
            LayoutActions.snackbarState({
              show: true,
              isError: true,
              message: error.response.data.message,
            })
          );
          dispatch(
            DashboardSidepanelActions.setCancelReservationLoading(false)
          );
        });
    }
  };

export const settingsSpot =
  (spotId: string) =>
  (dispatch: any, _: any, { api }: any) => {
    dispatch(DashboardSidepanelActions.setSpotSettingLoading(true));
    api.spotsAPI
      .settingsSpot(spotId)
      .then((res: any) => {
        const { data } = res;

        if (data) {
          dispatch(DashboardSidepanelActions.spotSettingData(data));
        }
        dispatch(DashboardSidepanelActions.setSpotSettingLoading(false));
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
        dispatch(DashboardSidepanelActions.setSpotSettingLoading(false));
      });
  };

export const associateSpot =
  (plateId: string, body: any, date: string) =>
  (dispatch: any, _: any, { api }: any) => {
    dispatch(DashboardSidepanelActions.setAccociateLoading(true));
    api.spotsAPI
      .accociateSpot(plateId, body)
      .then((res: any) => {
        dispatch(DashboardSidepanelActions.setAccociateLoading(false));
        dispatch(getLayout(body.venueId));
        dispatch(getReservedSpots(body.venueId, date, date));
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: false,
            message: "Spot associate successfully",
          })
        );
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
        dispatch(DashboardSidepanelActions.setAccociateLoading(false));
      });
  };

export const disassociateSpot =
  (plateId: string, venueId: string, date: string) =>
  (dispatch: any, _: any, { api }: any) => {
    dispatch(DashboardSidepanelActions.setAccociateLoading(true));
    api.spotsAPI
      .disassociateSpot(plateId)
      .then((res: any) => {
        dispatch(DashboardSidepanelActions.setAccociateLoading(false));
        dispatch(getLayout(venueId));
        dispatch(getReservedSpots(venueId, date, date));
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: false,
            message: "Spot disassociate successfully",
          })
        );
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
        dispatch(DashboardSidepanelActions.setAccociateLoading(false));
      });
  };
