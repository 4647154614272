import {
  LOADING,
  SHOW_SNACKBAR,
  ADD_SCHEDULE,
  REMOVE_SCHEDULE,
  RESET_SCANNER_DATA,
  RESET_SPOT_AVAILABILITY,
  REVOKE_RESERVATION,
  SET_NEW_RESERVATION,
  SET_RESERVATIONS,
  SET_SCANNER_DATA,
  SET_SPOT_AVAILABILITY,
  UPDATE_SPOT,
  ACTIVATE_LOCATION,
  ADD_LAYOUT,
  ADD_SPOTS,
  SET_DIMENSIONS,
  SET_PLATES,
  SET_SEARCH_RESULTS, ADD_SPOT_RESERVATION_NOTE, SET_SPOT_RESERVATION_NOTES,
} from "./types";

const loadingState = (isLoading: boolean) => ({
  type: LOADING,
  payload: isLoading,
});

const snackbarState = (snackbar: {
  show: boolean;
  isError: boolean;
  message: string;
}) => ({
  type: SHOW_SNACKBAR,
  payload: snackbar,
});

const addLayout = (payload: any) => ({
  type: ADD_LAYOUT,
  payload,
});

const addSpots = (payload: any) => ({
  type: ADD_SPOTS,
  payload,
});

const setDimensions = (payload: any) => ({
  type: SET_DIMENSIONS,
  payload,
});

const setPlates = (payload: any) => ({
  type: SET_PLATES,
  payload,
});

const setSearchResults = (payload: any) => ({
  type: SET_SEARCH_RESULTS,
  payload,
});

const activateLocation = () => ({
  type: ACTIVATE_LOCATION,
});

const setScannerData = (payload: any) => ({
  type: SET_SCANNER_DATA,
  payload,
});

const resetScannerData = () => ({
  type: RESET_SCANNER_DATA,
});

const setReservations = (payload: any) => ({
  type: SET_RESERVATIONS,
  payload,
});

const setNewReservation = (payload: any) => ({
  type: SET_NEW_RESERVATION,
  payload,
});

const revokeReservation = (payload: any) => ({
  type: REVOKE_RESERVATION,
  payload,
});

const updateSpot = (payload: any) => ({
  type: UPDATE_SPOT,
  payload,
});

const setSpotAvailability = (payload: any) => ({
  type: SET_SPOT_AVAILABILITY,
  payload,
});

const resetSpotAvailability = () => ({
  type: RESET_SPOT_AVAILABILITY,
});

const addSchedule = (payload: any) => ({
  type: ADD_SCHEDULE,
  payload,
});

const removeSchedule = (payload: any) => ({
  type: REMOVE_SCHEDULE,
  payload,
});

const addSpotReservationNote = (payload: any) => ({
  type: ADD_SPOT_RESERVATION_NOTE,
  payload,
});

const setSpotReservationNotes = (payload: any) => ({
    type: SET_SPOT_RESERVATION_NOTES,
    payload,
});

export const LayoutActions = {
  loadingState,
  snackbarState,
  addLayout,
  addSpots,
  setDimensions,
  setPlates,
  setSearchResults,
  activateLocation,
  setScannerData,
  resetScannerData,
  setReservations,
  setNewReservation,
  revokeReservation,
  updateSpot,
  setSpotAvailability,
  resetSpotAvailability,
  addSchedule,
  removeSchedule,
  addSpotReservationNote,
  setSpotReservationNotes
};
