import produce from "immer";

import {
  LOADING,
  SHOW_SNACKBAR,
  ADD_LAYOUT,
  ADD_SPOTS,
  RESET_SCANNER_DATA,
  SET_DIMENSIONS,
  SET_PLATES,
  SET_SCANNER_DATA,
  SET_SEARCH_RESULTS,
  SET_RESERVATIONS,
  SET_NEW_RESERVATION,
  REVOKE_RESERVATION,
  UPDATE_SPOT,
  SET_SPOT_AVAILABILITY,
  RESET_SPOT_AVAILABILITY,
  ADD_SCHEDULE,
  REMOVE_SCHEDULE, SET_SPOT_RESERVATION_NOTES, ADD_SPOT_RESERVATION_NOTE,
} from "./types";

interface Dimensions {
  width: number;
  height: number;
}

interface InitialState {
  loading: boolean;
  dimensions: Dimensions;
  builder: Array<any>;
  spots: Array<any>;
  selectedSpotNotes: Array<any>;
  plates: Array<any>;
  searchResults: Array<any> | null;
  scannerData: Array<any> | null;
  spotAvailability: boolean | null;
  reservations: Array<any> | null;
  schedules: Array<any> | null;
  snackbar: Object;
}

const initialState: InitialState = <InitialState>{
  loading: false,
  dimensions: {
    width: 0,
    height: 0,
  },
  builder: [],
  spots: [],
  plates: [],
  searchResults: [],
  scannerData: null,
  spotAvailability: null,
  selectedSpotNotes: [],
  reservations: null,
  schedules: null,
  snackbar: {
    show: false,
    isError: false,
    message: "",
  },
};

export default function (state = initialState, action: any) {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case LOADING: {
        draft.loading = payload;
        break;
      }
      case ADD_LAYOUT: {
        draft.builder = payload.elements;
        break;
      }
      case ADD_SPOTS: {
        draft.spots = payload;
        break;
      }
      case SET_DIMENSIONS: {
        draft.dimensions.width = payload.width;
        draft.dimensions.height = payload.height;
        break;
      }
      case SET_PLATES: {
        draft.plates = payload;
        break;
      }
      case SET_SEARCH_RESULTS: {
        draft.searchResults = payload;
        break;
      }
      case SET_SCANNER_DATA: {
        draft.scannerData = payload;
        break;
      }
      case RESET_SCANNER_DATA: {
        draft.searchResults = null;
        break;
      }
      case SET_RESERVATIONS: {
        draft.reservations = payload;
        break;
      }
      case SET_NEW_RESERVATION: {
        draft.reservations?.push(...payload);
        break;
      }
      case REVOKE_RESERVATION: {
        const { id } = payload;
        const index: any = draft.reservations?.findIndex(
          (reservation: any) => reservation.id === id
        );

        if (index !== -1 && draft && draft.reservations)
          draft.reservations[index] = payload;
        break;
      }

      case UPDATE_SPOT: {
        const { spotId } = payload;
        const index: any = draft.spots?.findIndex(
          (spot: any) => spot.id === spotId
        );

        // change the edit with more generic
        if (index !== -1 && draft && draft.spots)
          draft.spots[index].reserved = false;
        break;
      }
      case SET_SPOT_AVAILABILITY: {
        draft.spotAvailability = payload;
        break;
      }
      case RESET_SPOT_AVAILABILITY: {
        draft.spotAvailability = null;
        break;
      }
      case ADD_SCHEDULE: {
        draft.schedules = payload;
        break;
      }
      case REMOVE_SCHEDULE: {
        draft.schedules = payload;
        break;
      }
      case SHOW_SNACKBAR:
        draft.snackbar = payload;
        break;
      case SET_SPOT_RESERVATION_NOTES:
        draft.selectedSpotNotes = payload;
        break;
      default:
        return state;
    }
  });
}
