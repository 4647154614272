import { Box, Typography } from "@mui/material"

const ComingSoon = () => {
    return (
        <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,.4)',
            zIndex: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Typography variant="h1" sx={{
                fontWeight: '700',
                color: '#ABFFC1',
                textShadow: '1px 1px #044D5E'
            }}>Typo
                Coming soon
            </Typography>
        </Box>
    )
}

export default ComingSoon;