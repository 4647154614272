import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getActiveLocationMetadata,} from "../../../store/locations/selectors";
import {useAppDispatch} from "../../../hooks";
import {
    cancelReservation,
    checkReservation,
    completeReservation,
    getLayout,
    getLocationReservationsApi,
    getOrganisationPlates,
    getReservedSpots,
    markIssuedSpot,
} from "./async-actions";
import {Alert, Box, Button, CircularProgress, Grid, Snackbar,} from "@mui/material";
import Header from "./Header";
import Calendar from "./Calendar";
import Information from "./Information";
import Layout from "./Layout";
import {
    getCancelReservationLoading,
    getCompleteReservationLoading,
    getInitilizeLoading,
    getReservationLoading,
    getSelectedDate,
    getSelectedInitilizeSpot,
    getSelectedReservedSpot,
    getSelectedSpots,
} from "../sidepanel/selectors";
import {getSingleLocation} from "../../../store/locations/async-actions";
import ActivateLocation from "./ActivateLocation";
import MobileHeader from "./MobileHeader";
import Scanner from "./Scanner";
import ScannerModal from "./ScannerModal";
import {getLocationReservationById, getSnackbar} from "./selector";
import {useMedia} from "../../../utils";
import MakeReservationModal from "./MakeReservationModal";
import ConfirmReservationModal from "../beach-location/ConfirmReservationModal";
import MarkReceiptIssued from "../beach-location/MarkReceiptIssued";

import {getLocationReservations} from "./selector.ts";

import DashboardSidepanel from "../sidepanel/DashboardSidepanel";

import {LayoutActions} from "./actions";

import {hasPermission} from "../../../store/permissions";
import {AddReservationNotesModal} from "./AddReservationNotesModal.tsx";
import {createReservation} from "../sidepanel/async-actions.ts";
import CreateFiscalReceiptForAmountModal from "./CreateFiscalReceiptForAmountModal.tsx";

const TabPanel = (props: any) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

const BeachLocation = () => {
    const {desktop} = useMedia();

    const snackbar = useSelector(getSnackbar);

    const dispatch = useAppDispatch();
    const [tab, setTab] = useState(0);
    const [showScanner, setShowScanner] = useState(false);
    const [showCreateFiscalReceiptForAmountModal, setShowCreateFiscalReceiptForAmountModal] = useState(false);
    const [scanResult, setScanResult] = useState(null);
    const [rotateLayout, setRotateLayout] = useState(() => {
        const savedRotateLayout = localStorage.getItem("rotateLayout");
        return savedRotateLayout ? JSON.parse(savedRotateLayout) : false;
    });

    const activeLocation = useSelector(getActiveLocationMetadata);

    const reservations = useSelector(getLocationReservations);

    const initilizeLoading = useSelector(getInitilizeLoading);

    const reservationLoading = useSelector(getReservationLoading);

    const [isCompleteReservationOpened, setCompleteReservationModel] =
        useState(false);

    const [isMarkIssuedOpened, setMarkIssuedModel] = useState(false);

    const [isReservationNoteModalOpened, setReservationNoteModalOpened] =
        useState(false);

    const [isCancelReservationOpened, setCancelReservationModel] =
        useState(false);

    const completeReservationLoading = useSelector(getCompleteReservationLoading);
    const cancelReservationLoading = useSelector(getCancelReservationLoading);

    const [isReservationModelOpened, setReservationModelOpened] = useState(false);
    const selectedDate = useSelector(getSelectedDate);

    const handleShowScanner = () => {
        setScanResult(null);
        setShowScanner(!showScanner);
    };

    const handleShowCreateFiscalReceiptForAmountModal = () => {
        setShowCreateFiscalReceiptForAmountModal(true);
    };

    const openReservationNoteModal = () => {
        setReservationNoteModalOpened(true);
    };

    const handleReservationNoteCancel = () => {
        setReservationNoteModalOpened(false);
    };

    useEffect(() => {
        const fetchData = () => {
            if (activeLocation) {
                dispatch(getLayout(activeLocation.id));
                dispatch(getOrganisationPlates(activeLocation.id));
                dispatch(getSingleLocation(activeLocation.id));
                dispatch(
                    getReservedSpots(activeLocation.id, selectedDate, selectedDate)
                );
                dispatch(getLocationReservationsApi(activeLocation.id, selectedDate));
            }
        };

        // Initial fetch
        fetchData();

        // Set up interval for subsequent fetches
        const interval = setInterval(() => {
            dispatch(getSingleLocation(activeLocation.id));
            dispatch(getReservedSpots(activeLocation.id, selectedDate, selectedDate));
            dispatch(getLocationReservationsApi(activeLocation.id, selectedDate));
        }, 30000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, [activeLocation, selectedDate]);


    const handleTabChange = (_: any, newValue: number) => {
        setTab(newValue);
    };

    const handleRotateLayout = () => {
        const newRotateLayout = !rotateLayout;
        setRotateLayout(newRotateLayout);
        localStorage.setItem("rotateLayout", JSON.stringify(newRotateLayout));
    };

    const handleScanResult = (result: any) => {
        setScanResult(result);
        setShowScanner(!showScanner);
        dispatch(checkReservation(result));
    };

    const handleModalClose = () => {
        setScanResult(null);
    };

    const handleCheckAgain = () => {
        handleShowScanner();
    };

    const selectedSpots = useSelector(getSelectedSpots);
    const selectedInitilizeSpot = useSelector(getSelectedInitilizeSpot);
    const selectedReservedSpot = useSelector(getSelectedReservedSpot);

    // const isLocationActive = useSelector(getIsLocationActive);

    const reservation = useSelector((state: any) =>
        getLocationReservationById(
            state,
            selectedReservedSpot && selectedReservedSpot.id
        )
    );

    // const date = useSelector(getSelectedDate);

    const [isInitializeSpotOpened, setIsInitializeSpotOpened] = useState(false);
    const handleInitializeSpot = () => {
        setIsInitializeSpotOpened(!isInitializeSpotOpened);
    };

    const completeReservationModel = () => {
        setCompleteReservationModel(!isCompleteReservationOpened);
    };

    const cancelReservationModel = () => {
        setCancelReservationModel(!isCancelReservationOpened);
    };

    const handleCompleteReservation = () => {
        var spotReserve = reservations.find(
            (e: any) => e.spotId == selectedReservedSpot.id && e.status == "ACTIVE"
        );

        dispatch(
            completeReservation(spotReserve.id, activeLocation.id, selectedDate)
        );
        completeReservationModel();
    };

    const handleCancelReservation = () => {
        var spotReserve = reservations.find(
            (e: any) => e.spotId == selectedReservedSpot.id && e.status == "ACTIVE"
        );

        dispatch(
            cancelReservation(spotReserve.id, activeLocation.id, selectedDate)
        );
        cancelReservationModel();
    };

    const markIssueReservationModel = () => {
        setMarkIssuedModel(!isMarkIssuedOpened);
    };

    const cancelMarkIssueReservationModel = (error?: any) => {
        console.log(`Is there error ? `, JSON.stringify(error));
        if (error) {
            dispatch(
                LayoutActions.snackbarState({
                    show: true,
                    isError: true,
                    message: 'Проблем с връзката с фискалното устройство, опитайте отново'
                })
            );
        }
        setMarkIssuedModel(!isMarkIssuedOpened);
    };

    const handleMarkReservationReceiptIssued = () => {
        dispatch(markIssuedSpot(reservation.id, activeLocation.id, selectedDate));
        dispatch(getSingleLocation(activeLocation.id));
        dispatch(getReservedSpots(activeLocation.id, selectedDate, selectedDate));
        dispatch(getLocationReservationsApi(activeLocation.id, selectedDate));
        cancelMarkIssueReservationModel();
    };

    const makeReservationModal = () => {
        setReservationModelOpened(!isReservationModelOpened);
    };

    const handleReserve = (data: any) => {
        makeReservationModal();
        dispatch(createReservation(data));
    };

    const handleSnackBarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(
            LayoutActions.snackbarState({
                show: false,
                isError: snackbar.isError,
                message: "",
            })
        );
    };

    return (
        <Box
            sx={{
                padding: desktop ? "0 24px" : "0",
                height: "100%",
                // overflowX: 'hidden'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={9}>
                    <ActivateLocation/>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            background: "#fff",
                        }}
                    >
                        {desktop ? (
                            <Header tab={tab} handleTabChange={handleTabChange}/>
                        ) : (
                            <MobileHeader
                                isInitializeSpotOpened={isInitializeSpotOpened}
                                setIsInitializeSpotOpened={setIsInitializeSpotOpened}
                                activeTab={tab}
                                handleTabChange={handleTabChange}
                            />
                        )}
                        <Box sx={{width: "100%"}}>
                            <TabPanel value={tab} index={0}>
                                <Calendar/>
                                {/* <Button onClick={handleRotateLayout}>Завърти плажа</Button> */}

                                <Layout
                                    rotate={rotateLayout}
                                    handleRotate={handleRotateLayout}
                                />

                                {/* {rotateLayout ? (
                  <>
                    <Sea rotate={rotateLayout} />
                    <Layout rotate={rotateLayout} />
                  </>
                ) : (
                  <>
                    <Layout rotate={rotateLayout} />
                    <Sea rotate={rotateLayout} />
                  </>
                )} */}
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                {activeLocation && <Information location={activeLocation}/>}
                                {/* {activeLocationInformation && (
                  <Information location={activeLocationInformation} />
                )} */}
                            </TabPanel>
                        </Box>
                    </Box>
                    <br/>
                    <br/>

                    {!desktop && (
                        <>
                            <Box
                                sx={{
                                    background: "#fff",
                                    position: "fixed",
                                    bottom: 0,
                                    left: 0,
                                    width: "100%",
                                    gap: 2,
                                    textAlign: "center",
                                }}
                            >
                                {!selectedReservedSpot &&
                                    !selectedInitilizeSpot &&
                                    (selectedSpots.length !== 1 ||
                                        !!selectedSpots[0].disabled) && (
                                        <Button
                                            sx={{
                                                background: "#ABFFC1",
                                                color: "#044D5E",
                                                margin: "10px",
                                            }}
                                            onClick={handleShowScanner}
                                        >
                                            Сканирай
                                        </Button>
                                    )}
                                {!selectedReservedSpot &&
                                    !selectedInitilizeSpot &&
                                    (selectedSpots.length !== 1 ||
                                        !!selectedSpots[0].disabled) && (
                                        <Button
                                            sx={{
                                                background: "#ABFFC1",
                                                color: "#044D5E",
                                                margin: "10px",
                                            }}
                                            onClick={handleShowCreateFiscalReceiptForAmountModal}
                                        >
                                            Принтирай касов бон
                                        </Button>
                                    )}
                            </Box>

                            {/* Make a reservation */}
                            <Box
                                sx={{
                                    background: "#fff",
                                    position: "fixed",
                                    bottom: 0,
                                    left: 0,
                                    width: "100%",
                                    gap: 2,
                                    textAlign: "center",
                                }}
                            >
                                {selectedInitilizeSpot &&
                                    hasPermission("CREATE_RESERVATION") && (
                                        <Box
                                            sx={{
                                                position: "relative",
                                                textAlign: "center",
                                                width: "100%",
                                                margin: "10px",
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    background: "#044D5E",
                                                    color: "#C5FFFF",
                                                    fontWeight: "500",
                                                    fontSize: "11px",
                                                    width: "48%",
                                                    margin: "5px",
                                                    "&[disabled]": {
                                                        background: "#EDEFF2",
                                                        color: "#06191E",
                                                        opacity: "0.2",
                                                    },
                                                }}
                                                disabled={reservationLoading}
                                                onClick={makeReservationModal}
                                            >
                                                Направи резервация
                                            </Button>

                                            <Button
                                                sx={{
                                                    margin:"auto",
                                                    fontWeight: "500",
                                                    fontSize: "11px",
                                                    width: "48%",
                                                    background: "#fff8ab",
                                                    color: "#044D5E",
                                                    "&:disabled": {
                                                        background: "#EDEFF2",
                                                        color: "#5A6977",
                                                    },
                                                }}
                                                disabled={cancelReservationLoading}
                                                onClick={openReservationNoteModal}
                                            >
                                                Бележки
                                            </Button>

                                            {reservationLoading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        marginTop: "-10px",
                                                        marginLeft: "-16px",
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    )}
                                <Box
                                    sx={{
                                        position: "relative",
                                        display: "flex",
                                        width: "100%",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    {cancelReservationLoading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                marginTop: "-10px",
                                                marginLeft: "-16px",
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>

                            {/* Initilize Spot */}
                            <Box
                                sx={{
                                    background: "#fff",
                                    position: "fixed",
                                    bottom: 0,
                                    left: 0,
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                {!selectedInitilizeSpot && selectedSpots.length === 1 && (
                                    <Box
                                        sx={{
                                            position: "relative",
                                            textAlign: "center",
                                            width: "100%",
                                            margin: "10px",
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                flex: 1,
                                                background: "#C5FFFF",
                                                color: "#044D5E",
                                                margin: "auto",
                                                "&[disabled]": {
                                                    background: "#EDEFF2",
                                                    color: "#06191E",
                                                    opacity: "0.2",
                                                },
                                            }}
                                            disabled={initilizeLoading}
                                            onClick={handleInitializeSpot}
                                        >
                                            Активиране
                                        </Button>

                                        {initilizeLoading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: "-10px",
                                                    marginLeft: "-16px",
                                                }}
                                            />
                                        )}
                                    </Box>
                                )}
                            </Box>

                            {/* Mark Receipt Issued, Complete Booking and Cancel Booking */}
                            <Box
                                sx={{
                                    background: "#fff",
                                    position: "fixed",
                                    bottom: 0,
                                    left: 0,
                                    width: "100%",
                                    padding: "0px 10px",
                                }}
                            >
                                {/* Mark Receipt Issued */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        background: "#fff",
                                        width: "100%",
                                    }}
                                >
                                    {/* Complete Booking */}
                                    {selectedReservedSpot &&
                                        hasPermission("MANAGE_RESERVATIONS") && (
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    display: "flex",
                                                    width: "48%",
                                                    marginTop: "10px",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Button
                                                    sx={{
                                                        background: "#C5FFFF",
                                                        color: "#044D5E",
                                                        width: "100%",
                                                        fontSize: "11px",
                                                        "&:disabled": {
                                                            background: "#EDEFF2",
                                                            color: "#5A6977",
                                                        },
                                                    }}
                                                    onClick={handleMarkReservationReceiptIssued}
                                                >
                                                    Фискален бон за комплект
                                                </Button>

                                                {completeReservationLoading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            marginTop: "-10px",
                                                            marginLeft: "-16px",
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )}

                                    {/* Cancel Booking */}
                                    {selectedReservedSpot &&
                                        hasPermission("MANAGE_RESERVATIONS") && (
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    display: "flex",
                                                    width: "48%",
                                                    marginTop: "10px",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Button
                                                    sx={{
                                                        fontWeight: "500",
                                                        fontSize: "11px",
                                                        background: "#fff8ab",
                                                        width: "100%",
                                                        color: "#044D5E",
                                                        "&:disabled": {
                                                            background: "#EDEFF2",
                                                            color: "#5A6977",
                                                        },
                                                    }}
                                                    disabled={cancelReservationLoading}
                                                    onClick={openReservationNoteModal}
                                                >
                                                    Бележки
                                                </Button>

                                                {cancelReservationLoading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            marginTop: "-10px",
                                                            marginLeft: "-16px",
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        background: "#fff",
                                        width: "100%",
                                    }}
                                >
                                    {/* Complete Booking */}
                                    {selectedReservedSpot &&
                                        hasPermission("MANAGE_RESERVATIONS") && (
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    display: "flex",
                                                    width: "48%",
                                                    marginTop: "10px",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Button
                                                    sx={{
                                                        background: "#ABFFC1",
                                                        color: "#044D5E",
                                                        width: "100%",
                                                        fontSize: "11px",
                                                        "&:disabled": {
                                                            background: "#EDEFF2",
                                                            color: "#5A6977",
                                                        },
                                                    }}
                                                    disabled={completeReservationLoading}
                                                    onClick={completeReservationModel}
                                                >
                                                    Завърши резервация
                                                </Button>

                                                {completeReservationLoading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            marginTop: "-10px",
                                                            marginLeft: "-16px",
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )}

                                    {/* Cancel Booking */}
                                    {selectedReservedSpot &&
                                        hasPermission("MANAGE_RESERVATIONS") && (
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    display: "flex",
                                                    width: "48%",
                                                    marginTop: "10px",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Button
                                                    sx={{
                                                        fontWeight: "500",
                                                        fontSize: "11px",
                                                        background: "#ee5a5a",
                                                        width: "100%",
                                                        color: "#fff",
                                                        "&:disabled": {
                                                            background: "#EDEFF2",
                                                            color: "#5A6977",
                                                        },
                                                    }}
                                                    disabled={cancelReservationLoading}
                                                    onClick={cancelReservationModel}
                                                >
                                                    Отмени резервация
                                                </Button>

                                                {cancelReservationLoading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            marginTop: "-10px",
                                                            marginLeft: "-16px",
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                </Box>
                            </Box>

                            {showScanner && !scanResult && (
                                <Scanner
                                    result={scanResult}
                                    handleResult={handleScanResult}
                                    onClose={handleShowScanner}
                                />
                            )}
                            {scanResult && (
                                <ScannerModal
                                    handleClose={handleModalClose}
                                    handleCheckAgain={handleCheckAgain}
                                />
                            )}
                            {showCreateFiscalReceiptForAmountModal && (
                                <CreateFiscalReceiptForAmountModal
                                    open={showCreateFiscalReceiptForAmountModal}
                                    onClose={() => setShowCreateFiscalReceiptForAmountModal(false)}
                                />
                            )}
                        </>
                    )}
                </Grid>

                {desktop && (
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                paddingTop: "40px",
                            }}
                        >
                            <DashboardSidepanel/>
                        </Box>
                    </Grid>
                )}
            </Grid>

            {isReservationModelOpened && (
                <MakeReservationModal
                    handleReservation={handleReserve}
                    handleClose={makeReservationModal}
                />
            )}

            {isCompleteReservationOpened && (
                <ConfirmReservationModal
                    isComplete={true}
                    handleConfirm={handleCompleteReservation}
                    handleClose={completeReservationModel}
                />
            )}

            {isMarkIssuedOpened && (
                <MarkReceiptIssued
                    handleConfirm={handleMarkReservationReceiptIssued}
                    handleClose={cancelMarkIssueReservationModel}
                />
            )}

            {isReservationNoteModalOpened && (
                <AddReservationNotesModal
                    open={isReservationNoteModalOpened}
                    onClose={handleReservationNoteCancel}
                />
            )}

            {isCancelReservationOpened && (
                <ConfirmReservationModal
                    isComplete={false}
                    handleConfirm={handleCancelReservation}
                    handleClose={cancelReservationModel}
                />
            )}

            <Snackbar
                open={snackbar.show}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
            >
                <Alert
                    severity={snackbar.isError ? "error" : "success"}
                    variant="filled"
                    sx={{width: "100%"}}
                    onClose={handleSnackBarClose}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default BeachLocation;
