const ACTION_TYPE = "LOCATIONS";
export const ADD_LOCATIONS = `${ACTION_TYPE}/ADD_LOCATIONS`;
export const ADD_ACTIVE_LOCATION = `${ACTION_TYPE}/ADD_ACTIVE_LOCATION`;
export const ADD_LOCATION_INFORMATION = `${ACTION_TYPE}/ADD_LOCATION_INFORMATION`;
export const CHANGE_ACTIVE_LOCATION = `${ACTION_TYPE}/CHANGE_ACTIVE_LOCATION`;


export type Location = {
    id: string;
    name: string;
    description: string
    amenities: Array<string>;
    imageUrls: Array<string>;
    latitude: string;
    longitude: string;
    city: string;
    active: boolean;
    configured: boolean;
}

export type EmploymentLocation = {
    id: string;
    name: string;
}