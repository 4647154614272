import { LayoutActions } from "./actions";

export const getAllStatistics =
  (venueId: string, dateFrom: string, dateTo: string) =>
  async (dispatch: any, _: any, { api }: any) => {
    dispatch(LayoutActions.loadingState(true));
    api.statisticsAPI
      .getStatistics(venueId, dateFrom, dateTo)
      .then((res: any) => {
        const { data } = res;
        if (data !== null) {
          dispatch(LayoutActions.setStatisticsData(data));
        }

        dispatch(LayoutActions.loadingState(false));
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(
          LayoutActions.snackbarState({
            show: true,
            isError: true,
            message: error.response.data.message,
          })
        );
        dispatch(LayoutActions.loadingState(false));
      });

    // dispatch(
    //   LayoutActions.setStatisticsData({
    //     venueId: "5f3045a7-a4a2-4ef2-9b1b-f57756f9da29",
    //     dateFrom: "2023-06-01",
    //     dateTo: "2023-10-30",
    //     completedOnlineReservationsRevenue: "117",
    //     refundedOnlineReservationsAmount: "20",
    //     activeOnlineReservationsRevenue: "0",
    //     completedOnSiteReservationsRevenue: "0",
    //     activeOnSiteReservationsRevenue: "0",
    //   })
    // );
  };
