import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Tab, Tabs } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Info, Map } from '@mui/icons-material';
import { checkSpotAvailability } from './async-actions';
import { getPlates, getSpots } from './selector';
import { useAppDispatch } from '../../../hooks';
import { getSelectedSpots } from '../sidepanel/selectors';
import { LayoutActions } from '../beach-location/actions';
import CheckReservationModal from './CheckReservation';

const Header = ({
    tab,
    handleTabChange
}: any) => {
    const dispatch = useAppDispatch();
    const selectedSpots = useSelector(getSelectedSpots);
    const spots = useSelector(getSpots);
    const [selectedSpot, setSelectedSpot] = useState(null) as any;
    const plates = useSelector(getPlates);
    useEffect(() => {
        setSelectedSpot(selectedSpots[0]);
    }, [selectedSpots])

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchTermChange = (event: any) => {
        setSearchTerm(event.target.value);
        const filtered = spots.filter((spot: any) => {
            return event.target.value === '' ? null : spot.label.includes(event.target.value);
        });
        dispatch(LayoutActions.setSearchResults(filtered));
    }

    const handleClick = () => {
        setSearchTerm('');
        dispatch(LayoutActions.setSearchResults([]));
    }

    const [isCheckReservationOpened, setIsCheckReservationOpened] = useState(false);

    const handleCheckReservationOpen = () => {
        setIsCheckReservationOpened(true);
    }

    const handleCheckReservationClose = () => {
        setIsCheckReservationOpened(false);
        handleAvailabilityReset();
    }

    const handleCheckReservation = (afterDate: string, beforeDate: string, spotId?: string, plateId?: string) => {
        const selectedPlate = plates.find((plate: any) => plate.tag === plateId);
        dispatch(checkSpotAvailability(afterDate, beforeDate, spotId, selectedPlate.id));
    }

    const handleAvailabilityReset = () => {
        dispatch(LayoutActions.resetSpotAvailability());
    }
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '24px'
        }}>
            <Box sx={{
                flex: 1
            }}>
                <FormControl variant="standard">
                    <FormControl sx={{ m: 0, width: '25ch' }} variant="outlined">
                        <InputLabel
                            htmlFor="outlined-adornment-search"
                            sx={{
                                top: '-7px'
                            }}>Търсене</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-search"
                            value={searchTerm}
                            size='small'
                            onChange={handleSearchTermChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle search visibility"
                                        onClick={handleClick}
                                        edge="end"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                </FormControl>
            </Box>
            <Box>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            justifyContent: 'center'
                        },
                        '& .MuiButtonBase-root.MuiTab-root': {
                            color: 'rgba(0, 0, 0, .5)',
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '24px',
                            letterSpacing: '.4px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            minHeight: '48px',
                            '&.Mui-selected': {
                                color: '#C5FFFF',
                                backgroundColor: '#044D5E',
                                borderBottom: 'none'
                            },
                            '&:not(.Mui-selected)': {
                                backgroundColor: '#EDEFF2'
                            },
                        },
                        '& .MuiTabs-indicator': {
                            display: 'none'
                        }
                    }}
                >
                    <Tab
                        icon={<Map sx={{ mr: 1, mb: 0 }} />}
                        label='Карта'
                        sx={{
                            borderRadius: '8px 0 0 8px',
                        }}
                    />
                    <Tab
                        icon={<Info sx={{ mr: 1, mb: 0 }} />}
                        label="Информация"
                        sx={{
                            borderRadius: '0 8px 8px 0',
                        }}
                    />
                </Tabs>
            </Box>
            <Box sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'end',
                cursor: !selectedSpot || (selectedSpot && selectedSpot?.label) ? 'auto' : 'pointer'
            }}>
                <Button
                    sx={{
                        mr: 2,
                        background: '#C5FFFF',
                        color: '#044D5E',
                        '&[disabled]': {
                            background: '#EDEFF2',
                            color: '#06191E'
                        }
                    }}
                    onClick={handleCheckReservationOpen}
                >
                    Провери резервация
                </Button>
            </Box>
            <CheckReservationModal
                selectedSpot={selectedSpot}
                isOpen={isCheckReservationOpened}
                handleCheckReservation={handleCheckReservation}
                handleClose={handleCheckReservationClose}
                handleAvailabilityReset={handleAvailabilityReset}
            />
        </Box>
    )
}

export default Header;