import produce from "immer";
import {
  APPROVED_LAYOUT,
  PENDING_LAYOUT,
  VENUE_DETAIL_LAYOUT,
  SAVE_LAYOUT,
  LOADING,
} from "./types";

interface InitialState {
  list: Array<any>;
  detail: Object | null;
  saved_layout: Boolean;
  loading: Boolean;
}
const initialState: InitialState = <InitialState>{
  list: [],
  detail: {},
  saved_layout: false,
  loading: false,
};

export default function (state = initialState, action: any) {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case PENDING_LAYOUT: {
        draft.list = payload;
        break;
      }
      case APPROVED_LAYOUT: {
        draft.list = payload;
        break;
      }
      case VENUE_DETAIL_LAYOUT: {
        // draft.detail = {
        //   "rows": 12,
        //   "columns": 27,
        //   "gridCellSize": 32,
        //   "content": "{\"objects\": []}"
        // }
        draft.detail = payload;
        // console.log("draft.detail : ",JSON.stringify(payload));
        break;
      }
      case SAVE_LAYOUT: {
        draft.saved_layout = !draft.saved_layout;
        break;
      }
      case LOADING: {
        draft.loading = payload;
        break;
      }
      default:
        return state;
    }
  });
}
