import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Chip,
} from "@mui/material";
import { useAppDispatch } from "../../hooks";
import { useEffect, useState } from "react";
import { createEmployee, getRoles, updateEmployee } from "./async-actions";
import { useSelector } from "react-redux";
import { getEmployeesRoles, getLoading, getSnackbar } from "./selectors";
import { getLocationsList } from "../../store/locations/selectors";
import { ChevronLeft } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { EmployeesActions } from "./actions";
import { getActiveLocationMetadata } from "../../store/locations/selectors";
import { useLocation } from "react-router-dom";
import { hasPermission } from "../../store/permissions";

interface Role {
  id: number;
  name: string;
}

const CreateEmployee = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const location = useLocation();

  const initialData = location.state ? location.state.row : null;

  const loading = useSelector(getLoading);

  const snackbar = useSelector(getSnackbar);

  const [inputError, setInputError] = useState<string>("");

  const activeLocation = useSelector(getActiveLocationMetadata);

  const roles = useSelector(getEmployeesRoles);
  const locations = useSelector(getLocationsList);
  const [userId, setUserId] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);

  useEffect(() => {
    if (!hasPermission("CREATE_EMPLOYEE")) {
      navigate("/");
    }
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    if (initialData) {
      setUserId(initialData.id);
      setFirstname(initialData.firstname);
      setLastname(initialData.lastname);
      setEmail(initialData.email);
      checkAndSelectRole(initialData.role);
    }
  }, [initialData, roles]);

  const handleChange = (role: any) => {
    setSelectedRole(role);
  };

  const checkAndSelectRole = (roleName: string) => {
    const role = roles.find((item: Role) => item.name === roleName);

    if (role) {
      setSelectedRole(role);
    } else {
      console.warn(`Role with name "${roleName}" not found.`);
      setSelectedRole(null); // or handle as you see fit
    }
  };

  // State to keep track of checked locations
  const [checkedLocations, setCheckedLocations] = useState({}) as any;

  // Function to handle checkbox changes
  const handleCheckboxChange = (locationId: any) => (event: any) => {
    setCheckedLocations((prevState: any) => ({
      ...prevState,
      [locationId]: event.target.checked,
    }));
  };

  const handleSnackBarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(
      EmployeesActions.snackbarState({
        show: false,
        isError: snackbar.isError,
        message: "",
      })
    );
  };

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstname) {
      setInputError("First name is required.");
      return false;
    }

    if (!lastname) {
      setInputError("Last name is required.");
      return false;
    }

    if (!email) {
      setInputError("Email is required.");
      return false;
    } else if (!emailRegex.test(email)) {
      setInputError("Please provide a valid email address.");
      return false;
    }

    if (!selectedRole || !selectedRole.name) {
      setInputError("Please select a role.");
      return false;
    }

    setInputError("");
    return true;
  };

  const handleCreateEmployee = () => {
    const isValid = validate();
    if (!isValid) return;

    if (userId) {
      const formData = {
        venueId: activeLocation.id,
        role: selectedRole?.name,
      };

      dispatch(updateEmployee(userId, formData));
    } else {
      const formData = {
        role: selectedRole?.name,
        email: email,
        firstname: firstname,
        lastname: lastname,
        venueId: activeLocation.id,
        venueName: activeLocation.name,
      };

      dispatch(createEmployee(formData));
      setSelectedRole(null);
      setFirstname("");
      setLastname("");
      setEmail("");
      setCheckedLocations({});
    }
  };

  const StyledBackLink = styled(Link).attrs((props: any) => ({
    to: props.to || "#",
  }))`
    display: inline-flex;
    align-items: center;
  `;

  const StyledCancelLink = styled(Link).attrs((props: any) => ({
    to: props.to || "#",
  }))`
    display: inline-flex;
    align-items: center;
    border: 1px solid #06191e;
    color: #06191e;
    padding: 4px 8px;
    font-size: 0.875rem;
    line-height: 1.75;
    border-radius: 2px;
  `;

  return (
    <Box sx={{ pt: 3, pr: 3, pl: 3 }}>
      <Box
        sx={{
          background: "#fff",
          p: { xs: 0, sm: 3 },
          borderRadius: 2,
        }}
      >
        <Box>
          <StyledBackLink to="/user-management">
            <IconButton>
              <ChevronLeft />
            </IconButton>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "48px",
                letterSpacing: ".1px",
                ml: 1,
                color: "#06191E",
              }}
            >
              Към потребители
            </Typography>
          </StyledBackLink>
        </Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "48px",
            letterSpacing: ".1px",
            mt: 2,
            color: "#06191E",
          }}
        >
          Нов потребител
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <TextField
            label="Име"
            value={firstname}
            disabled={userId !== ""}
            onChange={(event) => setFirstname(event.target.value)}
            sx={{ flex: 1 }}
          />
          <TextField
            label="Фамилия"
            value={lastname}
            disabled={userId !== ""}
            onChange={(event) => setLastname(event.target.value)}
            sx={{ flex: 1 }}
          />
          <TextField
            label="Имейл"
            value={email}
            type="email"
            disabled={userId !== ""}
            onChange={(event) => setEmail(event.target.value)}
            sx={{ flex: 1 }}
          />
        </Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "48px",
            letterSpacing: ".1px",
            mt: 2,
            color: "#06191E",
          }}
        >
          Избери роля и локация
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "48px",
                letterSpacing: ".1px",
                m: 0,
                color: "#06191E",
              }}
            >
              Роля
            </Typography>
            <FormControl>
              <RadioGroup name="radio-buttons-group">
                {roles &&
                  roles.map((role: Role) => (
                    <FormControlLabel
                      checked={selectedRole?.name === role.name}
                      key={role.id}
                      value={role.name}
                      control={<Radio />}
                      label={role.name}
                      onClick={() => handleChange(role)}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "48px",
                letterSpacing: ".1px",
                m: 0,
                color: "#06191E",
              }}
            >
              Локация
            </Typography>

            {activeLocation && <Chip label={activeLocation.name} />}

            {/* <FormGroup>
              {locations &&
                locations.map((location: any, index: number) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={checkedLocations[location.id] || false}
                          onChange={handleCheckboxChange(location.id)}
                        />
                      }
                      label={location.name}
                    />
                  );
                })}
            </FormGroup> */}
          </Box>
        </Box>

        {inputError && (
          <div>
            <Alert sx={{ marginTop: "30px" }} severity="error">
              {inputError}
            </Alert>
          </div>
        )}

        <Box sx={{ display: "flex", gap: 1, mt: 3 }}>
          <StyledCancelLink to="/user-management">Отказ</StyledCancelLink>

          <Box sx={{ position: "relative" }}>
            <Button
              sx={{ background: "#06191E" }}
              variant="contained"
              disabled={loading}
              onClick={handleCreateEmployee}
            >
              Създай
            </Button>

            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={snackbar.show}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          severity={snackbar.isError ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
          onClose={handleSnackBarClose}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateEmployee;
