import { Routes, Route, Link } from "react-router-dom";
import ErrorPage from "./404";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./views/login/Login";
import BeachLocation from "./views/dashboard/beach-location";
import Layout from "./components/Layout";
// import Breadcrumbs from './components/Breadcrumbs';
import Reports from "./views/reports/Reports";
import PayoutHistory from "./views/payout-history/PayoutHistory";
import CreateLocation from "./views/create-location/CreateLocation";
import BeachMapBuilder from "./views/map-page/BeachMapBuilder";
import UserManagement from "./views/user-management/UserManagement";
import Statistics from "./views/statistics/Statistics";
import CreateEmployee from "./views/user-management/CreateEmployee";
import EmploymentActivation from "./views/employee-activation/EmployeeActivation";
import Request from "./views/reset-password/Request";
import Reset from "./views/reset-password/Reset";
import ApprovedVenues from "./views/map-page/venues/approvedList";
import PendingVenues from "./views/map-page/venues/pendingList";
import ActivateAccount from "./views/account-activation/ActivateAccount";
import { App as CapacitorApp } from '@capacitor/app';


import { hasPermission } from "./store/permissions";
import {MobileAppDownload} from "./views/MobileAppDownload.tsx";
import {useEffect} from "react";


import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import {browserTracingIntegration, replayIntegration} from "@sentry/react";
import {getCurrentScope} from "@sentry/capacitor";

const App = () => {

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem('fiscal_device_address');
    };

    CapacitorApp.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        clearLocalStorage();
      }
    });

    // Clear localStorage on initial load
    clearLocalStorage();

    if(import.meta.env.VITE_APP_ENVIRONMENT==='production') {
      Sentry.init({
            dsn: "https://fe0f606b8e8a7c0ba88b3e277edc478e@o4507741881565184.ingest.de.sentry.io/4507743306448976",
            tracePropagationTargets: ["localhost", /^https:\/\/myspot\.bg\/api/],
            integrations: [
              browserTracingIntegration({}),
              replayIntegration(),
            ],

            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          },
          // Forward the init method from @sentry/react
          SentryReact.init
      );
    }

    // Log buffer to store console logs
    const logBuffer: { type: any; message: any[]; }[] = [];
    const MAX_LOG_ENTRIES = 100;

// Function to capture console logs
    const captureConsole = (type) => {
      // @ts-ignore
      const original = console[type];
      // @ts-ignore
      console[type] = (...args) => {
        if (logBuffer.length >= MAX_LOG_ENTRIES) {
          logBuffer.shift();
        }
        logBuffer.push({type, message: args});
        original.apply(console, args);
      };
    };

// Capture all console methods
    ['log', 'warn', 'error', 'info', 'debug'].forEach(captureConsole);

// Configure Sentry to include console logs in the event data
    getCurrentScope().addScopeListener((scope) => {
      scope.addEventProcessor((event) => {
        event.extra = {
          ...event.extra,
          consoleLogs: logBuffer,
        };
        return event;
      });
    });

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, []);

  return (
    <>
      <Routes>
        {/* <Route path='/' index element={<DesiderRoutes />} /> */}
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute allowedRoles={["OWNER"]} />}>
          <Route element={<Layout />}>
            <Route
              path="/"
              element={<BeachLocation />}
              handle={{
                crumb: () => <Link to="dashboard">Dashboard</Link>,
              }}
            />

            <Route path="reports" element={<Reports />} />

            <Route path="statistics" element={<Statistics />} />

            <Route path="payout-history" element={<PayoutHistory />} />

            <Route path="create-location" element={<CreateLocation />} />

            <Route path="edit-location/:id" element={<CreateLocation />} />

            <Route path="user-management" element={<UserManagement />} />

            <Route path="user-management/create" element={<CreateEmployee />} />

            <Route path="user-management/edit" element={<CreateEmployee />} />

            {/* <Route path="map-page" element={<BeachMapBuilder />} /> */}

            <Route path="create-layout/:id" element={<BeachMapBuilder />} />

            <Route path="approved-venues" element={<ApprovedVenues />} />

            <Route path="pending-venues" element={<PendingVenues />} />

            <Route path="mobile-download" element={<MobileAppDownload/>}/>
          </Route>
        </Route>
        <Route path="activate-account" element={<ActivateAccount />} />
        <Route path="reset-password-request" element={<Request />} />
        <Route path="reset-password" element={<Reset />} />
        <Route path="reset-password/:resetToken" element={<Reset />} />
        <Route path="employee-activation/" element={<EmploymentActivation />} />
        <Route
          path="employee-activation/:activationToken"
          element={<EmploymentActivation />}
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default App;
