import produce from "immer";
import {
  ADD_EMPLOYEE,
  ADD_EMPLOYEES,
  ADD_ROLES,
  LOADING,
  SHOW_SNACKBAR,
  REMOVE_EMPLOYEE,
  SET_EMPLOYEE_ACTIVE_STATUS,
  SET_EMPLOYEE_INACTIVE_STATUS,
} from "./types";

interface InitialState {
  list: Array<any>;
  roles: Array<any>;
  employeeActive: boolean | null;
  loading: boolean;
  snackbar: Object;
}
const initialState: InitialState = <InitialState>{
  list: [],
  roles: [],
  employeeActive: null,
  loading: false,
  snackbar: {
    show: false,
    isError: false,
    message: "",
  },
};

export default function (state = initialState, action: any) {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case ADD_EMPLOYEES: {
        draft.list = payload;
        break;
      }
      case ADD_ROLES: {
        draft.roles = payload;
        break;
      }
      case ADD_EMPLOYEE: {
        draft.list.push(payload);
        break;
      }
      case REMOVE_EMPLOYEE: {
        const filtered = draft.list.filter(
          (employee: any) => employee.id !== payload
        );
        draft.list = filtered;
        break;
      }
      case SET_EMPLOYEE_ACTIVE_STATUS: {
        draft.employeeActive = true;
        break;
      }
      case SET_EMPLOYEE_INACTIVE_STATUS: {
        draft.employeeActive = false;
        break;
      }
      case LOADING: {
        draft.loading = payload;
        break;
      }
      case SHOW_SNACKBAR: {
        draft.snackbar = payload;
        break;
      }
      default:
        return state;
    }
  });
}
