import { hasPermission } from "../../store/permissions";
import { ReportsActions } from "./actions";

export const getReport =
  (
    locationId: string,
    limit: number,
    afterDate: string,
    beforeDate: string,
    statuses: any
  ) =>
  (dispatch: any, _: any, { api }: any) => {
    if (hasPermission("FETCH_RESERVATION_REPORTS")) {
      api.reportsAPI
        .getReservationsReport(
          locationId,
          limit,
          afterDate,
          beforeDate,
          statuses
        )
        .catch((error: any) => {
          console.log(error);
        })
        .then((res: any) => {
          const { data } = res;
          if (data) {
            dispatch(ReportsActions.addLocationReservations(data));
          }
        });
    }
  };
