import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useSelector} from "react-redux";
import {
    getSelectedDate,
    getSelectedInitilizeSpot,
    getSelectedReservedSpot,
    getSelectedSpots
} from "../sidepanel/selectors.ts";
import {useAppDispatch} from "../../../hooks.ts";
import {getActiveLocationMetadata} from "../../../store/locations/selectors.ts";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {getSpotNotes} from "./selector.ts";
import {addSpotReservationNote, spotNotes} from "../../../store/locations/async-actions.ts";
import {LayoutActions} from "./actions.ts";

interface AddReservationNotesModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (notes: string) => void;
    initialValue?: string;
}

export const AddReservationNotesModal: React.FC<AddReservationNotesModalProps> = ({
                                                                                      open,
                                                                                      onClose,
                                                                                  }) => {
    const [note, setNote] = useState<string>(undefined);

    const dispatch = useAppDispatch();

    const activeLocation = useSelector(getActiveLocationMetadata);
    const selectedDate = useSelector(getSelectedDate);
    const selectedSpot = (useSelector(getSelectedReservedSpot)) ? useSelector(getSelectedReservedSpot) : useSelector(getSelectedInitilizeSpot);
    const notes = useSelector(getSpotNotes);

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value);
    };

    const handleSubmit = () => {
        dispatch(addSpotReservationNote(selectedSpot.id, {
            venueId: activeLocation.id,
            content: note,
            forDay: selectedDate
        }));
        dispatch(
            LayoutActions.snackbarState({
                show: true,
                isError: false,
                message: 'Успешен запис'
            })
        );
        onClose();
    };

    useEffect(() => {
        dispatch(spotNotes(selectedSpot.id, selectedDate));
    }, [selectedSpot]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                Бележка към резервация
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ maxHeight: '150px', overflowY: 'auto' }}>
                    <List>
                        {notes.map((note, index) => (
                            <ListItem key={index} alignItems="flex-start">
                                <ListItemText
                                    primary={note.content}
                                    secondary={
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {note.createdBy}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', paddingTop: '10px' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="notes"
                        label="Бележка"
                        type="text"
                        fullWidth
                        multiline
                        rows={2}
                        value={note}
                        onChange={handleNotesChange}
                    />
                    <Button onClick={handleSubmit} color="primary" fullWidth>
                        Запази
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
