import { useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import Button from "@mui/material/Button";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const Dropbox = styled.section`
  text-align: center;
  width: 100%;
  margin: auto;
`;

interface DropBoxProps {
  onDrop: (files: File[]) => void;
}

const DropBox: React.FC<DropBoxProps> = ({ onDrop }) => {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      onDrop(acceptedFiles);
    },
    noClick: true,
    noKeyboard: true,
  });

  // const fileNames = acceptedFiles.map((file: File) => (
  //     <li key={file.path}>
  //         {file.path} - {file.size} bytes
  //     </li>
  // ));

  return (
    <>
      <Dropbox>
        <Container
          className="dropbox"
          {...getRootProps({ isDragAccept, isFocused, isDragReject })}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop file here</p>

          <Button variant="contained" onClick={open}>
            Click to select file
          </Button>
        </Container>
      </Dropbox>
    </>
  );
};

export default DropBox;
